
import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import {
  faRocket,
  faPhone,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import notNull from "../../../util/common/notNull";
import {
  HostMexxarWS,
} from "../../../configs/api-config";
import { Client as StompClient } from "@stomp/stompjs";

const DailyTarget = (props) => {
  const { style, auditDataSummery, responseData } = props;


  const [webSocketData, setWebSocketData] = useState(null);

 
  const initialData = Array.isArray(responseData) && responseData.length > 0
    ? responseData[0]
    : null;

  const data = webSocketData || initialData; 

  useEffect(() => {
    const userId = JSON.parse(localStorage.user).id;
    const client = new StompClient({
      brokerURL: HostMexxarWS + "wsc",
      reconnectDelay: 5000,
      onConnect: () => {
        console.log("WebSocket connected");
        client.subscribe(`/maintenance/summary/${userId}`, (message) => {
          try {
            const parsedMessage = JSON.parse(message.body);
            console.log("WebSocket message received:", parsedMessage);
            setWebSocketData(parsedMessage); 
          } catch (error) {
            console.error("Error parsing WebSocket message:", error);
          }
        });
      },
      onStompError: (error) => {
        console.error("Stomp error", error);
      },
    });

    client.activate();

    return () => {
      if (client) {
        client.deactivate();
        console.log("WebSocket disconnected");
      }
    };
  }, []);

  return (
    <Card style={style}>
      <div className="text-center p-2">
        <div style={{ flex: 1 }}>
          {notNull(data) ? (
            <>
              <div className="sub-1 p-1">
                <div style={{ flex: 1 }}>Daily Call Targets</div>
                <div style={{ flex: 1 }}>Answered Calls</div>
                <div style={{ flex: 1 }}>Daily Clearance</div>
              </div>

              <div className="sub-1 p-1">
                <div style={{ flex: 1 }}>{data.remainingCallingTarget}/{data.dailyCallingTarget}</div>
                <div style={{ flex: 1 }}>{data.outgoingAnsweredCount}</div>
                <div style={{ flex: 1 }}>{data.dailyCleared}</div>
              </div>
              <div className="sub-1 p-1">
                <div style={{ flex: 1 }}>
                  <FontAwesomeIcon
                    icon={faRocket}
                    size="2x"
                    color="#E8B406"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <FontAwesomeIcon
                    icon={faPhone}
                    size="2x"
                    color="#00C426"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <FontAwesomeIcon
                    icon={faCheck}
                    size="2x"
                    color="#FE00C5"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="sub-1 p-1">
                <div style={{ flex: 1 }}>Daily Targets</div>
                <div style={{ flex: 1 }}>Verified</div>
                <div style={{ flex: 1 }}>Declined</div>
              </div>

              <div className="sub-1 p-1">
                <div style={{ flex: 1, color: "#B9C0D3" }}>
                  {auditDataSummery.dailyTarget}
                </div>
                <div style={{ flex: 1, color: "#B9C0D3" }}>
                  {auditDataSummery.verified}
                </div>
                <div style={{ flex: 1, color: "#B9C0D3" }}>
                  {auditDataSummery.declined}
                </div>
              </div>
              <div className="sub-1 p-1">
                <div style={{ flex: 1 }}>
                  <FontAwesomeIcon
                    icon={faRocket}
                    size="2x"
                    color="#E8B406"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <FontAwesomeIcon
                    icon={faCheck}
                    size="2x"
                    color="#fc0b03"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <FontAwesomeIcon
                    icon={faTimes}
                    size="2x"
                    color="#fc0b03"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default DailyTarget;
