export default function calculateShiftRates(shift) {
  const {
    startDate,
    endDate,
    chargeRates, // Object for charge rates
    payRates, // Object for pay rates
    bankHolidays,
    startHourDay,
    startHourNight,
  } = shift;

  let start = new Date(startDate);
  let end = new Date(endDate);
  const results = [];

  const dayStartHour = startHourDay; // ex:06:00 AM for day start
  const nightStartHour = startHourNight; // 20:00 PM for night start

  while (start < end) {
    let currentHour = start.getHours();
    let currentDay = start.getDay();
    const isSaturday = currentDay === 6;
    const isSunday = currentDay === 0;
    // const isHoliday = checkIfHoliday(start, bankHolidays);
    const isHoliday = checkIfHoliday(start, bankHolidays); // Updated to check correctly

    let hourlyRate;
    let chargeRate;
    let segmentEndTime;
    let PayDescription;

    // Determine hourly rate and segment end time
    if (currentHour >= dayStartHour && currentHour < nightStartHour) {
      // Day Shift
      if (isSaturday) {
        hourlyRate = payRates.saturday.day; // Pay rate for Saturday Day
        chargeRate = chargeRates.saturday.day; // Charge rate for Saturday Day
        PayDescription = "Saturday Pay Day";
      } else if (isSunday) {
        hourlyRate = payRates.sunday.day; // Pay rate for Sunday Day
        chargeRate = chargeRates.sunday.day; // Charge rate for Sunday Day
        PayDescription = "Sunday Pay Day";
      } else if (isHoliday) {
        hourlyRate = payRates.bankHoliday.day; // Pay rate for Bankholiday Day
        chargeRate = chargeRates.bankHoliday.day; // Charge rate for Bankholiday Day
        PayDescription = "Bankholiday Pay Day";
      } else {
        hourlyRate = payRates.weekday.day; // Pay rate for Weekday Day
        chargeRate = chargeRates.weekday.day; // Charge rate for Weekday Day
        PayDescription = "Weekday Pay Day";
      }
      segmentEndTime = new Date(start);
      segmentEndTime.setHours(nightStartHour, 0, 0, 0); // End at 18:00 (night start)
    } else if (currentHour >= nightStartHour && currentHour < 24) {
      // Night Shift
      if (isSaturday) {
        hourlyRate = payRates.saturday.night; // Pay rate for Saturday Night
        chargeRate = chargeRates.saturday.night; // Charge rate for Saturday Night
        PayDescription = "Saturday Pay Night";
      } else if (isSunday) {
        hourlyRate = payRates.sunday.night; // Pay rate for Sunday Night
        chargeRate = chargeRates.sunday.night; // Charge rate for Sunday Night
        PayDescription = "Sunday Pay Night";
      } else if (isHoliday) {
        hourlyRate = payRates.bankHoliday.night; // Pay rate for Bankholiday Night
        chargeRate = chargeRates.bankHoliday.night; // Charge rate for Bankholiday Night
        PayDescription = "Bankholiday Pay Night";
      } else {
        hourlyRate = payRates.weekday.night; // Pay rate for Weekday Night
        chargeRate = chargeRates.weekday.night; // Charge rate for Weekday Night
        PayDescription = "Weekday Pay Night";
      }
      segmentEndTime = new Date(start);
      segmentEndTime.setHours(24, 0, 0, 0); // End at midnight
    } else {
      // After midnight
      if (isSaturday) {
        hourlyRate = payRates.saturday.night; // Pay rate for Saturday Night
        chargeRate = chargeRates.saturday.night; // Charge rate for Saturday Night
        PayDescription = "Saturday Pay Night";
      } else if (isSunday) {
        hourlyRate = payRates.sunday.night; // Pay rate for Sunday Night
        chargeRate = chargeRates.sunday.night; // Charge rate for Sunday Night
        PayDescription = "Sunday Pay Night";
      } else if (isHoliday) {
        hourlyRate = payRates.bankHoliday.night; // Pay rate for Bankholiday Night
        chargeRate = chargeRates.bankHoliday.night; // Charge rate for Bankholiday Night
        PayDescription = "Bankholiday Pay Night";
      } else {
        hourlyRate = payRates.weekday.night; // Pay rate for Weekday Night
        chargeRate = chargeRates.weekday.night; // Charge rate for Weekday Night
        PayDescription = "Weekday Pay Night";
      }
      segmentEndTime = new Date(start);
      segmentEndTime.setHours(dayStartHour, 0, 0, 0); // End at 06:00 AM
    }

    if (segmentEndTime > end) {
      segmentEndTime = end; // Adjust if shift ends before the segment end
    }

    // Calculate duration in hours
    // const quantity = (segmentEndTime - start) / (1000 * 60); // in minutes  //use this if needed in fractions
    // Inside your while loop
    // Inside your while loop
    const quantity = (segmentEndTime - start) / (1000 * 60); // in minutes
    const hours = Math.floor(quantity / 60); // Calculate full hours
    const minutes = quantity % 60; // Calculate remaining minutes

    // Format hours and minutes to ensure two digits
    const formattedHours = String(hours).padStart(2, "0"); // Pad hours to 2 digits
    const formattedMinutes = String(minutes).padStart(2, "0"); // Pad minutes to 2 digits
    console.log("test start and end time --->", start, segmentEndTime);
    results.push({
      StartTime: start.toTimeString().slice(0, 5),
      EndTime: segmentEndTime.toTimeString().slice(0, 5),
      ChargeRate: chargeRate,
      PayRate: hourlyRate,
      PayDescription: PayDescription,
      //   Quantity: quantity / 60, // Convert minutes to hours  //use this if needed in fractions
      Quantity: `${formattedHours}:${formattedMinutes}`, // Format as "HH:MM"
    });

    start = new Date(segmentEndTime); // Move to the next segment
  }

  return results;
}

// Function to check for holidays
function checkIfHoliday(date, bankHolidays) {
  const formattedDate = date.toISOString().split("T")[0]; // Format to 'YYYY-MM-DD'
  const currentHour = date.getHours();

  console.log(
    "calculateShiftRates checkIfHoliday--->",
    date,
    bankHolidays,
    bankHolidays.includes(formattedDate)
  );

  // If it's the bank holiday date and the time is before midnight, return true
  if (bankHolidays.includes(formattedDate) && currentHour < 24) {
    return true;
  }

  // Return false if the date is not a holiday
  return false;
}

//how to call

// const shift = {
//   startDate: formattedstartDateTime, // Friday 19:00
//   endDate: formattedendDateTime, // Saturday 02:30
//   chargeRates: {
//     weekday: {
//       day: 40, // Charge rate for Weekday Day
//       night: 50, // Charge rate for Weekday Night
//     },
//     saturday: {
//       day: 35, // Charge rate for Saturday Day
//       night: 55, // Charge rate for Saturday Night
//     },
//     sunday: {
//       day: 45, // Charge rate for Sunday Day
//       night: 60, // Charge rate for Sunday Night
//     },
//     bankHoliday: {
//       day: 40, // Charge rate for Bankholiday Day
//       night: 50, // Charge rate for Bankholiday Night
//     },
//   },
//   payRates: {
//     weekday: {
//       day: 20, // Pay rate for Weekday Day
//       night: 30, // Pay rate for Weekday Night
//     },
//     saturday: {
//       day: 25, // Pay rate for Saturday Day
//       night: 35, // Pay rate for Saturday Night
//     },
//     sunday: {
//       day: 30, // Pay rate for Sunday Day
//       night: 40, // Pay rate for Sunday Night
//     },
//     bankHoliday: {
//       day: 40, // Pay rate for Bankholiday Day
//       night: 50, // Pay rate for Bankholiday Night
//     },
//   },
//   bankHolidays: ["2024-09-30"], // Example holiday (Monday)
// };

// logic
// Case 1: Shift fully within day rates(ex- Client's day start:06:00 end 18:00,client's night start 18:00 end 06:00(nextday),shift start 08:00 to 16:00)
//shift start and end time inside the client's dayrates start and end time

// Case 2: Shift fully within night rates (ex- Client's day start:06:00 end 18:00,client's night start 18:00 end 06:00(nextday),
//shift start and end time inside the client's night start and night end time
//a.shift start 20:00 to 05:30(next day))- 20:00 to 00:00 night rates (Sameday night rate)
//00:00 06:00(client's nightend time)-if after midnight it is SAT(Saturday),apply saturday night rates.if after midnight it is  (SUN) sunday apply sunday night rates,if after midnight it is   BH(Bank holiday) apply bank holiday night rates, and if after midnight it is Monday(WeekDay)  apply (Weekday) night rates
//b.shift start 02:00 to 05:30(next day))
//02:00 05:30-if after midnight it is SAT(Saturday),apply saturday night rates.if after midnight it is  (SUN) sunday apply sunday night rates,if after midnight it is   BH(Bank holiday) apply bank holiday night rates, and if after midnight it is Monday(WeekDay)  apply (Weekday) night rates

// Case 3: Shift spans night and day rates
//shift start time inside the client's night start  and day endtime, shift end time in client's day start time and end time
//(ex- Client's day start:06:00 end 18:00,client's night start 18:00 end 06:00(nextday),
//a.shift start 20:00 to 06:30(next day))- 20:00 to 00:00 night rates (Sameday night rate)
//00:00 to 06:00(client's night end time)-if after midnight it is SAT(Saturday),apply saturday night rates.if after midnight it is  (SUN) sunday apply sunday night rates,if after midnight it is   BH(Bank holiday) apply bank holiday night rates, and if after midnight it is Monday(WeekDay)  apply (Weekday) night rates
//06:00(client's night end time) to 06:30 -next day day rate
//d.shift start 02:00 to 06:30(next day))
//02:00 to 06:00(client's night end time)-if after midnight it is SAT(Saturday),apply saturday night rates.if after midnight it is  (SUN) sunday apply sunday night rates,if after midnight it is   BH(Bank holiday) apply bank holiday night rates, and if after midnight it is Monday(WeekDay)  apply (Weekday) night rates
//06:00(client's night end time) to 06:30 -next day day rate

// Case 4: Shift spans day and night rates (shift start time ,inside the client's day start and day endtime, shift end time in client's day start time and end time
//ex- Client's day start:06:00 end 18:00,client's night start 18:00 end 06:00(nextday),
//a.shift start 16:00 to 18:30)
//16:00 to 18:00-day rate
//18:00(client's day end time) to 18:30 - night rate
//b.shift start 16:00 to 02:30(next day))
//16:00 to 18:00 day rates
//18:00 to 00:00 day rates ( night rates)
//00:00 to 02:00(client's night end time)-if after midnight it is SAT(Saturday),apply saturday night rates.if after midnight it is  (SUN) sunday apply sunday night rates,if after midnight it is   BH(Bank holiday) apply bank holiday night rates, and if after midnight it is Monday(WeekDay)  apply (Weekday) night rates

// Case 5: Shift spans day,night,day   ex:client's dayRate startTime-6:00 end 18:00 , nightRate startTime:18:00 end 06:00
//a.shift start 16:00 to 06:30(next day))-
//16:00 to 18:00 day rates
//18:00 to 00:00 day rates (night rates)
//00:00 to 06:00(client's night end time)-if after midnight it is SAT(Saturday),apply saturday night rates.if after midnight it is  (SUN) sunday apply sunday night rates,if after midnight it is   BH(Bank holiday) apply bank holiday night rates, and if after midnight it is Monday(WeekDay)  apply (Weekday) night rates
//06:00(client's night end time) to 06:30 -next day day rate

// Case 6: Shift spans night,day,night ex:client's dayRate startTime-6:00 end 18:00 , nightRate startTime:18:00 end 06:00
//a.shift start: 05:00 ends at 19:00
//05:00 to 06:00 night rates.
//06:00 to 18:00 day rates (day  rates)
//18:00 to 19:00- night rates.

// Case 7: Shift spans night and day ex:client's dayRate startTime-6:00 end 18:00 , nightRate startTime:18:00 end 06:00
//a.shift start: 05:00 ends at 16:00
//05:00 to 06:00 night rates.
//06:00 to 16:00 day rates (day rates)

//Notes: Always Client's night rate time should calculate in to two seoparate scenarios
// If the client's dayRate startTime-6:00 end 18:00 , nightRate startTime:18:00 end 06:00
// night calculations should be calculate as below
// 18:00-00:00 and 00:00-06:00
//By doing this its easy to calculate rates for shifts which falls under nights and pass midnight and then the next day becomes SAT,SUN or BH
//SAT,SUN,BH calculations should be carry as below
//If the shift start day is Friday,and shift is ending pass midnight, shift ends at SAT,
//Therefore after midnight we should consider it as SAT night rates until the shift end time (till 06:00),if the shift go above that then its SAT day rates . Until midnight its Friday Night Rates
//If the shift start day is SAT,and shift is ending pass midnight, shift ends at SUN,
//Therefore after midnight we should consider it as SUN night rates until the shift end time (till 06:00),if the shift go above that then its SUN day rates . Until midnight its SAT Night Rates
//If the shift start day is SUN,and shift is ending pass midnight, shift ends at MONDAY(Which is weekDay),
//Therefore after midnight we should consider it as WEEKDAY(MONDAY) night rates until the shift end time (till 06:00),if the shift go above that then its WEEKDAY day rates . Until midnight its SUN Night Rates
