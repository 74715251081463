import { useEffect, useState } from "react";
import axios from "axios";

export default function useClientsFetch(
  url,
  query,
  pageNumber,
  searchAdvanced,
  reset,
  inactiveFilter,
  activeFilter
) {
  const [loadingClients, setLoadingClients] = useState(true);
  const [errorClients, setErrorClients] = useState(false);
  const [rowsClients, setRowsClients] = useState([]);
  const [totalElementsClients, setTotalElementsClients] = useState(0);
  const [hasMoreClients, setHasMoreClients] = useState(false);

  useEffect(() => {
    setRowsClients([]);
  }, [query, reset, searchAdvanced, inactiveFilter, activeFilter]);

  useEffect(() => {
    setLoadingClients(true);
    setErrorClients(false);
    let cancel;
    axios({
      method: "GET",
      url: url,
      params: {
        page: pageNumber,
        offset: 20,
        q: query,
        status: inactiveFilter
          ? "INACTIVE"
          : activeFilter
          ? "ACTIVE"
          : undefined,
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        if (res.data.status === "success") {
          setTotalElementsClients(res.data.body.totalElements);
          setRowsClients((prevBooks) => {
            return [...new Set([...prevBooks, ...res.data.body.content])];
          });
          setHasMoreClients(res.data.body.content.length > 0);
        } else {
          setErrorClients(true);
        }
        setLoadingClients(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setLoadingClients(false);
        setErrorClients(true);
      });
    return () => cancel();
  }, [searchAdvanced, query, pageNumber, reset, inactiveFilter, activeFilter]);

  return {
    loadingClients,
    errorClients,
    rowsClients,
    hasMoreClients,
    totalElementsClients,
  };
}
