import React, { useState, useEffect } from "react";
import axios from "axios";
import { forEach, filter } from "lodash";
import { useForm } from "react-hook-form";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";
import {
  ClientLable,
  CANDIDATE_RATE_DATA,
  HOSTMexxar,
} from "../../../configs/api-config";
import { Button, Input, Form, FormGroup, Label, Col, Alert } from "reactstrap";
import useDidMountEffect from "./intialRunUseEffect";
import sortByCustomOrder from "../../../util/common/sortByCustomOrder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { UncontrolledTooltip } from "reactstrap/lib";
import { formatToHHMMSS } from "../../../util/common/formatToHHMMSS";

function ClientRateBookingModal(props) {
  const MexxarApiCandidateRate = CANDIDATE_RATE_DATA;
  const { addToast } = useToasts();
  const { register, handleSubmit } = useForm();
  const {
    closeModal,
    candidate,
    toggle,
    incommingPage,
    clientId,
    specialityId,
    departmentId,
    confirmRates,
    ratesConfirmed,
  } = props;

  let initialRateTypes = [
    { type: "Rate Week Days", key: 0 },
    { type: "Rate Saturday", key: 1 },
    ,
    { type: "Rate Sunday", key: 2 },
    { type: "Rate Bank Holiday", key: 3 },
  ];
  const [clients, setClients] = useState();
  const [update, setUpdate] = useState(false);
  const [departments, setDepartments] = useState();
  const [currentDepartment, setCurrentDepartment] = useState(-1);
  const [editable, setEditable] = useState(false);
  const [specialities, setSpecialities] = useState();
  const [currentClient, setCurrentClient] = useState(-1);
  const [currentSpeciality, setCurrentSpeciality] = useState(-1);
  const [candidateData, setCandidateID] = useState(candidate.candidate);
  const [error, setError] = useState({ bool: false, message: "" });
  const [reset, setReset] = useState(false);

  const [rateTypes, setRateTypes] = useState(initialRateTypes);

  const [rateType, setRateType] = useState("Candidate");

  const toggleRates = (e) => {
    if (e.target.value === "Candidate") {
      setRateType(ClientLable);
    } else if (e.target.value === ClientLable) {
      setRateType("Candidate");
    }
  };

  const typeFinder = (data) => {
    let shortForm;
    let type;
    let key;
    switch (data) {
      case "WEEK":
        shortForm = data;
        type = "Rate Week Days";
        key = 0;
        break;
      case "SAT":
        shortForm = data;
        type = "Rate Saturday";
        key = 1;
        break;
      case "SUN":
        shortForm = data;
        type = "Rate Sunday";
        key = 2;
        break;
      case "BH":
        shortForm = data;
        type = "Rate Bank Holiday";
        key = 3;
        break;
    }
    return { shortForm: shortForm, type: type, key: key };
  };

  const getRates = (clientId, specialityId, roleId, bandId) => {
    setRateTypes(initialRateTypes);

    if (
      (rateType === "Candidate" && clientId === -1) ||
      specialityId === -1 ||
      roleId === -1 ||
      currentDepartment === -1 ||
      bandId === -1
    ) {
      return;
    }

    let typeOfRate =
      rateType === "Candidate" ? "candidate-rates" : "client-rates";
    const candidateIdParam =
      rateType === "Candidate" ? `&candidateId=${candidateData.id}` : "";

    const url = `${HOSTMexxar}${typeOfRate}/filter?offset=17&clientId=${clientId}&specialityId=${specialityId}&roleId=${roleId}&clientWardId=${currentDepartment}&bandId=${bandId}${candidateIdParam}`;

    axios.get(url).then((res) => {
      const rates = res.data.body?.content || [];
      setUpdate(rates.length > 0);

      let dayTypes = ["WEEK", "SAT", "SUN", "BH"];
      let updatedRates = dayTypes.map((dayType) => {
        const filterRates = (payMode, rateMode) => {
          return rates.find(
            (item) =>
              item.dayType === dayType &&
              item.payRollDayMode === payMode &&
              item.rateMode === rateMode
          );
        };

        const defaultDayRate = filterRates("DAY", "DEFAULT") || {};
        const defaultNightRate = filterRates("NIGHT", "DEFAULT") || {};
        const specialDayRate = filterRates("DAY", "SPECIAL") || {};
        const specialNightRate = filterRates("NIGHT", "SPECIAL") || {};

        return {
          type: typeFinder(dayType).type,
          key: typeFinder(dayType).key,
          shortForm: dayType,

          nId: defaultNightRate.id || "",
          dId: defaultDayRate.id || "",
          sDId: specialDayRate.id || "",
          sNId: specialNightRate.id || "",

          nStart: defaultNightRate.startTime || "",
          nEnd: defaultNightRate.endTime || "",
          nRate: defaultNightRate.rate || "",
          nChargeRate: defaultNightRate.chargeRate || "",

          dStart: defaultDayRate.startTime || "",
          dEnd: defaultDayRate.endTime || "",
          dRate: defaultDayRate.rate || "",
          dChargeRate: defaultDayRate.chargeRate || "",

          bRate: specialDayRate.rate || specialNightRate.rate || "",
        };
      });

      console.log("angi updated rate----03->", updatedRates);

      setRateTypes(updatedRates);

      const allEmpty = updatedRates.every(
        (rate) => !rate.dStart && !rate.dEnd && !rate.nStart && !rate.nEnd //since these values are not presented we assume that the rates are not present
      );

      if (rateType === "Candidate" && allEmpty) {
        let clientRates = [];
        // get the rates relavent to client and get dStart,dEnd,nStart,nEnd from the clients. and replace above  rateTypes object's dStart,dEnd,nStart,nEnd
        const clientRatesUrl = `${HOSTMexxar}client-rates/filter?offset=17&clientId=${clientId}&specialityId=${specialityId}&roleId=${roleId}&clientWardId=${currentDepartment}&bandId=${bandId}`;
        axios.get(clientRatesUrl).then((clientRes) => {
          clientRates = clientRes.data.body?.content || [];

          if (clientRates?.length === 0) {
            let defaultWardID = departments?.find(
              (x) => x.wardName === "Default Ward"
            );

            if (!defaultWardID) {
              setError({
                bool: true,
                message:
                  "Default ward has not been added. Please add the default ward to the client first.",
              });
              return;
            }

            //get default rate for the given combination of speciality/band/role
            const defaultClientRatesUrl = `${HOSTMexxar}client-rates/filter?offset=17&clientId=${clientId}&specialityId=${specialityId}&roleId=${roleId}&clientWardId=${defaultWardID?.id}&bandId=${bandId}`;

            axios.get(defaultClientRatesUrl).then((clientRes) => {
              clientRates = clientRes.data.body?.content || [];

              const allEmpty = clientRates.every(
                (rate) =>
                  !rate.startTime &&
                  !rate.endTime &&
                  !rate.chargeRate &&
                  !rate.rate
                //since these values are not presented we assume that the rates are not present
              );

              console.log(
                "angi bug fix--->",
                clientRates,
                allEmpty,
                updatedRates
              );
              if (clientRates?.length === 0 || allEmpty) {
                //if get default rate for the given combination of speciality/band/role is not set, go fot the flat rate

                setError({
                  bool: true,
                  crutial: false,
                  message:
                    "Warning: Default ward rates have not been added for selected combination of Band/Role/Speciality. Please add default rates to the client first through " +
                    ClientLable +
                    " from side navigation tab.",
                });

                const flatClientRatesUrl = `${HOSTMexxar}client-rates/filter?offset=17&clientId=${clientId}&specialityId=${1}&roleId=${1}&clientWardId=${
                  defaultWardID?.id
                }&bandId=${1}`;
                axios.get(flatClientRatesUrl).then((clientRes) => {
                  clientRates = clientRes.data.body?.content || [];
                  if (clientRates.length === 0) {
                    setError({
                      bool: true,
                      crutial: true,
                      message:
                        "Default(Flat) rates have not been added. Please add default rates to the client first through " +
                        ClientLable +
                        " from side navigation tab.",
                    });
                  } else {
                    updatedRates = updateRatesWithClientData(
                      updatedRates,
                      clientRates
                    );
                    setRateTypes(updatedRates);

                    const allEmpty = updateRatesWithClientData(
                      updatedRates,
                      clientRates
                    ).every(
                      (rate) =>
                        !rate.dStart && !rate.dEnd && !rate.nStart && !rate.nEnd
                    );

                    if (allEmpty) {
                      setError({
                        bool: true,
                        crutial: true,
                        message:
                          "The Start/End is not setup for the " +
                          ClientLable +
                          " .Please configure it first.",
                      });
                    } else {
                      setError({
                        bool: true,
                        crutial: false,
                        message:
                          "The Start/End times and Charge Rates you are seeing are related to the client's default rate(Flat) values.",
                      });
                    }
                  }
                });
                setError({
                  bool: true,
                  crutial: true,
                  message:
                    "Default rates have not been added. Please add default rates to the client first through " +
                    ClientLable +
                    " from side navigation tab.",
                });
              } else {
                //if get default rate for the given combination of speciality/band/role is available go with those rates
                updatedRates = updateRatesWithClientData(
                  updatedRates,
                  clientRates
                );
                setRateTypes(updatedRates);

                const allEmpty = updateRatesWithClientData(
                  updatedRates,
                  clientRates
                ).every(
                  (rate) =>
                    !rate.dStart && !rate.dEnd && !rate.nStart && !rate.nEnd
                );

                if (allEmpty) {
                  setError({
                    bool: true,
                    crutial: true,
                    message:
                      "The Start/End is not setup for the " +
                      ClientLable +
                      "for selected combination of Band/Role/Speciality" +
                      " .Please configure it first.",
                  });
                } else {
                  setError({
                    bool: true,
                    crutial: false,
                    message:
                      "The Start/End times and Charge Rates you are seeing are related to the client's default rate values.",
                  });
                }
              }
            });
          }

          // Merging client rates into candidate rates
          // Map the client's rates to update updatedRates
          updatedRates = updateRatesWithClientData(updatedRates, clientRates);
          setError({
            bool: true,
            crutial: false,
            message:
              "The Start/End times and Charge Rates you are seeing correspond to the client's specified combinations above.",
          });
          console.log("angi updated rate----->", updatedRates);

          setRateTypes(updatedRates);
        });
      }
    });
  };

  // const updateRatesWithClientData = (updatedRates, clientRates) => {
  //   return updatedRates.map((rate) => {
  //     const clientDayRate = clientRates.find(
  //       (clientRate) =>
  //         clientRate.dayType === rate.shortForm &&
  //         clientRate.payRollDayMode === "DAY" &&
  //         clientRate.rateMode === "DEFAULT"
  //     );

  //     const clientNightRate = clientRates.find(
  //       (clientRate) =>
  //         clientRate.dayType === rate.shortForm &&
  //         clientRate.payRollDayMode === "NIGHT" &&
  //         clientRate.rateMode === "DEFAULT"
  //     );

  //     return {
  //       ...rate,
  //       nStart: clientNightRate
  //         ? clientNightRate.startTime.slice(0, 5)
  //         : rate.nStart.slice(0, 5),
  //       nEnd: clientNightRate
  //         ? clientNightRate.endTime.slice(0, 5)
  //         : rate.nEnd.slice(0, 5),
  //       dStart: clientDayRate
  //         ? clientDayRate.startTime.slice(0, 5)
  //         : rate.dStart.slice(0, 5),
  //       dEnd: clientDayRate
  //         ? clientDayRate.endTime.slice(0, 5)
  //         : rate.dEnd.slice(0, 5),
  //     };
  //   });
  // };

  const updateRatesWithClientData = (updatedRates, clientRates) => {
    return updatedRates.map((rate) => {
      const clientDayRate = clientRates.find(
        (clientRate) =>
          clientRate.dayType === rate.shortForm &&
          clientRate.payRollDayMode === "DAY" &&
          clientRate.rateMode === "DEFAULT"
      );

      const clientNightRate = clientRates.find(
        (clientRate) =>
          clientRate.dayType === rate.shortForm &&
          clientRate.payRollDayMode === "NIGHT" &&
          clientRate.rateMode === "DEFAULT"
      );

      return {
        ...rate,
        nStart: clientNightRate
          ? clientNightRate.startTime.slice(0, 5)
          : rate.nStart.slice(0, 5),
        nEnd: clientNightRate
          ? clientNightRate.endTime.slice(0, 5)
          : rate.nEnd.slice(0, 5),
        dStart: clientDayRate
          ? clientDayRate.startTime.slice(0, 5)
          : rate.dStart.slice(0, 5),
        dEnd: clientDayRate
          ? clientDayRate.endTime.slice(0, 5)
          : rate.dEnd.slice(0, 5),
        nChargeRate: clientNightRate
          ? clientNightRate.chargeRate
          : rate.nChargeRate,
        dChargeRate: clientDayRate
          ? clientDayRate.chargeRate
          : rate.dChargeRate,
      };
    });
  };

  useEffect(() => {
    const setCandidateData = (candidate) => {
      if (candidate?.candidateSpecialities?.length) {
        setSpecialities(candidate.candidateSpecialities);
        setCurrentSpeciality(candidate.candidateSpecialities[0].id);
      }
      if (candidate?.preferredClients?.length) {
        setClients(candidate.preferredClients);
        setCurrentClient(candidate.preferredClients[0].id);
      }
    };

    if (incommingPage === "addBooking") {
      setCandidateData(candidate?.candidate);
      setCurrentClient(clientId);
      setCurrentSpeciality(specialityId);
    } else if (candidate) {
      setCandidateData(candidate?.candidate);
    }
  }, [candidate, incommingPage, clientId, specialityId]);

  useEffect(() => {
    if (currentClient && currentSpeciality && candidate?.candidate) {
      const { candidateRole, candidateBand } = candidate.candidate;
      getRates(
        currentClient,
        currentSpeciality,
        candidateRole.id,
        candidateBand.id
      );
    }
  }, [
    currentClient,
    currentSpeciality,
    currentDepartment,
    rateType,
    candidate,
    reset,
  ]);

  useDidMountEffect(() => {
    axios
      .get(HOSTMexxar + "client-wards/clients/" + currentClient)
      .then((res) => {
        let wards = res.data.body;
        // Sort array so that the "Default Ward" is first, "Escalation Ward" in second of the list
        const sortedWards = sortByCustomOrder(
          wards,
          ["Default Ward", "Escalation Rates"],
          "wardName"
        );

        setDepartments(sortedWards);

        if (incommingPage === "addBooking") {
          const matchingWard = sortedWards.find(
            (ward) => ward.wardId === departmentId
          );
          if (matchingWard) {
            setCurrentDepartment(matchingWard.id); // Set to the matched department
          } else {
            setCurrentDepartment(sortedWards[0]?.id); // Set to the first if no match found
          }
        } else {
          if (sortedWards.length > 0) {
            setCurrentDepartment(sortedWards[0]?.id);
          } else {
            setCurrentDepartment(-1);
          }
        }
      })
      .catch((err) => {
        // console.log("err", err);
      });
  }, [currentClient]);

  const confirmRatesCheck = () => {
    setError({
      bool: false,
      message: "",
      crutial: false,
    });
    const allEmpty = rateTypes.every(
      (rate) => rate.nRate === "" && rate.dRate === ""
    );
    console.log("angi all empty confirm rates", rateTypes);
    if (allEmpty) {
      setError({
        bool: true,
        message:
          "The rates are not set up, Please setup and Save First or Please double-check and confirm again.",
        crutial: true,
      });
      return;
    } else {
      confirmRates(rateTypes);
    }
  };

  const onSubmit = (data) => {
    console.log(data);

    //check if this is an update or new entry
    if (update == false) {
      let tempArray = [];
      for (let i = 0; i < 4; i++) {
        // let BonusRate = Number(data[i + "BonusRate"]);
        // let BonusChargeRate = Number(data[i + "BonusChargeRate"]);
        let DayRate = Number(data[i + "DayRate"]);
        let DStartTime = formatToHHMMSS(data[i + "DStartTime"]);
        let DEndTime = formatToHHMMSS(data[i + "DEndTime"]);
        let NightRate = Number(data[i + "NightRate"]);
        let NStartTime = formatToHHMMSS(data[i + "NStartTime"]);
        let NEndTime = formatToHHMMSS(data[i + "NEndTime"]);
        let NightChargeRate = Number(data[i + "NightChargeRate"]);
        let DayChargeRate = Number(data[i + "DayChargeRate"]);

        tempArray.push(
          //......Bonus Day Rate .......//
          // {
          //   dayType: i,
          //   payRollDayMode: 0, // This should be 0 for Day Rates
          //   rate: BonusRate,
          //   // chargeRate: BonusChargeRate,
          //   rateMode: 1,
          //   candidateId: candidateData.id,
          //   specialityId: Number(currentSpeciality),
          //   roleId: candidateData.candidateRole.id,
          //   bandId: candidateData.candidateBand.id,
          //   startTime: DStartTime,
          //   endTime: DEndTime,
          //   clientId: currentClient,
          //   clientWardId: currentDepartment,
          // },
          // //......Bonus Night Rate .......//
          // {
          //   dayType: i,
          //   payRollDayMode: 1, // This should be 1 for Night Rates
          //   rate: BonusRate,
          //   // chargeRate: BonusChargeRate,
          //   rateMode: 1,
          //   candidateId: candidateData.id,
          //   specialityId: Number(currentSpeciality),
          //   roleId: candidateData.candidateRole.id,
          //   bandId: candidateData.candidateBand.id,
          //   startTime: NStartTime,
          //   endTime: NEndTime,
          //   clientId: currentClient,
          //   clientWardId: currentDepartment,
          // },
          //......Day Rate.......//
          {
            dayType: i,
            payRollDayMode: 0, // This should be 0 for Day Rates
            rate: DayRate,
            chargeRate: DayChargeRate,
            rateMode: 0,
            candidateId: candidateData.id,
            specialityId: Number(currentSpeciality),
            roleId: candidateData.candidateRole.id,
            bandId: candidateData.candidateBand.id,
            startTime: DStartTime,
            endTime: DEndTime,
            clientId: currentClient,
            clientWardId: currentDepartment,
          },
          //......Night Rate.......//
          {
            dayType: i,
            payRollDayMode: 1, // This should be 1 for Night Rates
            rate: NightRate,
            chargeRate: NightChargeRate,
            rateMode: 0,
            candidateId: candidateData.id,
            specialityId: currentSpeciality,
            roleId: candidateData.candidateRole.id,
            bandId: candidateData.candidateBand.id,
            startTime: NStartTime,
            endTime: NEndTime,
            clientId: currentClient,
            clientWardId: currentDepartment,
          }
        );
      }

      forEach(tempArray, function (data) {
        axios
          .post(MexxarApiCandidateRate, data)
          .then((res) => {
            if (res.data.status == "success") {
              // getData(clientID);
              // setShowAddRow(!showAddRow);

              setError({
                bool: false,
                message: "",
                crutial: false,
              });
              ToastCommon(true, "success", addToast);
              setReset(!reset)
            } else {
              ToastCommon(true, "duplicate", addToast);
            }
          })
          .catch((err) => {
            ToastCommon(true, "error", addToast);
          });
      });

      if (incommingPage !== "addBooking") {
        closeModal(true);
      }
    } else {
      let tempArray = [];

      for (let i = 0; i < 4; i++) {
        // let BonusRate = Number(data[i + "BonusRate"]);
        // let BonusChargeRate = Number(data[i + "BonusChargeRate"]);
        let DayRate = Number(data[i + "DayRate"]);
        let DayChargeRate = Number(data[i + "DayChargeRate"]);
        let DStartTime = formatToHHMMSS(data[i + "DStartTime"]);
        let DEndTime = formatToHHMMSS(data[i + "DEndTime"]);
        let NightRate = Number(data[i + "NightRate"]);
        let NightChargeRate = Number(data[i + "NightChargeRate"]);
        let NStartTime = formatToHHMMSS(data[i + "NStartTime"]);
        let NEndTime = formatToHHMMSS(data[i + "NEndTime"]);
        let DayRateId = data[i + "DayRate" + "ID"];
        let NightRateId = data[i + "NightRate" + "ID"];
        // let BonusRateDayId = data[i + "BonusRateD" + "ID"];
        // let BonusRateNightId = data[i + "BonusRateN" + "ID"];

        tempArray.push(
          //......Bonus Day Rate .......//
          // {
          //   id: Number(BonusRateDayId),
          //   dayType: i,
          //   payRollDayMode: 0, // This should be 0 for Day Rates
          //   rate: BonusRate,
          //   // chargeRate: BonusChargeRate,
          //   rateMode: 1,
          //   candidateId: candidateData.id,
          //   specialityId: Number(currentSpeciality),
          //   roleId: candidateData.candidateRole.id,
          //   bandId: candidateData.candidateBand.id,
          //   startTime: DStartTime,
          //   endTime: DEndTime,
          //   clientId: currentClient,
          //   clientWardId: currentDepartment,
          // },

          // //......Bonus Night Rate .......//
          // {
          //   id: Number(BonusRateNightId),
          //   dayType: i,
          //   payRollDayMode: 1, // This should be 1 for Night Rates
          //   rate: BonusRate,
          //   // chargeRate: BonusChargeRate,
          //   rateMode: 1,
          //   candidateId: candidateData.id,
          //   specialityId: Number(currentSpeciality),
          //   roleId: candidateData.candidateRole.id,
          //   bandId: candidateData.candidateBand.id,
          //   startTime: NStartTime,
          //   endTime: NEndTime,
          //   clientId: currentClient,
          //   clientWardId: currentDepartment,
          // },
          //......Day Rate.......//
          {
            id: Number(DayRateId),
            dayType: i,
            payRollDayMode: 0, // This should be 0 for Day Rates
            rate: DayRate,
            chargeRate: DayChargeRate,
            rateMode: 0,
            candidateId: candidateData.id,
            specialityId: Number(currentSpeciality),
            roleId: candidateData.candidateRole.id,
            bandId: candidateData.candidateBand.id,
            startTime: DStartTime,
            endTime: DEndTime,
            clientId: currentClient,
            clientWardId: currentDepartment,
          },
          //......Night Rate.......//
          {
            id: Number(NightRateId),
            dayType: i,
            payRollDayMode: 1, // This should be 1 for Night Rates
            rate: NightRate,
            chargeRate: NightChargeRate,
            rateMode: 0,
            candidateId: candidateData.id,
            specialityId: Number(currentSpeciality),
            roleId: candidateData.candidateRole.id,
            bandId: candidateData.candidateBand.id,
            startTime: NStartTime,
            endTime: NEndTime,
            clientId: currentClient,
            clientWardId: currentDepartment,
          }
        );
      }

      forEach(tempArray, function (data) {
        axios
          .put(MexxarApiCandidateRate, data)
          .then((res) => {
            if (res.data.status === "success") {
              // getData(clientID);
              // setShowAddRow(!showAddRow);
              ToastCommon(true, "success", addToast);
              setReset(!reset)

            } else {
              ToastCommon(true, "duplicate", addToast);
            }
          })
          .catch((err) => {
            console.log(err);
            ToastCommon(true, "error", addToast);
          });
      });
      if (incommingPage !== "addBooking") {
        closeModal(true);
      }
    }
    // setReset(!reset);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label sm={6}>{rateType} rates for the following combination</Label>
        <Col sm={6} className="d-flex justify-content-end ">
          <Button value={rateType} color="info" onClick={(e) => toggleRates(e)}>
            {rateType} Rates
          </Button>
        </Col>
      </FormGroup>{" "}
      <FormGroup row>
        <Label sm={3}>Role</Label>
        <Col sm={9}>
          <Label sm={3}>{candidate?.candidate?.candidateRole?.name}</Label>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Band</Label>
        <Col sm={9}>
          <Label sm={3}>{candidate?.candidate?.candidateBand?.name}</Label>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Speciality</Label>
        <Col sm={9}>
          {specialities != null && specialities != undefined ? (
            <Input
              type="select"
              name="specialities"
              innerRef={register}
              value={currentSpeciality}
              onChange={(data) => setCurrentSpeciality(data.target.value)}
            >
              {specialities.map((speciality, key) => (
                <option value={speciality.id} key={key}>
                  {speciality.name}
                </option>
              ))}
            </Input>
          ) : null}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>{ClientLable} Name</Label>
        <Col sm={9}>
          {clients != null && clients != undefined ? (
            <Input
              type="select"
              name="hospital"
              innerRef={register}
              value={currentClient}
              onChange={(data) => setCurrentClient(data.target.value)}
            >
              {clients.map((client, key) => (
                <option value={client.id} key={key}>
                  {client.name}
                </option>
              ))}
            </Input>
          ) : null}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Department Name</Label>
        <Col sm={9}>
          {departments != null && departments != undefined ? (
            <Input
              type="select"
              name="deapartment"
              // defaultValue={deapartment.id}
              value={currentDepartment}
              innerRef={register}
              onChange={(selected) =>
                setCurrentDepartment(selected.target.value)
              }
            >
              {departments.map((department, key) => (
                <option value={department.id} key={key}>
                  {department.wardName}
                </option>
              ))}
            </Input>
          ) : null}
        </Col>
      </FormGroup>{" "}
      <FormGroup row>
        <Col sm={12}>
          <Alert color={"info"} className="mt-2 text-sm">
            <li>
              {" "}
              To assign or view rates without specifying wards/department, use
              'Default Ward'.
            </li>
            <li>
              {" "}
              To define rates by ward/department, select the relevant
              ward/department under each {ClientLable}.
            </li>
            <li>
              {" "}
              Select 'Escalation Rates' from the ward/department list to view or
              set "Escalation Rates".
            </li>
          </Alert>
        </Col>
      </FormGroup>
      <div className="col-12 col-sm-12 ">
        <div>
          <div className="card-body">
            {rateTypes.map((data, id) => {
              return (
                <div className="d-flex flex-row" key={id}>
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ width: "300px" }}
                  >
                    {data.type}
                  </div>
                  <div
                    className="d-flex flex-column "
                    style={{ width: "600px" }}
                  >
                    {/* <div className="d-flex justify-content-center m-1">Week Days</div> */}
                    <div className="d-flex flex-row justify-content-center m-1">
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "120px" }}
                      >
                        {rateType === ClientLable ? "Night" : ""}
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "80px" }}
                      >
                        N.P.R
                        <FontAwesomeIcon
                          id="NPR"
                          className="ml-1"
                          icon={faExclamationCircle}
                        />
                      </div>
                      <UncontrolledTooltip target="NPR">
                        Night Pay Rate
                      </UncontrolledTooltip>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "80px" }}
                      >
                        N.C.R
                        <FontAwesomeIcon
                          id="NCR"
                          className="ml-1"
                          icon={faExclamationCircle}
                        />
                      </div>
                      <UncontrolledTooltip target="NCR">
                        Night Charge Rate
                      </UncontrolledTooltip>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "120px" }}
                      >
                        {rateType === ClientLable ? "Day" : ""}
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "80px" }}
                      >
                        D.P.R
                        <FontAwesomeIcon
                          id="DPR"
                          className="ml-1"
                          icon={faExclamationCircle}
                        />
                      </div>
                      <UncontrolledTooltip target="DPR">
                        Day Pay Rate
                      </UncontrolledTooltip>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "80px" }}
                      >
                        D.C.R
                        <FontAwesomeIcon
                          id="DCR"
                          className="ml-1"
                          icon={faExclamationCircle}
                        />
                      </div>
                      <UncontrolledTooltip target="DCR">
                        Day Charge Rate
                      </UncontrolledTooltip>
                      {/* <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "80px" }}
                      >
                        Bonus
                      </div> */}
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                      <div className="d-flex flex-column">
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="time"
                            hidden={rateType === "Candidate"}
                            placeholder=""
                            name={data.key + "NStartTime"}
                            defaultValue={data.nStart}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="time"
                            hidden={rateType === "Candidate"}
                            placeholder=""
                            name={data.key + "NEndTime"}
                            defaultValue={data.nEnd}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center m-1"
                        style={{ width: "80px" }}
                      >
                        <Input
                          type="text"
                          name={data.key + "NightRate"}
                          defaultValue={data.nRate}
                          inputMode="numeric"
                          pattern="[0-9]*"
                          onChange={(e) => {
                            // Allow only numbers with up to two decimal places
                            const newValue = e.target.value
                              .replace(/[^0-9.]/g, "") // Remove non-numeric and non-decimal point characters
                              .replace(/^(\d+\.?\d{0,2}).*$/, "$1"); // Restrict to two decimal places
                            e.target.value = newValue;
                            e.target.style.color =
                              parseFloat(newValue) > data.nChargeRate
                                ? "red"
                                : "inherit";
                          }}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                        <Input
                          type="hidden"
                          name={data.key + "NightRate" + "ID"}
                          defaultValue={data.nId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        />
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center m-1"
                        style={{ width: "80px" }}
                      >
                        <Input
                          type="text"
                          readOnly
                          name={data.key + "NightChargeRate"}
                          defaultValue={data.nChargeRate}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                        {/* <Input
                          type="hidden"
                          name={rateType.key + "NightChargeRate" + "ID"}
                          defaultValue={rateType.nId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        /> */}
                      </div>
                      <div className="d-flex flex-column">
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="time"
                            hidden={rateType === "Candidate"}
                            placeholder=""
                            name={data.key + "DStartTime"}
                            defaultValue={data.dStart}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="time"
                            hidden={rateType === "Candidate"}
                            placeholder=""
                            name={data.key + "DEndTime"}
                            defaultValue={data.dEnd}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center m-1"
                        style={{ width: "80px" }}
                      >
                        <Input
                          type="text"
                          name={data.key + "DayRate"}
                          defaultValue={data.dRate}
                          onChange={(e) => {
                            // Allow only numbers with up to two decimal places
                            const newValue = e.target.value
                              .replace(/[^0-9.]/g, "") // Remove non-numeric and non-decimal point characters
                              .replace(/^(\d+\.?\d{0,2}).*$/, "$1"); // Restrict to two decimal places
                            e.target.value = newValue;
                            e.target.style.color =
                              parseFloat(newValue) > data.dChargeRate
                                ? "red"
                                : "inherit";
                          }}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                        <Input
                          type="hidden"
                          name={data.key + "DayRate" + "ID"}
                          defaultValue={data.dId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        />
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center m-1"
                        style={{ width: "80px" }}
                      >
                        <Input
                          type="text"
                          readOnly
                          name={data.key + "DayChargeRate"}
                          defaultValue={data.dChargeRate}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                        {/* <Input
                          type="hidden"
                          name={rateType.key + "DayChargeRate" + "ID"}
                          defaultValue={rateType.dId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        /> */}
                      </div>
                      {/* <div
                        className="d-flex justify-content-center align-items-center m-1"
                        style={{ width: "60px" }}
                      >
                        <Input
                          type="text"
                          name={data.key + "BonusRate"}
                          defaultValue={data.bRate}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />

                        <Input
                          type="hidden"
                          name={data.key + "BonusRateD" + "ID"}
                          defaultValue={data.sDId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        />

                        <Input
                          type="hidden"
                          name={data.key + "BonusRateN" + "ID"}
                          defaultValue={data.sNId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              );
            })}
            {incommingPage === "addBooking" && error?.bool && (
              <Alert
                color={`${error?.crutial ? "danger" : "info"}`}
                className="my-2 text-sm"
              >
                {error?.message}
              </Alert>
            )}
            {incommingPage === "addBooking" && (
              <Alert color="warning" className="my-2 text-sm">
                1. Confirm Rates: Please verify that the rates have been
                populated in the fields above. <br />
                2. Save: If you’ve made any changes or added new rates, be sure
                to click "Save."
                <br />
                3. Proceed with Booking: Once you’ve completed either of the
                above actions, you can proceed to create the booking.
              </Alert>
            )}
            {rateType === "Candidate" && incommingPage !== "addBooking" && (
              <Alert color="warning" className="my-2 text-sm">
                Press save only if you need to make changes to the default
                values.
              </Alert>
            )}
            {rateType !== "Candidate" && (
              <Alert color="warning" className="my-2 text-sm">
                Switch to "Candidate Rates" to see "Save" or "Confirm Rates"
                Button from Top Right Corner
              </Alert>
            )}

            <div className="d-flex justify-content-end mt-3 mr-3">
              {rateType === "Candidate" && (
                <>
                  {incommingPage === "addBooking" && (
                    <Button
                      color="success"
                      type="button"
                      disabled={error?.bool || error?.crutial}
                      onClick={() => confirmRatesCheck()}
                      className=""
                    >
                      Confirm Rates
                    </Button>
                  )}
                  <Button color="success" type="submit" className="mx-3">
                    Save
                  </Button>
                </>
              )}
              <Button color="secondary" onClick={closeModal}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default ClientRateBookingModal;
