import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";
import { HOSTMexxar, REGISTER_CANDIDATE } from "../../../configs/api-config";
import { Alert, Button, Col, FormGroup, Input, Label } from "reactstrap";
import { connect } from "react-redux";
import { filter, forEach, unionBy } from "lodash";
import Delete from "./delete";
import { useIsMounted } from "../../../customHooks/useIsMounted";

const WhiteListDoc = (props) => {
  const {
    closeModal,
    success,
    candidateId,
    candidateRole,
    candidateBand,
    roleName,
    bandName,
    whiteListPreference,
    selectedPrefferedClient,
    changedFramework1,
  } = props;
  const MexxarApiCandidate = REGISTER_CANDIDATE;

  const { addToast } = useToasts();
  const [templateAlreadySet, setTemplateAlreadySet] = useState(false);

  const { register, handleSubmit, errors } = useForm();
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [certificateData, setCertificateData] = useState([]);
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState([]);
  const [templateIdList, setTemplateIdList] = useState([]);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [templateId, setTemplateId] = useState([]);
  const [changedSpecility, setChangedSpecility] = useState(1);
  const [changedFramework, setChangedFramework] = useState(1);
  const [speciality, setSpeciality] = useState(null);
  const [framework, setFramework] = useState(null);
  const [specialityName, setSpecialityName] = useState(null);
  const [whiteListLoading, setWhiteListLoading] = useState(false);
  const [removeAll, setRemoveAll] = useState(false);
  const [templateStatus, setTemplateStatus] = useState(false);
  const [previousSavedItemList, setPreviousSavedItemList] = useState([]);

  const getDocuments = () => {
    setLoading(true);
    axios
      .all([
        axios.get(
          MexxarApiCandidate +
            "/" +
            candidateId +
            "/documents/framework/" +
            changedFramework +
            "/role/" +
            candidateRole +
            "/speciality/" +
            changedSpecility
        ),
      ])

      .then(
        axios.spread((res, res2) => {
          console.log("res", res);
          setLoading(false);
          setCertificateData(res?.data?.body);
        })
      )
      .catch((err) => {});
    return () => {};
  };

  const getSpecialiftiesAndFrameworkList = () => {
    axios
      .get(MexxarApiCandidate + "/" + candidateId)
      .then((res) => {
        let frameworksTemp = [];

        if (res.data.body[0].preferredClients != undefined) {
          forEach(res.data.body[0].preferredClients, function (item) {
            frameworksTemp.push(item.framework);
          });
        }

        let frameworks = unionBy(frameworksTemp, "id");

        let specialityTemp =
          res.data.body[0].candidateSpecialities.length === 0
            ? null
            : res.data.body[0].candidateSpecialities;

        setChangedFramework(frameworks[0].id);
        setChangedSpecility(specialityTemp[0].id);
        setSpeciality(specialityTemp);
        setFramework(frameworks);
        setSpecialityName(specialityTemp[0]?.name);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const onItemSelect = (id) => {
    if (selectedItems.includes(id)) {
      const updatedItems = selectedItems.filter((item) => item !== id);
      setSelectedItems(updatedItems);
    } else {
      setSelectedItems([...selectedItems, id]);
    }

    if (templateIdList.includes(id)) {
      const updatedTemplateIds = templateIdList.filter((item) => item !== id);
      setTemplateIdList(updatedTemplateIds);
    } else {
      setTemplateIdList([...templateIdList, id]);
    }
  };

  const addTemplate = (data, status) => {
    const documentIdList = [...new Set([...templateIdList, ...selectedItems])];

    const payload = {
      templateName: `Role: ${roleName} | Band: ${bandName} | Speciality: ${changedSpecility}| Framework: ${changedFramework}`,
      documentIdList: documentIdList,
      roleId: candidateRole,
      specialityId: changedSpecility,
      bandId: candidateBand,
      frameworkWhitelistTemplateType: whiteListPreference,
      clientId: selectedPrefferedClient,
      frameworkId: changedFramework,
    };

    axios
      .post(HOSTMexxar + "frameworktemplate", payload)
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "success", addToast);
          setTemplateAlreadySet(true);
          setTemplateIdList(documentIdList);
          setSelectedItems([]);
        } else {
          ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
    closeModal();
  };

  const UpdateTemplate = (data, status) => {
    const documentIdList = [...new Set([...templateIdList, ...selectedItems])]; // Merge selections with existing template data

    const payload = {
      templateName: `Role: ${roleName} | Band: ${bandName} | Speciality: ${changedSpecility}| Framework: ${changedFramework}`,
      documentIdList: documentIdList,
      roleId: candidateRole,
      specialityId: changedSpecility,
      bandId: candidateBand,
      frameworkWhitelistTemplateType: whiteListPreference,
      frameworkId: changedFramework,
    };

    if (selectedPrefferedClient) {
      payload.clientId = selectedPrefferedClient;
    }

    axios
      .put(`${HOSTMexxar}frameworktemplate/${templateId}`, payload)
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "success", addToast);
          setTemplateIdList(documentIdList);
          setSelectedItems([]);
        } else {
          ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
    closeModal();
  };

  const getTemplateById = () => {
    let url = `${HOSTMexxar}frameworktemplate/filter?specialityId=${changedSpecility}&bandId=${candidateBand}&roleId=${candidateRole}&type=${whiteListPreference}&frameworkId=${changedFramework}`;

    if (selectedPrefferedClient) {
      url += `&clientId=${selectedPrefferedClient}`;
    }

    axios
      .get(url)
      .then((res) => {
        const content = res?.data?.body?.content?.[0];

        if (content && content.documentList !== null) {
          setTemplateAlreadySet(true);
          setTemplateId(content.id);

          const documentList = content.documentList;

          if (Array.isArray(documentList)) {
            const ids = documentList.map((item) => item.id);
            setTemplateIdList(ids);
            setSelectedItems(ids);
          } else {
            throw new Error("Invalid response format");
          }
        } else {
          setTemplateAlreadySet(false);
          setTemplateId(null);
          setTemplateIdList([]);
          setSelectedItems([]);
        }
      })
      .catch((err) => {
        console.error("Error fetching templates:", err);
        setTemplateAlreadySet(false);
        setTemplateId(null);
        setTemplateIdList([]);
        setSelectedItems([]);
      });
  };

  const deleteTemplate = () => {
    axios
      .delete(HOSTMexxar + "frameworktemplate/" + templateId)
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "delete", addToast);
          getTemplateById();
          closeModal();
        } else {
          ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "update", addToast);
      });
  };

  const getSelectedTemplate = () => {
    axios
      .get(HOSTMexxar + "frameworktemplate/" + selectedTemplate?.value)
      .then((res) => {
        if (Array.isArray(res?.data?.body?.documentList)) {
          const ids = res.data.body.documentList.map((item) => item.id);
          setSelectedTemplate(ids);
        } else {
          throw new Error("Invalid response format");
        }
      })
      .catch((err) => {
        console.error("Error fetching templates:", err);
        // setAllTemplate([]);
      });
  };

  const changeSpecility = (e) => {
    setChangedSpecility(Number(e.target.value));
  };

  const changeFramework = (e) => {
    setChangedFramework(Number(e.target.value));
  };

  // Modify getWhiteList to return docs
  const getWhiteList = async (candidateId, type) => {
    setWhiteListLoading(true);
    setSelectAll(false);
    setRemoveAll(false);
    setPreviousSavedItemList([]);

    let url =
      HOSTMexxar +
      "frameworkDocWhitelist/filter?candidateId=" +
      props.candidateId +
      "&type=" +
      "CANDIDATE" + // preference is always "CANDIDATE" for the template function
      "&candidateBandId=" +
      candidateBand +
      "&candidateSpecialityId=" +
      changedSpecility +
      "&candidateRoleId=" +
      candidateRole +
      "&frameworkId=" +
      changedFramework +
      "&levelId=1";

    try {
      const res = await axios.get(url, { params: { offset: 200 } });
      let docs = res?.data?.body.content.map(function (item) {
        return { id: item.id, rowId: item.document.id };
      });

      setPreviousSavedItemList(docs);
      setWhiteListLoading(false);

      return docs; // Return docs here
    } catch (error) {
      setWhiteListLoading(false);
      ToastCommon(true, "error", addToast);
      return []; // Return an empty array in case of an error
    }
  };

  // Deletion function that accepts docs as a parameter
  const deleteItems = async (docs) => {
    const axiosPromises = [];

    docs.forEach((item) => {
      if (item.id) {
        axiosPromises.push(
          axios.delete(HOSTMexxar + "frameworkDocWhitelist/" + item.id)
        );
      }
    });

    try {
      await Promise.all(axiosPromises);
      console.log("All items deleted successfully.");
    } catch (error) {
      console.error("Error occurred while deleting items", error);
    }
  };

  const [templateAssigned, setTemplateAssigned] = useState(false);

  // Function to handle the axios.post requests after deletion
  const addWhitelistItems = async (templateIdList) => {
    const axiosPromises = [];

    templateIdList.forEach((item) => {
      let whiteList = {
        candidate: candidateId,
        document: item,
        client: null,
        type: whiteListPreference,
        candidateBand: candidateBand,
        candidateRole: candidateRole,
        candidateSpeciality: changedSpecility,
        level: 1,
        framework:
          whiteListPreference === "CLIENT"
            ? changedFramework
            : changedFramework,
      };

      // Push each Axios promise into the array for the current client
      axiosPromises.push(
        axios.post(HOSTMexxar + "frameworkDocWhitelist", whiteList)
      );
    });

    try {
      await Promise.all(axiosPromises);
      setTemplateAssigned(true);
      props.reset();
      setTimeout(() => {
        setTemplateAssigned(false);
      }, 3000); // 3000 ms = 3 seconds
    } catch (error) {
      console.error("Error occurred while adding whitelist items", error);
    }
  };

  // Main function to handle the entire flow
  const runWhiteListAndDeleteAndCreateNewWhiteList = async () => {
    // Step 1: Get the docs from getWhiteList
    const docs = await getWhiteList();

    // Step 2: Pass the docs to the deleteItems function and wait for the deletion to complete
    await deleteItems(docs);

    // Step 3: Add new whitelist items after deletion is done

    await addWhitelistItems(templateIdList);
  };

  useEffect(() => {
    getSpecialiftiesAndFrameworkList();
    return () => {};
  }, []);

  useEffect(() => {
    getDocuments();
    return () => {};
  }, [changedSpecility, changedFramework]);

  useEffect(() => {
    getTemplateById();
    return () => {};
  }, [changedSpecility, changedFramework]);

  return (
    <>
      <div className="d-flex justify-content-between p-2 ml-3"></div>
      {templateAssigned ? (
        <Alert color="success">Template Assigned Successfully</Alert>
      ) : null}
      <div>
        <FormGroup row className="p-2">
          <Col sm={4}>
            <Label for="candidateSpecialityId">Speciality</Label>

            <Input
              type="select"
              id="candidateSpecialityId"
              name="candidateSpecialityId"
              value={changedSpecility}
              onChange={changeSpecility}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            >
              {speciality?.map((items, id) => {
                return (
                  <option
                    value={items.id}
                    key={id}
                    style={{ color: "#6A6767" }}
                  >
                    {items.name}
                  </option>
                );
              })}
            </Input>
          </Col>

          <Col sm={4}>
            <Label for="frameworkId">Framework</Label>

            <Input
              type="select"
              id="frameworkId"
              name="frameworkId"
              value={changedFramework}
              onChange={changeFramework}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            >
              {framework?.map((items, id) => {
                return (
                  <option
                    value={items.id}
                    key={id}
                    style={{ color: "#6A6767" }}
                  >
                    {items.name}
                  </option>
                );
              })}
            </Input>
          </Col>
          <Col sm={4}>
            <Label for="frameworkId">
              Role:{roleName} | Band:{bandName}
            </Label>
          </Col>
        </FormGroup>
      </div>
      <div className="d-flex justify-content-end p-2 ml-3">
        <div className="mr-3">
          <Button
            color={templateAlreadySet ? "primary" : "warning"}
            size="sm"
            onClick={templateAlreadySet ? UpdateTemplate : addTemplate}
            disabled={selectedItems.length === 0 && templateIdList.length === 0}
          >
            {templateAlreadySet ? "Update Template" : "Save Template"}
          </Button>
          <Button
            color="danger"
            size="sm"
            className="float-right ml-2"
            onClick={() => {
              setShowDeleteRow(true);
            }}
          >
            Delete Template
          </Button>
          <Button
            color="warning"
            size="sm"
            className="float-right ml-2"
            onClick={runWhiteListAndDeleteAndCreateNewWhiteList}
            disabled={!templateAlreadySet}
          >
            Save template to candidate
          </Button>
          {!templateAlreadySet && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                No templates assigned to above combination. Please set a
                combination first
              </li>
            </ul>
          )}
        </div>
        {/* <div>
          <Button
            color="warning"
            size="sm"
            className="float-right ml-2"
            onClick={createWhiteList}
          >
            Save Template Candidate
          </Button>
        </div> */}
      </div>
      {/* {templateStatus && (
        <div>
          <Alert>
            Already Template Saved
          </Alert>
        </div>
      )} */}
      <div
        style={{
          backgroundColor: "white",
          color: "black",
          border: "none",
          boxShadow: "none",
        }}
      >
        <div className="table-responsive">
          <table className="table table-bordered ">
            <tbody>
              {certificateData.map((item, index) => (
                <tr key={index} className="custom-table-row">
                  <td style={{ minWidth: "30px", textAlign: "center" }}>
                    <label className="ui-check m-0">
                      <input
                        type="checkbox"
                        checked={
                          selectedItems.includes(item?.documentId) ||
                          templateIdList.includes(item?.documentId)
                        }
                        onChange={() => onItemSelect(item?.documentId)}
                      />
                      <i></i>
                    </label>
                  </td>
                  <td>{item?.documentName}</td>
                  <td>
                    {item?.type === "MANDATORY" && <span>Mandatory</span>}
                    {item?.type === "IF_APPLICABLE" && (
                      <span>If Applicable</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteTemplate();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(false)}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    counties: state.initialDataGetReducer.counties,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(WhiteListDoc);