import React, { useState } from "react";
import {
  faExclamationTriangle,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { HOSTMexxar } from "../../../../configs/api-config";
import ToastCommon from "../../../../components/toastCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Label, Input, Form, FormGroup, Col, Row, Alert } from "reactstrap";

export default function PasswordReset(props) {
  const { register, handleSubmit, errors, setError, clearErrors } = useForm();
  const { closeModal, mode, id, type, candidateEmail,candidateData } = props;

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [passwordStrengthMessage, setPasswordStrengthMessage] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const MexxarHost = HOSTMexxar;
  const { addToast } = useToasts();

  const validatePasswordStrength = (password) => {
    const lowerCase = /[a-z]/;
    const upperCase = /[A-Z]/;
    const numbers = /[0-9]/;
    const specialChars = /[!@#$%^&*()_+{}\[\]:;"'<>,.?/\\|`~-]/;
    const length = password.length;

    if (!password.match(lowerCase)) {
      return "Password should contain lowercase letters!";
    } else if (!password.match(upperCase)) {
      return "Password should contain uppercase letters!";
    } else if (!password.match(numbers)) {
      return "Password should contain numbers!";
    } else if (!password.match(specialChars)) {
      return "Password should contain special characters!";
    } else if (length < 8) {
      return "Password length should be at least 8 characters.";
    }
    return "Password is strong!";
  };



  const resetAccountPassword = () => {
    const fd = new FormData();
    fd.append("pwd", newPassword);
    if (type == "employee") {
   
      axios
        .post(MexxarHost + "employee/" + id + "/reset-password", fd)
        .then((res) => {
          if (res.data.status == "success") {
            ToastCommon(true, "resetPassword", addToast);
            closeModal();
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {
          ToastCommon(true, "error", addToast);
        });
    } else if (type == "candidate") {
      // axios
      //   .post(MexxarHost + "user/reset-password", fd)
      axios
        .post(
          MexxarHost +
            "candidates/reset-password?email=" +
            candidateEmail +
            "&pwd=" +
            newPassword
        )
        .then((res) => {
          if (res.data.status == "success") {
            ToastCommon(true, "resetPassword", addToast);
            closeModal();
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {
       
        });
    }
  };


  const onSubmit = (data) => {
    const strengthMessage = validatePasswordStrength(newPassword);
    if (strengthMessage === "Password is strong!") {
      if (confirmPassword === newPassword) {
        setPasswordMatch(false);
        resetAccountPassword();
      } else {
        setPasswordMatch(true);
        setError("confirmPassword", {
          type: "manual",
          message: "Passwords do not match!",
        });
      }
    } else {
      setPasswordStrengthMessage(strengthMessage);
    }
  };

  const handleNewPasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    const strengthMessage = validatePasswordStrength(password);
    setPasswordStrengthMessage(strengthMessage);

    if (strengthMessage === "Password is strong!") {
      clearErrors("newPassword");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const password = e.target.value;
    setConfirmPassword(password);
 
    if (password === newPassword) {
      setPasswordMatch(false);
      clearErrors("confirmPassword");
    } else {
      setPasswordMatch(true);
      setError("confirmPassword", {
        type: "manual",
        message: "Passwords do not match!",
      });
    }
  };
  console.log("candidateData",candidateData);
  const candidateApplicationStatus = candidateData?.candidateApplicationStatus;
  console.log("candidateApplicationStatus", candidateData?.candidateApplicationStatus);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Row className="mb-2 ml-1">
          <Col sm={12}>
            <p className="text-muted">
              <strong>Password Requirements:</strong>
              <ul>
                <li>At least one lowercase letter (a-z)</li>
                <li>At least one uppercase letter (A-Z)</li>
                <li>At least one number (0-9)</li>
                <li>At least one special character (e.g., !@#$%^&*())</li>
                <li>Minimum length of 8 characters</li>
              </ul>
            </p>
          </Col>
        </Row>
        
        <Col sm={12}>
        {candidateApplicationStatus === 'NOT_SENT' && (
          <Alert color="danger">
            <p className="text-muted ml-4">
              <div>Please check the application status before resetting the password</div>
            </p>
          </Alert>
        )}

        {candidateApplicationStatus === 'PENDING' && (
          <Alert color="danger">
            <p className="text-muted ml-4">
              <div>The candidate must activate their account via the email link before the password reset can be processed</div>
            </p>
          </Alert>
        )}
      </Col>
      
        <Label sm={4}>New Password</Label>
        <Col sm={7}>
          <Input
            onChange={handleNewPasswordChange}
            type={showPassword ? "text" : "password"}
            name="newPassword"
            invalid={!!errors.newPassword}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 50,
                message: "This field is allowed only 50 characters",
              },
            })}
          ></Input>
          {passwordStrengthMessage && (
            <ul className="list-unstyled text-sm mt-1 text-muted filled">
              <li className="text-danger">{passwordStrengthMessage}</li>
            </ul>
          )}
          {errors.newPassword && (
            <ul className="list-unstyled text-sm mt-1 text-muted filled">
              <li className="text-danger">{errors.newPassword?.message}</li>
            </ul>
          )}
        </Col>

        <Col sm={1} className="d-flex align-items-center justify-content-start">
          {showPassword ? (
            <FontAwesomeIcon
              icon={faEye}
              className="btn-raised btn-wave"
              onClick={() => setShowPassword(false)}
              style={{ boxShadow: "none" }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faEyeSlash}
              className="btn-raised btn-wave"
              onClick={() => setShowPassword(true)}
              style={{ boxShadow: "none" }}
            />
          )}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4}>Confirm Password</Label>
        <Col sm={7}>
          <Input
            type={showPassword ? "text" : "password"}
            name="confirmPassword"
            onChange={handleConfirmPasswordChange}
            invalid={!!errors.confirmPassword || passwordMatch}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 50,
                message: "This field only allows up to 50 characters",
              },
            })}
          ></Input>
      
           {passwordMatch && (
      <ul className="list-unstyled text-sm mt-1 text-muted filled">
        <li className="text-danger">
          <FontAwesomeIcon icon={faExclamationTriangle} color="warning" />{" "}
          The new password and confirm password fields must match.
        </li>
      </ul>
    )}
        </Col>

        <Col sm={1} className="d-flex align-items-center justify-content-start">
          {showPassword ? (
            <FontAwesomeIcon
              icon={faEye}
              className="btn-raised btn-wave"
              onClick={() => setShowPassword(false)}
              style={{ boxShadow: "none" }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faEyeSlash}
              className="btn-raised btn-wave"
              onClick={() => setShowPassword(true)}
              style={{ boxShadow: "none" }}
            />
          )}
        </Col>
      </FormGroup>

      <Row className="justify-content-end">
        <div className="d-flex">
          <Button className="mr-2" color="success" type="submit">
            {mode}
          </Button>
          <Button color="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}
