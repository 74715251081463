import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Label, Form, FormGroup, Col } from "reactstrap";
import { forEach } from "lodash";
import Select from "react-select";
import { connect } from "react-redux";

function BookingConfigure(props) {
  const { register, handleSubmit } = useForm();
  const { closeModal, agencyList, type, clients } = props;
  const [selectedAgency, setSelectedAgency] = useState([]);
  const [agency, setAgency] = useState([]);

  const storedAgencyForBot = JSON.parse(
    localStorage.getItem("selectedAgencyForBot")
  );

  const storedAgencyForFilter = JSON.parse(
    localStorage.getItem("selectedAgencyForFilter")
  );
  const getAgencies = () => {
    let agencyArray = [];
    forEach(agencyList, function (item) {
      agencyArray.push({
        value: item.agencyName,
        label: item.agencyName + `${item.type === "DATABASE" ? "-DB" : ""}`,
        id: item.clientId,
      });
    });
    setAgency(agencyArray);
  };

  useEffect(() => {
    if (type === "BOT") {
      getAgencies();
      storedAgencyForBot && setSelectedAgency(storedAgencyForBot);
    } else if (type === "DEFAULT") {
      setAgency(clients);
      storedAgencyForFilter && setSelectedAgency(storedAgencyForFilter);
    }
  }, [agencyList]);

  const onSubmit = (data) => {
    if (type === "BOT") {
      if (selectedAgency && selectedAgency.length > 0) {
        localStorage.setItem(
          "selectedAgencyForBot",
          JSON.stringify(selectedAgency)
        );
      } else {
        localStorage.removeItem("selectedAgencyForBot");
      }
    } else if (type === "DEFAULT") {
      if (selectedAgency && selectedAgency.length > 0) {
        localStorage.setItem(
          "selectedAgencyForFilter",
          JSON.stringify(selectedAgency)
        );
      } else {
        localStorage.removeItem("selectedAgencyForFilter");
      }
    }

    closeModal();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label sm={3}>Agency Name</Label>
        <Col sm={8}>
          {agency.length > 0 && (
            <Select
              isMulti
              name="agency"
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                  color: "#333333",
                }),
              }}
              value={selectedAgency}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(data) => setSelectedAgency(data)}
              options={agency}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            />
          )}
        </Col>
      </FormGroup>

      <div className="d-flex justify-content-end">
        <Button className="mr-2" color="success" type="submit">
          Save
        </Button>
        <Button color="secondary" onClick={() => closeModal()}>
          Cancel
        </Button>
      </div>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(BookingConfigure);
