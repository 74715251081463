import {
    faMobileAlt,
    faPhone,
    faPhoneAlt,
  } from "@fortawesome/free-solid-svg-icons";
  import React, { useState, useCallback } from "react";
  import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Row,
    UncontrolledTooltip,
  } from "reactstrap";
  import RoundIconButton from "../../RoundIconButton/round-icon-button";
  import { connect } from "react-redux";
  import axios from "axios";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { Link } from "react-router-dom/cjs/react-router-dom.min";
  import ToastCommon from "../../toastCommon";
  import { useToasts } from "react-toast-notifications";
  import { HOSTMexxar } from "../../../configs/api-config";
  
  const OnboardingCall = ({
    custom,
    landLine, 
    candidatePhoneNo,
    userprofile,
    resetFilters,
    mobileSignUp = false,
  }) => {
    const HorizonApplicationId = custom?.customHeaders?.horizonApplicationID;
    const [isCalling, setIsCalling] = useState(false);
    const [phoneNumberType, setPhoneNumberType] = useState("Primary");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { addToast } = useToasts();
  
    const headers = new Headers({
      ApplicationId: HorizonApplicationId,
      "Content-Type": "application/x-www-form-urlencoded",
    });
  
    const handleOnClick = useCallback(
      async (event) => {
        event.preventDefault();
  
        if (isCalling) {
          setIsCalling(false);
        } else {
          setIsCalling(true);
          const params = new URLSearchParams();
          if (phoneNumberType === "Primary") {
            params.append("destination", parseInt(candidatePhoneNo, 10)); // Convert to integer
          } else if (phoneNumberType === "Secondary") {
            params.append("destination", parseInt(userprofile?.secondaryMobile, 10)); // Convert to integer
          } else if (phoneNumberType === "Land") {
            params.append("destination", parseInt(landLine, 10)); // Convert to integer
          }
  
          try {
            const response = await fetch(
              "https://localhost:10443/api/v1/MakeCall",
              {
                method: "POST",
                headers,
                body: params,
              }
            );
            const data = await response.json();
            localStorage.setItem("callId", data.UniqueId);
          } catch (error) {
            setIsCalling(false);
            console.error("Error Call Hangup:", error);
          }
        }
      },
      [isCalling, phoneNumberType, candidatePhoneNo, userprofile?.secondaryMobile, landLine, headers]
    );
  
    const toggleDropdown = useCallback(() => {
      setDropdownOpen((prev) => !prev);
    }, []);
  
    const displayPhoneNumber = (phoneNumber) => {
      const convertedNumber = parseInt(phoneNumber, 10); // Convert to integer
      return isNaN(convertedNumber) ? "N/A" : convertedNumber.toString(); // Return converted number or "N/A"
    };
  
    const handlePhoneNumberType = (type) => {
      setPhoneNumberType(type);
    };
  
   const savingFunction = useCallback(() => {
  console.log("Saving Function", mobileSignUp);

  // Check if userProfile.employee is null
  if (userprofile?.employee === null) {
    console.error("Error: Employee information is missing.");
    ToastCommon(true, "error", addToast);
  }

else{  const url = mobileSignUp
    ? `${HOSTMexxar}mobileSignUp/lastContact/${userprofile?.id}/${userprofile?.employee?.id}`
    : `${HOSTMexxar}onboarding/lastContact/${userprofile?.id}/${userprofile?.employee?.id}`;

  axios
    .put(url)
    .then(() => {
      ToastCommon(true, "success", addToast);
      resetFilters();
    })
    .catch((err) => {
      console.log("Error:", err);
    });}
}, [addToast, resetFilters, userprofile, mobileSignUp]);

  
    return (
      <Form>
        <Row className="justify-content-center">
          <div className="mx-1" id="callCandidate" style={{ marginRight: "50px" }}>
            <RoundIconButton
              buttonColor="#31c971"
              icon={faPhone}
              iconSize="fa-sm"
              buttonSize="btn-sm"
              click={handleOnClick}
              aria-label="Call Candidate"
            />
            <UncontrolledTooltip placement="right" target="callCandidate">
              Call
            </UncontrolledTooltip>
          </div>
  
          <div className="mx-1" id="phoneTypeDropdown" style={{ marginLeft: "50px" }}>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle caret color="success">
                {phoneNumberType}
              </DropdownToggle>
              <DropdownMenu>
                {["Primary", "Secondary", "Land"].map((type) => (
                  <DropdownItem
                    key={type}
                    onClick={() => handlePhoneNumberType(type)}
                    style={{
                      backgroundColor: phoneNumberType === type ? "#d4edda" : "",
                      fontWeight: phoneNumberType === type ? "bold" : "normal",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={type === "Land" ? faPhoneAlt : faMobileAlt}
                      className="icon mr-2"
                    />
                    <span className="option-text">{type}</span>:{" "}
                    {displayPhoneNumber(
                      type === "Primary"
                        ? candidatePhoneNo
                        : type === "Secondary"
                        ? userprofile?.secondaryMobile
                        : landLine // Corrected from lanndLine
                    )}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <UncontrolledTooltip placement="right" target="phoneTypeDropdown">
              Select Phone Type
            </UncontrolledTooltip>
          </div>
        </Row>
  
        <br />
  
        <Row className="justify-content-end">
          <div className="my-1">
            <Link to="#">
              <Button
                color="info"
                size="sm"
                onClick={savingFunction}
              >
                Update Last Contact
              </Button>
            </Link>
          </div>
        </Row>
      </Form>
    );
  };
  
  const mapStateToProps = (state) => ({
    auth: state.auth,
    callState: state.websocketData?.Payload,
    reason1: state?.callSummaryReducer,
    custom: state.customHeaderReducer,
  });
  
  export default connect(mapStateToProps)(OnboardingCall);
  