import React, { useState, useRef, useCallback } from "react";
import axios from "axios";
import filter from "lodash/filter";
import { Link } from "react-router-dom";
import NoConnection from "../errorPages/NoConnection";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../components/toastCommon";
import NoSearchResults from "../errorPages/NoSearchResults";
import loadingAnimation from "../../../src/images/puff.svg";
import Delete from "../../components/Modal/ModalBody/delete";
import { useIsMounted } from "../../customHooks/useIsMounted";
import { CLIENTS, ClientLable, HOSTMexxar } from "../../configs/api-config";
import { faEye, faNotEqual, faPoundSign, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewClient from "../../components/Modal/ModalBody/newClient";
import ModalComponent from "../../components/Modal/modalComponent";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Button, Input, Badge, UncontrolledTooltip, Row, Collapse, FormGroup, Card, Label, Col, CardBody } from "reactstrap";
import WardsList from "../../components/Modal/ModalBody/Admin/wardsList";
import { CandidateActiveStatus } from "../../util/common/candidateStatus";
import ClientRateModal from "../../components/Modal/ModalBody/clientRateModal";
import ConfigureWards from "../../components/Modal/ModalBody/Admin/configureWards";
import { FunnelIcon } from "../../assets/icons/svg";
import useClientsFetch from "../../customHooks/useClientsFetch";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import { useSpring, animated } from "react-spring";
import HospitalNote from "../../components/Modal/ModalBody/hospitalNote";

const ClientProfiles = () => {
  const MexxarApi = CLIENTS;
  const MexxarHost = HOSTMexxar;

  const { addToast } = useToasts();

  /***************** Search filter ******************/
  const [query, setQuery] = useState(null);
  const [reset, setReset] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [remember, setRemember] = useState(true); /// <--------filter settings options saving and retreving/ if need activate later with below checkbox
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [searchAdvanced, setSearchAdvanced] = useState(false);
  const [callResponse, setCallResponse] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");

  const [mode, setMode] = useState("");
  const [rowID, setRowID] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [showWards, setShowWards] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showAddRow, setShowAddRow] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [showHospitalNote, setShowHospitalNote] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [showRateModal, setShowRateModal] = useState(false);
  const [showConfigWards, setShowConfigWards] = useState(false);
  const [inactiveFilter, setInactiveFilter] = useState(false);
  const [activeFilter, setActiveFilter] = useState(false);
  let url = HOSTMexxar + "clients/filter?";

  
 
  

  const { loadingClients, errorClients, rowsClients, hasMoreClients, totalElementsClients } = useClientsFetch(
    url,
    query,
    pageNumber,
    searchAdvanced,
    reset,
    inactiveFilter
  );
  const handleactiveFilterChange = () => {
    setActiveFilter(!activeFilter);
  };
  const handleInactiveFilterChange = () => {
    setInactiveFilter(!inactiveFilter);
  };
  
  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearchClick = () => {
    setPageNumber(1);
  };

  useDidMountEffect(() => {
    setPageNumber(1);
  }, [searchAdvanced, reset, inactiveFilter,activeFilter]);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loadingClients) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreClients) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingClients, hasMoreClients]
  );

  const toggleR = (client) => {
    setSelectedClient(client);
    setShowRateModal(!showRateModal);
  };

  const addNewWard = (data) => {
    axios
      .post(MexxarHost + "client-wards", data)
      .then((res) => {
        if (res.data.status == "success") {
          setShowConfigWards(!showConfigWards);

          ToastCommon(true, "success", addToast);
        } else {
          //toastCommon(true, "duplicate");
        }
      })
      .catch((err) => {
        //console.log(err)
        // toastCommon(true, "error");
      });
  };

  const deleteRow = () => {
    axios
      .delete(MexxarApi + "/" + rowID)
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "delete", addToast);
          setReset(!reset);
        } else {
          ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  const deleteAllRows = () => {
    //console.log(rowID);
    selectedItems.forEach((item) => {
      axios
        .delete(MexxarApi + "/" + item)
        .then((res) => {
          setReset(!reset);
          setSelectedItems([]);
          setSelectAll(false);
        })
        .catch((err) => {
          //console.log(err)
        });
    });
  };

  const onItemSelect = (rowId) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == rowId;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item != rowId;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
    } else {
      setSelectedItems([...selectedItems, rowId]);
    }
  };

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const changeStatus = (id, status) => {
    let setStatus;
    if (status == "ACTIVE") {
      setStatus = "INACTIVE";
    } else {
      setStatus = "ACTIVE";
    }

    axios
      .put(MexxarApi + "/" + id + "/status/" + setStatus)
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "update", addToast);
          setReset(!reset);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
      });
  };
  const resetFilters = () => {
    setReset(!reset);
    
   
  };

  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rowsClients.forEach((item) => {
        ids.push(item.id);
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };

  function handleSearch(e) {
    if (e.target.value == "") {
      setQuery(null);
    } else {
      setQuery(e.target.value);
    }
    setPageNumber(1);
  }

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  /******* Use to set page number to default value of 1 when changing the employee or search advance filters  *******/
  useDidMountEffect(() => {
    setPageNumber(1);
    return () => { };
  }, [searchAdvanced, reset]);
  /*******End *******/

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  return (
    <>
      <div className="form-inline">
        <Button
          onClick={toggleFilter}
          className="btn bg-dark-lt text-dark"
          // disabled
        >
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={handleSearch}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder={`"Search ${ClientLable}s..."`}
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
          }}
        />
        <UncontrolledTooltip target="searchBar">
          Search {ClientLable}s by Name/Email/Phone No.
        </UncontrolledTooltip>
      </div>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
            
              <Col></Col>
              <Col>
                
                <br/>
             
              
                <FormGroup check>
                  <Label check>
                  
                     <Input
                      type="checkbox"
                      checked={inactiveFilter}
                      onChange={handleInactiveFilterChange}
                    />
                    <Badge color="danger">INACTIVE</Badge>
                  </Label>
                </FormGroup>
              </Col>
             

              <Col>
                {/* <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "90px" }}
                    onClick={handleSearchClick}
                  >
                    Search
                  </Button>
                </div> */}
                {/* <div className="d-flex flex-column align-items-end">
                  <Button
                    color="danger m-1 btn-sm"
                    style={{ width: "90px" }}
                    onClick={() => {
                      resetFilters();
                    }}
                  >
                    Reset
                  </Button>
                </div> */}
              </Col>
            </Row>
            <Row></Row>
          </CardBody>
        </Card>
      </Collapse>

      <div className="d-flex justify-content-between py-2  ">
        <div>
          {selectedItems.length > 0 ? (
            <Button
              color="danger"
              size="sm"
              onClick={() => {
                setShowDeleteAll(true);
              }}
            >
              Delete
            </Button>
          ) : null}
        </div>
        <div>
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowAddRow(true);
            }}
          >
            Add {ClientLable}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <table
            id="datatable"
            className="table table-theme table-row v-middle dataTable no-footer"
            role="grid"
            ariaDescribedby="datatable_info"
          >
            <thead>
              <tr role="row">
                <th
                  className="sorting_asc"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaSort="ascending"
                  ariaLabel="ID: activate to sort column descending"
                  style={{ width: "26px" }}
                >
                  <label className="ui-check m-0">
                    <input
                      type="checkbox"
                      name="id"
                      checked={selectAll}
                      onChange={() => checkAll()}
                    />
                    <i></i>
                  </label>
                </th>
                <th
                  className="sorting_asc"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaSort="ascending"
                  ariaLabel="ID: activate to sort column descending"
                  style={{ width: "26px" }}
                >
                  <span className="text-muted">ID</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">{ClientLable}</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Framework</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Email</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  width="150px"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Contact</span>
                </th>

                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  aria-label="Action"
                >
                  <span className="text-muted ">Action</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  aria-label="Departments"
                >
                  <span className="text-muted d-flex flex-column align-items-end">
                    Departments
                  </span>
                </th>
                {/* <th
                  className="sorting_disabled d-flex justify-content-end mr-3"
                  rowSpan="1"
                  colSpan="1"
                  aria-label="Action"
                >
                  <span className="text-muted d-none d-sm-block mr-3">
                    Action
                  </span>
                </th> */}
                {/* <th
                  className="sorting_disabled d-flex justify-content-end mr-3"
                  rowSpan="1"
                  colSpan="1"
                  aria-label="Departments"
                >
                  <span className="text-muted d-none d-sm-block mr-3">
                    Departments
                  </span>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {errorClients && (
                <tr>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td></td>
                  <td>
                    <NoConnection error={errorClients}></NoConnection>
                  </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                </tr>
              )}

              {rowsClients?.map((row, i) => {
                if (rowsClients.length === i + 1) {
                  return (
                    <tr
                      className="odd"
                      role="row"
                      ref={lastBookElementRef}
                      key={i}
                    >
                      <td
                        style={{ minWidth: "30px", textAlign: "center" }}
                        className="sorting_1"
                      >
                        <label className="ui-check m-0">
                          <input
                            type="checkbox"
                            name="id"
                            checked={isSelected(row.id)}
                            onChange={() => onItemSelect(row.id)}
                          />
                          <i></i>
                        </label>
                      </td>
                      <td
                        style={{ minWidth: "30px", textAlign: "center" }}
                        className="sorting_1"
                      >
                        <small className="text-muted">{i + 1}</small>
                      </td>
                      <td className="">{row.name}</td>
                      {row.framework.name ? (
                        <td className="">{row.framework.name}</td>
                      ) : (
                        ""
                      )}
                      <td className="">{row.email}</td>
                      <td className="">{row.primaryMobile}</td>
                      <td className=" ">
                        <div className="d-flex pointer">
                          <div className="mr-5">
                            <Badge
                              onClick={() => changeStatus(row.id, row.status)}
                              style={{
                                backgroundColor: CandidateActiveStatus(
                                  row.status
                                ).badgeColor,
                              }}
                            >
                              {CandidateActiveStatus(row.status).badgeLable}
                            </Badge>
                          </div>
                          <div className="mr-3 pointer">
                            <FontAwesomeIcon
                              id="rates"
                              icon={faPoundSign}
                              color="#FFBA00"
                              className="fa-lg mb-2 btn-raised btn-wave pointer "
                              style={{
                                boxShadow: "none",
                              }}
                              onClick={() => {
                                toggleR(row);
                              }}
                            />
                            <UncontrolledTooltip placement="top" target="rates">
                              Rates
                            </UncontrolledTooltip>
                          </div>
                          <div>
                            <FontAwesomeIcon
                              // color="#448bff"
                              id="edit"
                              icon={faEdit}
                              className="shadow-none fa-lg mb-2 btn-raised btn-wave mx-3 pointer "
                              onClick={() => {
                                setShowUpdateRow(true);
                                setRowID(row.id);
                              }}
                              size="1x"
                            />
                            <UncontrolledTooltip placement="top" target="edit">
                              Edit
                            </UncontrolledTooltip>
                          </div>
                          <div>
                            <FontAwesomeIcon
                              // color="#448bff"
                              id="note"
                              icon={faStickyNote}
                              className="shadow-none fa-lg mb-2 btn-raised btn-wave mx-3 pointer "
                              onClick={() => {
                                setShowHospitalNote(true);
                                setRowID(row.id);
                              }}
                              size="1x"
                            />
                            <UncontrolledTooltip placement="top" target="note">
                              {ClientLable} Note
                            </UncontrolledTooltip>
                          </div>


                          <div>
                            <FontAwesomeIcon
                              // color="#FF5603"
                              id="delete"
                              icon={faTrashAlt}
                              onClick={() => {
                                setShowDeleteRow(true);
                                setRowID(row.id);
                              }}
                              className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave pointer"
                              size="1x"
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="delete"
                            >
                              Delete
                            </UncontrolledTooltip>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column align-items-end">
                          <Button
                            color="primary "
                            className="mb-1  "
                            size="sm"
                            style={{ width: "60px" }}
                            onClick={() => {
                              setShowConfigWards(true);
                              setRowID(row.id);
                              setRowData(row);
                              setMode("Add");
                            }}
                          >
                            Add
                          </Button>
                          <Link
                            to={{
                              pathname:
                                "/dashboard/admin/client-wards/" + row.id,
                            }}
                          >
                            <Button
                              color="success"
                              size="sm"
                              style={{ width: "60px" }} // onClick={() => {
                            //   setShowWards(true);
                            //   setRowID(row.id);
                            // }}
                            >
                              Show
                            </Button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr className="odd" role="row" key={i}>
                      <td
                        style={{ minWidth: "30px", textAlign: "center" }}
                        className="sorting_1"
                      >
                        <label className="ui-check m-0">
                          <input
                            type="checkbox"
                            name="id"
                            checked={isSelected(row.id)}
                            onChange={() => onItemSelect(row.id)}
                          />
                          <i></i>
                        </label>
                      </td>
                      <td
                        style={{ minWidth: "30px", textAlign: "center" }}
                        className="sorting_1"
                      >
                        <small className="text-muted">{i + 1}</small>
                      </td>
                      <td className="">{row.name}</td>
                      {row.framework.name ? (
                        <td className="">{row.framework.name}</td>
                      ) : (
                        ""
                      )}
                      <td className="">{row.email}</td>
                      <td className="">{row.primaryMobile}</td>
                      <td className=" ">
                        <div className="d-flex pointer">
                          <div className="mr-5">
                            <Badge
                              onClick={() => changeStatus(row.id, row.status)}
                              style={{
                                backgroundColor: CandidateActiveStatus(
                                  row.status
                                ).badgeColor,
                              }}
                            >
                              {CandidateActiveStatus(row.status).badgeLable}
                            </Badge>
                          </div>
                          <div className="mr-3 pointer">
                            <FontAwesomeIcon
                              id="rates"
                              icon={faPoundSign}
                              color="#FFBA00"
                              className="fa-lg mb-2 btn-raised btn-wave pointer "
                              style={{
                                boxShadow: "none",
                              }}
                              onClick={() => {
                                toggleR(row);
                              }}
                            />
                            <UncontrolledTooltip placement="top" target="rates">
                              Rates
                            </UncontrolledTooltip>
                          </div>
                          <div>
                            <FontAwesomeIcon
                              // color="#448bff"
                              id="edit"
                              icon={faEdit}
                              className="shadow-none fa-lg mb-2 btn-raised btn-wave mx-3 pointer "
                              onClick={() => {
                                setShowUpdateRow(true);
                                setRowID(row.id);
                              }}
                              size="1x"
                            />
                            <UncontrolledTooltip placement="top" target="edit">
                              Edit
                            </UncontrolledTooltip>
                          </div>
                          <div>
                            <FontAwesomeIcon
                              // color="#448bff"
                              id="note"
                              icon={faStickyNote}
                              className="shadow-none fa-lg mb-2 btn-raised btn-wave mx-3 pointer "
                              onClick={() => {
                                setShowHospitalNote(true);
                                setRowID(row.id);
                              }}
                              size="1x"
                            />
                            <UncontrolledTooltip placement="top" target="note">
                              Hospital Note
                            </UncontrolledTooltip>
                          </div>
                          <div>
                            <FontAwesomeIcon
                              // color="#FF5603"
                              id="delete"
                              icon={faTrashAlt}
                              onClick={() => {
                                setShowDeleteRow(true);
                                setRowID(row.id);
                              }}
                              className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave pointer"
                              size="1x"
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="delete"
                            >
                              Delete
                            </UncontrolledTooltip>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex flex-column align-items-end">
                          <Button
                            color="primary "
                            className="mb-1  "
                            size="sm"
                            style={{ width: "60px" }}
                            onClick={() => {
                              setShowConfigWards(true);
                              setRowID(row.id);
                              setRowData(row);
                              setMode("Add");
                            }}
                          >
                            Add
                          </Button>
                          <Link
                            to={{
                              pathname:
                                "/dashboard/admin/client-wards/" + row.id,
                            }}
                          >
                            <Button
                              color="success"
                              size="sm"
                              style={{ width: "60px" }} // onClick={() => {
                            //   setShowWards(true);
                            //   setRowID(row.id);
                            // }}
                            >
                              Show
                            </Button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                }
              })}

              {!loadingClients && !errorClients && rowsClients.length == 0 && (
                <tr>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td></td>
                  <td>
                    <NoSearchResults />
                  </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                </tr>
              )}

              {loadingClients && !errorClients && (
                <tr>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td>
                    <img src={loadingAnimation} style={{ height: 100 }}></img>
                  </td>
                  <td> </td>
                  <td> </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <ModalComponent
        show={showRateModal}
        // onClick={toggleR}
        closeModal={toggleR}
        header={`${ClientLable} Rates`}
        className="modal-xl"
      >
        <ClientRateModal toggle={toggleR} client={selectedClient} />
        <div className="mt-3"> </div>
      </ModalComponent>

      {/* add new client modal */}
      <ModalComponent
        show={showAddRow}
        header={`Add New ${ClientLable}`}
        className="modal-dialog modal-lg"
        closeModal={() => setShowAddRow(false)}
      >
        <NewClient
          rowID={rowID}
          mode="Add"
          closeModal={() => setShowAddRow(false)}
          success={() => {
            setReset(!reset);
            setShowAddRow(false);
          }}
        />
      </ModalComponent>

      {/* update client modal */}
      <ModalComponent
        show={showUpdateRow}
        header={`Update ${ClientLable} `}
        className="modal-dialog modal-lg "
        closeModal={() => setShowUpdateRow(false)}
      >
        <NewClient
          rowID={rowID}
          mode="Update"
          success={() => {
            setReset(!reset);
            setShowUpdateRow(false);
          }}
          closeModal={() => setShowUpdateRow(false)}
        />
      </ModalComponent>


      <ModalComponent
        show={showHospitalNote}
        header={` ${ClientLable} Note `}
        className="modal-dialog modal-lg "
        closeModal={() => setShowHospitalNote(false)}
      >
        <HospitalNote
          rowID={rowID}
          mode="Update"
          success={() => {
            setReset(!reset);
            setShowHospitalNote(false);
          }}
          closeModal={() => setShowHospitalNote(false)}
        />
      </ModalComponent>



      <ModalComponent
        show={showWards}
        header="Departments"
        className="modal-dialog modal-lg"
        scrollable={true}
        closeModal={() => setShowWards(false)}
      >
        <WardsList rowID={rowID} closeModal={() => setShowWards(false)} />
      </ModalComponent>

      <ModalComponent
        show={showConfigWards}
        header="Configure Departments "
        className="modal-dialog modal-lg"
        scrollable={true}
        closeModal={() => setShowConfigWards(false)}
      >
        <ConfigureWards
          mode={mode}
          //    rowData={rowData}
          clientId={rowID}
          onCreate={(data) => addNewWard(data)}
          closeModal={() => setShowConfigWards(false)}
        />
      </ModalComponent>
      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteRow();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(false)}
      />
      <Delete
        show={showDeleteAll}
        isConfirm={() => {
          deleteAllRows();
          setShowDeleteAll(!showDeleteAll);
        }}
        closeModal={() => setShowDeleteAll(false)}
      />
    </>
  );
};

export default ClientProfiles;
