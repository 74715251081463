import React, { useState, useEffect } from "react";
import "./working-card-widget.style.css";
import { Badge, Card, CardBody, Button, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

import {
  faMars,
  faVenus,
  faPhone,
  faEnvelope,
  faCalendar,
  faPhoneSlash,
  faCalendarAlt,
  faCaretSquareRight,
  faCaretSquareLeft,
} from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";
import { Link } from "react-router-dom";
import axios from "axios";
import ModalComponent from "../../components/Modal/modalComponent";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import EmailNav from "../../components/Modal/ModalBody/emailNav";
import {
  HOSTMexxar,
  RESCHEDULE_HEADHUNTER_WORKSPACE,
  HostMexxarURL,
} from "../../configs/api-config";
import {
  ApplicationStatus,
  GenderSwitch,
  ConversationResponse,
} from "../../util/common";
import moment from "moment";

import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import { isNull } from "lodash";
import AvatarMexxar from "../../util/common/avatarMexxar";

const HeaduntingCallBackWidget = (props) => {
  const { userProfile, auth } = props;

  const { addToast } = useToasts();

  const MexxarApiReschedule = RESCHEDULE_HEADHUNTER_WORKSPACE;
  const MexxarUrl = HostMexxarURL;

  const handleSelectedCandidate = (userProfile) =>
    setSelectedCandidate(userProfile);
  const [selectedCandidate, setSelectedCandidate] = useState({});

  const [showLastContacted, setShowLastContacted] = useState(false);
  const [calendarShow, setCalendarShow] = useState(false);
  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);
  const handleShowLastContacted = () =>
    setShowLastContacted(!showLastContacted);

  const [showEmailModal, setShowEmailModal] = useState(false);

  const toggleShowEmailModal = () => {
    setShowEmailModal(!showEmailModal);
  };

  const toggleM = () => setShowEmailModal(!showEmailModal);

  const [rows, setRows] = useState([]);
  const [profilePicture, setProfilePicture] = useState();
  const MexxarHostAPI = HOSTMexxar;

  // const loadCandidateData = () =>{
  //   axios.get(MexxarHostAPI+"candidates/"+userProfile.candidateId).then((res) => {
  //     if (res.data.body[0].avatar == null || res.data.body[0].avatar == undefined){
  //       res.data.body[0].gender==="MALE"
  //       ? setProfilePicture("http://207.246.85.110/images/maleCandidate.png")
  //       : setProfilePicture("http://207.246.85.110/images/femaleCandidate.png")
  //     }else{
  //       const config = { responseType: 'blob' };
  //       axios.get(MexxarHostAPI + (String(res.data.body[0].avatar).replace("pro_pic","pro-pic")), config).then((file)=>{
  //         getBase64(new File([file.data], "sample"), (result) => {
  //           setProfilePicture(result)
  //         });
  //       })
  //     }
  //   });
  // }
  // function getBase64(file, cb) {
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = function () {
  //       cb(reader.result)
  //   };
  //   reader.onerror = function (error) {
  //       console.log('Error: ', error);
  //   };
  // }

  // const [certificates, setCertificates] = useState([]);
  // useEffect(() => {
  //   axios
  //     .get("http://207.246.85.110/mockData/maintenance-widget.json")
  //     .then((res) => {
  //       setCertificates(res.data.data);
  //     });
  // }, []);

  // const getAllRows = () => {
  //   axios
  //     .get(MexxarApiReschedule)
  //     .then((res) => {
  //       console.log(
  //         "incomming response headhunter call back widget...",
  //         res.data.body
  //       );
  //       setRows(res.data.body);
  //     })
  //     .catch((err) => {
  //       //console.log(err)
  //       ToastCommon(true, "error", addToast);
  //     });
  // };
  // useEffect(() => {
  //   loadCandidateData();
  //   //getAllRows();
  // }, []);

  const resheduleCall = (data) => {
    let rescheduleDate = moment(data.date).add(data.time).toISOString();
    axios
      .get(MexxarApiReschedule, {
        callerId: auth.item.id,
        receiverId: userProfile.candidateId,
        callDate: moment(new Date()).toISOString(),
        rescheduleDate: rescheduleDate,
      })
      .then((res) => {
        //  setCandidateId(res.data.body);
        ToastCommon(true, "success", addToast);
        setCalendarShow(!calendarShow);
      })
      .catch((err) => {
        //console.log("erorrrrrrrrrrrrrrrr", err);
        ToastCommon(true, "error", addToast);
      });
  };

   return (
    <>
      <div
        className=" card d-flex flex-row main bg-light p-1 "
        style={{ minWidth: "900px", marginBottom: "5px" }}
      > 
      <AvatarMexxar
          candidateId={userProfile?.candidateId}
          primaryPhone={userProfile?.primaryPhone}
          avatar={userProfile?.proPic}
          gender={userProfile?.gender}
        />

        <div className="pl-2 d-flex flex-column " style={{ flex: 3 }}>
          <div className="d-flex ml-1 mb-1">
            <div>
              {userProfile.candidateFirstName}&nbsp;
              {userProfile.candidateLastName}{" "}
            </div>
          </div>
          <div className=" d-flex ">
            <div className="mx-2 ">
              <Link to={"/dashboard/call/" + userProfile.candidateId}>
                <RoundIconButton
                  buttonColor="#00C426"
                  icon={faPhone}
                  iconSize="fa-md"
                  buttonSize="25px"
                ></RoundIconButton>
              </Link>
            </div>
            <div className="mx-2 ">
              <RoundIconButton
                buttonColor="#458AF7"
                icon={faEnvelope}
                click={() => {
                  toggleShowEmailModal();
                  handleSelectedCandidate(userProfile);
                }}
                buttonSize="25px"
              ></RoundIconButton>
            </div>
            <div className=" mx-2  d-flex align-items-center ">
              <FontAwesomeIcon
                color="#FF5603"
                icon={faCalendarAlt}
                onClick={() => handleCalendarShow(true)}
                className="fa-lg mb-2 btn-raised btn-wave "
              />
            </div>
          </div>
        </div>
        <div className="d-flex  align-items-center" style={{ flex: 1 }}>
          <FontAwesomeIcon
            // color={userProfile.gender == "1" ? "#458AF7" : "#f54394"}

            color={GenderSwitch(userProfile.gender).genderColor}
            // icon={userProfile.gender == "1" ? faMars : faVenus}
            icon={GenderSwitch(userProfile.gender).genderSymbol}
          />
        </div>
        <div
          className="d-flex align-items-center justify-content-center flex-column"
          style={{ flex: 4 }}
        >
          <div style={{ height: "4vh", flex: 1 }}>Call Response</div>
          <div style={{ height: "3vh", flex: 1 }}>
            {" "}
            <Badge
              color={
                ConversationResponse(userProfile.candidateCallResponseStatus)
                  .badgeColor
              }
            >
              {
                ConversationResponse(userProfile.candidateCallResponseStatus)
                  .badgeLable
              }
            </Badge>
          </div>

          <div style={{ height: "3vh", flex: 1 }}></div>
        </div>

        <div
          className="d-flex align-items-center flex-column"
          style={{ flex: 4 }}
        >
          <div style={{ height: "4vh", flex: 1 }}>Application Status</div>
          <div style={{ height: "3vh", flex: 1 }}>
            <Badge
              // color={ApplicationStatus(userProfile.candidateApplicationStatus).badgeColor}
              style={{
                backgroundColor: ApplicationStatus(
                  userProfile.candidateApplicationStatus
                ).badgeColor,
              }}
            >
              {
                ApplicationStatus(userProfile.candidateApplicationStatus)
                  .badgeLable
              }
            </Badge>
          </div>
          <div style={{ height: "3vh", flex: 1 }}></div>
        </div>

        <div style={{ flex: 1 }}>
          <div style={{ height: "4vh", flex: 1 }}></div>
          <div style={{ height: "3vh", flex: 1 }}></div>
          <div style={{ height: "3vh", flex: 1 }}></div>
        </div>
        <div className="d-flex flex-column " style={{ flex: 4 }}>
          {!showLastContacted ? (
            <>
              <div
                style={{
                  height: "4vh",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className={` d-flex ${
                    isNull(userProfile.lastContactedUserName)
                      ? "justify-content-center"
                      : "justify-content-between"
                  }`}
                >
                  <Label></Label>
                  <Label>Reschedule</Label>
                  {!isNull(userProfile.lastContactedUserName) && (
                    <FontAwesomeIcon
                      icon={faCaretSquareRight}
                      className="  btn-raised pointer shadow-none pointer"
                      onClick={() => handleShowLastContacted()}
                    />
                  )}
                </div>
              </div>
              <div className="d-flex flex-column align-items-center">
                <div style={{ height: "3vh" }}>
                  {userProfile.rescheduledCallerName}
                </div>
                <div className="d-flex justify-content-center ">
                  <span className="mr-1">
                    <small className="text-muted">
                      {moment(userProfile.rescheduledDate).format(
                        "ddd MMM DD YYYY"
                      )}
                    </small>
                  </span>
                  <span className="mr-1">
                    <small className="text-muted">
                      {moment(userProfile.rescheduledDate).format("hh:mm a")}
                    </small>
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div style={{ height: "4vh" }}>
                <div className="d-flex justify-content-between">
                  <Label></Label>
                  <Label>Last Contacted By</Label>
                  <FontAwesomeIcon
                    icon={faCaretSquareLeft}
                    className="  btn-raised pointer shadow-none pointer"
                    onClick={() => handleShowLastContacted()}
                  />
                </div>
              </div>
              <div className="d-flex flex-column  align-items-center ">
                <div style={{ height: "3vh" }}>
                  {userProfile.lastContactedUserName}
                </div>
                <div className="d-flex justify-content-center ">
                  <span className="mr-1">
                    <small className="text-muted">
                      {moment(userProfile.lastContactedDate).format(
                        "ddd MMM DD YYYY"
                      )}
                    </small>
                  </span>
                  <span className="mr-1">
                    <small className="text-muted">
                      {moment(userProfile.lastContactedDate).format("hh:mm a")}
                    </small>
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
        <div style={{ flex: 4 }}>
          <div
            className="d-flex justify-content-center mb-1 "
            style={{ maxHeight: "6vh" }}
          >
            Number of Calls
          </div>
          <div
            className=" d-flex justify-content-around "
            style={{ height: "3vh" }}
          >
            <div>
              <FontAwesomeIcon
                color="#00C426"
                icon={faPhone}
                className="fa-flip-horizontal"
              />
            </div>
            <div>
              <FontAwesomeIcon
                color="#F80055"
                icon={faPhoneSlash}
                className="fa-flip-horizontal"
              />
            </div>
          </div>
          <div
            className="d-flex justify-content-around"
            style={{ height: "3vh" }}
          >
            <div>{userProfile.answeredCount}</div>
            <div>{userProfile.notAnsweredCount}</div>
          </div>
        </div>
      </div>
      <ModalComponent
        show={showEmailModal}
        header="E mail and sms"
        closeModal={() => toggleM()}
      >
        <EmailNav closeModal={() => toggleM()} user={selectedCandidate} />
      </ModalComponent>

      <ModalComponent
        show={calendarShow}
        header="Reschedule a call"
        closeModal={() => setCalendarShow(false)}
      >
        <SmallCalendar
          onCreate={(data) => resheduleCall(data)}
          closeModal={() => setCalendarShow(false)}
        />
      </ModalComponent>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(HeaduntingCallBackWidget);
