import React, { useState, useEffect } from "react";
import {
  faPhone,
  faMicrophoneSlash,
  faMicrophone,
  faUpload,
  faChevronLeft,
  faChevronRight,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toLower } from "lodash";
import loadingAnimation from "../../../src/images/puff.svg";
import { HOSTMexxar } from "../../configs/api-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Label, Button, Input, UncontrolledTooltip } from "reactstrap";
import SortFunctionAscending from "../../components/sortFunction/sortByDateAscending";
import EmployeePayrollWidget from "../../widgets/payroll/candidate-payroll-pending-widget";
import NoSearchResults from "../errorPages/NoSearchResults";
import { useSpring, animated } from "react-spring";
import NoConnection from "../errorPages/NoConnection";
import { FunnelIcon } from "../../assets/icons/svg";

const filterByName = (name, dataSet) => {
  let filtered = dataSet;
  if (name !== "") {
    filtered = dataSet.filter((item) => {
      let searchableString = `${item.candidateName}   ${item.candidatesId}  ${item.clientId} ${item.clientName}  `;

      let p = searchableString;
      return p.toLowerCase().includes(name.toLowerCase());
    });
  }
  let lengthofSearch = filtered.length;
  return { filtered, lengthofSearch };
};

const TimesheetsReview = () => {
  const MexxarAPI = HOSTMexxar;
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getCandidates = () => {
    setLoading(true);
    axios
      .get(MexxarAPI + "workspace/booking/timesheet-upload?uploadStatus=false")
      .then((res) => {
        setRows(res.data.body);
        setLoading(false);
        if (res.data.status == "success") {
          setRows(res.data.body);
          setLoading(false);
        } else if (res.data.status == "failed") {
          setError(true);
        }
      })
      .catch((err) => {
        setError(true);
      });
  };

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  useEffect(() => {
    getCandidates();
    return () => {};
  }, []);

  return (
    <>
      <div className="form-inline mb-2">
        <FontAwesomeIcon icon={faFileAlt} className={"ml-2 mr-3  "} />
        <Label className={"mr-3"}>{rows.length} Timesheets</Label>
        <Button
          onClick={() => setIsOpenFilter(!isOpenFilter)}
          className="btn bg-dark-lt text-dark"
          disabled={true}
        >
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>

        <Input
          onChange={(e) => handleSearchOnChange(e)}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />
      </div>
      <UncontrolledTooltip target="searchBar">
        Search candidates by Name/Id/Email/Phone No.
      </UncontrolledTooltip>

      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      {rows && !loading ? (
        filterByName(searchQuery, rows).lengthofSearch > 0 ? (
          filterByName(
            searchQuery,
            rows.sort(SortFunctionAscending)
          ).filtered.map((row, i) => {
            return (
              <animated.div style={animationProps} key={i}>
                <EmployeePayrollWidget
                  key={i}
                  userProfile={row}
                  refreshPage={getCandidates}
                  //                  workflowStatusUpdated={(data) => refreshWorkflow(data)}
                ></EmployeePayrollWidget>
              </animated.div>
            );
          })
        ) : (
          <NoSearchResults />
        )
      ) : null}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}

      {/* <EmployeePayrollWidget></EmployeePayrollWidget> */}
    </>
  );
};

export default TimesheetsReview;
