import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  UncontrolledTooltip,
  Input,
  Label,
  Badge,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import ModalComponent from "../../components/Modal/modalComponent";
import TimeSheet from "../../components/Modal/ModalBody/timeSheet";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import { ClientLable, HOSTMexxar } from "../../configs/api-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import loadingAnimation from "../../../src/images/puff.svg";
import SortFunctionAscending from "../../components/sortFunction/sortByDateAscending";
import NoSearchResults from "../../pages/errorPages/NoSearchResults";
import NoConnection from "../../pages/errorPages/NoConnection";
import { useSpring, animated } from "react-spring";
import { FunnelIcon } from "../../assets/icons/svg";
import { Link } from "react-router-dom";
import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import AddRejectNoteModal from "../../components/AddANote/AddRejectNote";
import { sendNotification } from "../../util/common/sendNotification";

const filterByName = (name, dataSet) => {
  let filtered = dataSet;
  if (name !== "") {
    filtered = dataSet.filter((item) => {
      let searchableString = `${item.candidateName}   ${item.candidatesId}  ${item.clientId} ${item.clientName}  `;

      let p = searchableString;
      return p.toLowerCase().includes(name.toLowerCase());
    });
  }
  let lengthofSearch = filtered.length;
  return { filtered, lengthofSearch };
};

const EmployeePayrollWidgetVerified = () => {
  const [rows, setRows] = useState([]);
  const MexxarAPI = HOSTMexxar;
  const [calendarShow, setCalendarShow] = useState(false);
  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [data, setData] = useState();
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [candidateAmendedStatus, setCandidateAmendedStatus] = useState("");
  const { addToast } = useToasts();
  const toggleShowTimeModal = (data) => {
    setData(data);
    setShowTimeModal(!showTimeModal);
  };

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const [selectedRow, setSelectedRow] = useState(null);
  const handleReject = (row) => {
    setSelectedRow(row);
    toggleModal();
  };
  console.log("angi reject data needed-----?", selectedRow);

  const handleSaveRejectNote = (noteReject) => {
    if (selectedRow) {
      RejectTimesheet(selectedRow, noteReject);
      toggleModal();
    }
  };
  const getCandidates = () => {
    axios
      .get(MexxarAPI + "workspace/payroll?ps=PENDING&hrs=PENDING&rs=PENDING")
      .then((res) => {
        if (res.data.status === "success") {
          setRows(res.data.body);

          setLoading(false);

          if (res.data.candidateAmendedStatus) {
            setCandidateAmendedStatus(res.data.candidateAmendedStatus);
          } else {
            setCandidateAmendedStatus("Not_AMENDED");
          }
        } else if (res.data.status === "failed") {
          setError(true);
        }
      })
      .catch((err) => {
        setError(true);
      });
  };

  useEffect(() => {
    getCandidates();
    return () => {};
  }, []);

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  const RejectTimesheet = (row, rejectReason) => {
    const encodedRejectReason = encodeURIComponent(rejectReason);

    axios
      .patch(
        `${HOSTMexxar}timesheets/rejectTimesheet/${row.id}?status=DECLINE&rejectReason=${encodedRejectReason}`
      )
      .then((res) => {
        if (res.data.status === "Success") {
          console.log("Timesheet reject check", res.data);

          setRows((prevRows) =>
            prevRows.filter(
              (item) => item.booking.id !== row.booking.id && item.id !== row.id
            )
          );
          const { bookingRef, dateOfShift, startTime, endTime } =
            res.data.body[0];

          const formattedDate =
            moment(dateOfShift).format("dddd, MMMM D, YYYY");

          sendNotification({
            notificationType: "TIME_SHEET",
            title: `Timesheet for Booking ${bookingRef} on ${formattedDate} Rejected`,
            content: `Your timesheet has been rejected due to: ${rejectReason}. Scheduled time was from ${startTime} to ${endTime}.`,
            candidateId: row.booking.candidateId,
            startDate: new Date(),
            addToast: addToast,
          });
          ToastCommon(true, "success", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  return (
    <div>
      <div className="form-inline mb-2">
        <FontAwesomeIcon icon={faFileAlt} className={"ml-2 mr-3  "} />
        <Label className={"mr-3"}>
          {rows.length} {}Timesheets
        </Label>
        <Link to="/payroll/mobileTimesheetScan">
          <Button color="success" className=" mr-2">
            Batch Review
          </Button>
        </Link>
        <Button
          onClick={() => setIsOpenFilter(!isOpenFilter)}
          className="btn bg-dark-lt text-dark"
          disabled={true}
        >
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>

        <Input
          onChange={(e) => handleSearchOnChange(e)}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />
      </div>

      <UncontrolledTooltip target="searchBar">
        Search candidates by Name/Id/Client Id/{ClientLable} Name
      </UncontrolledTooltip>

      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}
      {rows && !loading ? (
        filterByName(searchQuery, rows).lengthofSearch > 0 ? (
          filterByName(searchQuery, rows).filtered.map((row, id) => {
            return (
              <animated.div style={animationProps} key={id}>
                <div className="card mb-2 " key={id}>
                  <div className="card-body">
                    <div className="flex flex-row d-flex justify-content-between ">
                      <div className="d-flex  " style={{ width: "250px" }}>
                        <div className="d-flex align-items-center mr-3 ">
                          <span className="mx-2">
                            <b className="badge badge-circle sm text-primary"></b>
                          </span>
                        </div>
                        <div>
                          <div>{row.booking.candidateName}</div>

                          <div className="item-mail text-muted h-1x d-none d-sm-block">
                            <time
                              dateTime="2020-12-20T00:00:00Z"
                              data-local="time"
                              data-format="%B %e, %Y %l:%M%P"
                              title="December 20, 2020 at 5:30am IST"
                              data-localized=""
                              aria-label="December 20, 2020 5:30am"
                            >
                              <small>
                                Booking Date :{" "}
                                {moment(row.booking.bookingDate).format("llll")}
                              </small>
                            </time>
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={{ width: "60px" }}
                      ></div>

                      <div className="textCenter-Custom">
                        <div>{ClientLable} Name</div>
                        <div className="text-muted">{row.hospitalName}</div>
                      </div>

                      <div className="textCenter-Custom">
                        <div>Submitted Date</div>
                        <div className="text-muted"> 
                          {moment(row.submitedDate).format("llll")}
                        </div>
                      </div>
                      <div className="textCenter-Custom">
                        <div>Candidate Status:</div>
                        <div>
                          <Badge
                            color={
                              candidateAmendedStatus === "AMENDED"
                                ? "success"
                                : "danger"
                            }
                          >
                            {candidateAmendedStatus}
                          </Badge>
                        </div>
                      </div>

                      <div>
                        <Button
                          color="success"
                          className="mr-2"
                          onClick={() => {
                            toggleShowTimeModal(row);
                          }}
                        >
                          Show
                        </Button>
                        {/* <Button
                          color="danger"
                          className="mr-2"
                          // 
                          onClick={toggleModal}
                        >
                        Reject
                        </Button> */}
                        <Button
                          color="danger"
                          onClick={() => handleReject(row)}
                        >
                          Reject
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </animated.div>
            );
          })
        ) : (
          <NoSearchResults />
        )
      ) : null}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}

      <ModalComponent
        show={calendarShow}
        header="Log new call back"
        closeModal={() => handleCalendarShow(false)}
      >
        <SmallCalendar />
        <div className="mt-3">
          {" "}
          <Button color="success">Send</Button>{" "}
          <Button color="secondary" onClick={() => handleCalendarShow(false)}>
            Cancel
          </Button>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showTimeModal}
        header="Time Sheet"
        size="lg"
        closeModal={() => setShowTimeModal(false)}
      >
        <TimeSheet
          data={data}
          closeModal={() => setShowTimeModal(false)}
        ></TimeSheet>
        <div className="mt-4">
          <Col sm={{ size: 8, offset: 8 }}></Col>
        </div>
      </ModalComponent>

      {/* <AddRejectNoteModal
            isOpen={isModalOpen}
            toggle={toggleModal}
            // onSave={RejectTimesheet}
      /> */}
      <AddRejectNoteModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        onSave={handleSaveRejectNote}
      />
    </div>
  );
};

export default EmployeePayrollWidgetVerified;
