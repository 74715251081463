import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import Select from "react-select";

import { loginRequest } from "../../configs/authConfig";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import ListGroup from "react-bootstrap/ListGroup";
import { OutlookSwitchIcon } from "../../util/common/outlookNavIconSwitch";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Row,
} from "reactstrap";
import { CommonEmail } from "../../configs/api-config";
import { connect } from "react-redux";
import EmailFormOutlook from "./EmailFormOutlook";
import loadingAnimation from "../../../src/images/puff.svg";
import {
  faExclamationCircle,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { UncontrolledTooltip } from "reactstrap/lib";
import { useHistory } from "react-router";
import EmailLogoutButton from "./emailLogoutButton";

const OutlookSideNav2 = ({
  onFolderClick,
  mailFolders,
  user,
  mailFoldersPending,
  reShapedAccounts,
  selectedAccount,
  setSelectedAccount,
  switchMessage,
  handleFolderExpand,
}) => {
  const { instance, accounts } = useMsal();
  const history = useHistory();

  const [showForm, setShowForm] = useState(false);
  const [commonEmailAdded, setCommonEmailAdded] = useState(false);

  const handleButtonClick = () => {
    setShowForm(true);
  };

  useEffect(() => {
    localStorage.setItem("outlookLoggedIn", true);
  }, []);

  useEffect(() => {
    localStorage.setItem("outlookLoggedIn", false);
  }, []);

  const login2ndAcc = () => {
    instance
      .loginPopup(loginRequest)
      .then((res) => {})
      .catch((e) => {});
  };

  useEffect(() => {
    let temp = accounts?.find((item) => item?.username === CommonEmail);
    if (temp) {
      setCommonEmailAdded(true);
    } else {
      setCommonEmailAdded(false);
    }
    return () => {};
  }, []);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: "1px solid #f54394",
      boxShadow: "0 0 0 0.2rem rgba(245, 67, 148, 0.25)",
    }),
  };

  // State to track expanded folders
  const [expandedFolders, setExpandedFolders] = useState({});
  const [showLogOutConfirmation, setShowLogOutConfirmation] = useState(false);

  // Toggle expand/collapse for a folder
  const handleToggleFolder = (folderId) => {
    handleFolderExpand(folderId);
    setExpandedFolders((prevState) => ({
      ...prevState,
      [folderId]: !prevState[folderId],
    }));
  };

  return (
    <div>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>

      <div className="fade aside aside-sm" id="content-aside">
        <div
          className="modal-dialog d-flex flex-column w-md bg-body"
          id="mail-nav"
        >
          {!commonEmailAdded ? (
            <Alert color="danger">
              Note: Please login to {CommonEmail} if you are not logged in
            </Alert>
          ) : null}

          <Card>
            <CardBody>
              <Row>
                <Col sm={12} className="m-2">
                  Switch Account
                  <FontAwesomeIcon
                    id="switchAccount"
                    className="mx-2 pointer"
                    icon={faExclamationCircle}
                  ></FontAwesomeIcon>
                  <UncontrolledTooltip
                    target={"switchAccount"}
                    className="pointer"
                  >
                    Switch in-between your outlook accounts
                  </UncontrolledTooltip>
                  <EmailLogoutButton
                    setShowLogOutConfirmation={setShowLogOutConfirmation}
                    showLogOutConfirmation={showLogOutConfirmation}
                  />
                </Col>

                <Col sm={12}>
                  <Select
                    isSearchable={false} // Disable typing
                    styles={colourStyles}
                    type="select"
                    // isMulti
                    id="trainngId"
                    name="trainngId"
                    value={selectedAccount}
                    onChange={(event) => {
                      setSelectedAccount(event);
                    }}
                    options={reShapedAccounts?.length > 0 && reShapedAccounts}
                  ></Select>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Label className="mt-2">Sign in with different account</Label>
                  <Button
                    className="btn-sm mt-2 btn-success"
                    onClick={login2ndAcc}
                  >
                    Login
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <Label className="mt-2"> {switchMessage}</Label>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className="navbar">
            <span className="text-md" style={{ fontWeight: "bold" }}>
              Outlook Email{" "}
            </span>
          </div>

          <div className="">
            <div className="p-2 px-3">
              <button
                className="btn  btn-block btn-md box-shadows gd-primary text-white"
                id="btn-new-mail"
                onClick={handleButtonClick}
              >
                New mail
              </button>
            </div>

            <div className="sidenav scrollable  my-1 "></div>

            <div className="sidenav scrollable  my-1 ">
              <nav className="nav-active-text-primary " data-nav>
                <ul className="sidebar">
                  {mailFolders?.length > 0 ? (
                    <>
                      {mailFolders?.map((folder, index) => (
                        <div key={index}>
                          <ListGroup.Item
                            className="d-flex my-1 align-items-start p-1 pointer"
                            onClick={() =>
                              onFolderClick(folder.id, folder.displayName)
                            }
                          >
                            <i
                              className="p-1"
                              onClick={() => handleToggleFolder(folder.id)}
                            >
                              <FontAwesomeIcon
                                color={
                                  OutlookSwitchIcon(folder.displayName)
                                    .outlookColor
                                }
                                icon={
                                  OutlookSwitchIcon(folder.displayName)
                                    .outlookSymbol
                                }
                              />
                            </i>
                            <div className="p-1 d-flex flex-column">
                              {folder.displayName}
                            </div>
                            <div style={{ whiteSpace: "pre-wrap" }}>
                              {(folder.displayName === "Inbox" ||
                                folder.displayName === "Drafts") && (
                                <span className="badge badge-pill align-end">
                                  {folder.unreadItemCount}
                                </span>
                              )}
                            </div>
                            {/* Toggle icon */}
                            {folder?.childFolderCount >= 1 ? (
                              <i
                                className="p-1"
                                onClick={() => handleToggleFolder(folder.id)}
                                style={{ cursor: "pointer" }}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    expandedFolders[folder.id]
                                      ? faMinus
                                      : faPlus
                                  } // Toggle icon
                                />
                              </i>
                            ) : null}
                          </ListGroup.Item>
                          {/* Conditionally render child folders */}
                          {expandedFolders[folder.id] &&
                            folder.childFolders &&
                            folder.childFolders.length > 0 && (
                              <div className="ml-3">
                                {" "}
                                {/* Optional: Add some margin for indentation */}
                                {folder.childFolders.map(
                                  (childFolder, childIndex) => (
                                    <ListGroup.Item
                                      key={`child-${childIndex}`}
                                      className="d-flex my-1 align-items-start p-1"
                                      onClick={() =>
                                        onFolderClick(
                                          childFolder.id,
                                          childFolder.displayName
                                        )
                                      }
                                    >
                                      <i className="p-1">
                                        <FontAwesomeIcon
                                          color={
                                            OutlookSwitchIcon(
                                              childFolder.displayName
                                            ).outlookColor
                                          }
                                          icon={
                                            OutlookSwitchIcon(
                                              childFolder.displayName
                                            ).outlookSymbol
                                          }
                                        />
                                      </i>
                                      <div className="p-1 d-flex flex-column">
                                        {childFolder.displayName}
                                      </div>
                                      <div style={{ whiteSpace: "pre-wrap" }}>
                                        {(childFolder.displayName === "Inbox" ||
                                          childFolder.displayName ===
                                            "Drafts") && (
                                          <span className="badge badge-pill align-end">
                                            {childFolder.unreadItemCount}
                                          </span>
                                        )}
                                      </div>
                                    </ListGroup.Item>
                                  )
                                )}
                              </div>
                            )}
                        </div>
                      ))}

                      <br></br>
                    </>
                  ) : (
                    <div>Please refresh the page</div>
                  )}

                  {mailFoldersPending && !mailFolders?.length > 0 && (
                    <div className="d-flex justify-content-center align-items-center">
                      <div>
                        <img
                          src={loadingAnimation}
                          style={{ height: 100 }}
                        ></img>
                      </div>
                    </div>
                  )}
                </ul>
              </nav>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
          {/* <EmailComposeModal
              showForm={showForm}
              closeModal={() => setShowForm(false)}
              type="NEW"
              sender={user}
            /> */}
          {showForm && (
            <EmailFormOutlook
              composeType={"NEW"}
              // emailBody={item?.body}
              closeModal={() => setShowForm(false)}
              // from={item?.from}
              // dateAndTime={item?.sentDateTime}
              // subjectIncomming={item?.subject}
              // cc={item?.ccRecipients}
              // bcc={item?.bccRecipients}
              // to={item?.toRecipients}
              // email={item}
            />
          )}
        </div>
      </div>
    </div>
  );
};

function mapStatetoProps(state) {
  return {
    user: state.auth.item,
  };
}

export default connect(mapStatetoProps, {})(OutlookSideNav2);
