import React, { useState, useEffect, useRef } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardHeader,
  CardText,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { CompanyName, HOSTMexxar } from "../../../configs/api-config";
import axios from "axios";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import PdfViewerModal from "../../PDFView/PDFView";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import 'jspdf-autotable';

// import { PDFDocument, rgb, StandardFonts } from '@pdf-lib/core';
const { Document, Page, pdfjs } = require("react-pdf"); //eslint-disable-line

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; //eslint-disable-line

export default function CandidateOnlineChecksDataAdding({
  consultantLastName,
  consultantFirstName,
  candidateData,
  type,
  onClose,
  resetCandidate,
}) {
  const { addToast } = useToasts();

  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const [errorMessage, setErrorMessage] = useState({
    message: "",
    bool: false,
  });
  const [nmcCheckData, setNmcCheckData] = useState(null);
  const [defaultRegisterNoValue, setDefaultRegisterNoValue] = useState("");
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [checkIsProcessing, setCheckIsProcessing] = useState(false);
  const [candidateDOB, setCandidateDOB] = useState(candidateData?.dateOfBirth);
  const [pdfBlobs, setPdfBlobs] = useState([]);
  const [candidateLastName, setCandidateLastName] = useState(
    candidateData?.lastName
  );

  const updateDBSRegNo = (data) => {
    axios
      .patch(
        `${HOSTMexxar}candidates/${candidateData.id}/dbsReference?reference=${data.registerNo}`
      )
      .then((res) => {
        if (res.data.status === "success") {
          resetCandidate();
          ToastCommon(true, "success", addToast);
          setErrorMessage({ message: "", bool: false });
        } else {
          setErrorMessage({ message: res.data.status, bool: true });
        }
      })
      .catch((error) => {
        // ToastCommon(true, "error", addToast);
      });
  };

  const updateHCPCProfCode = (data) => {
    axios
      .patch(
        `${HOSTMexxar}candidates/${candidateData.id}/hcpcProfCode?profCode=${data.profCode}`
      )
      .then((res) => {
        if (res.data.status === "success") {
          resetCandidate();
          ToastCommon(true, "success", addToast);
        } else {
          setErrorMessage({ message: res.data.status, bool: true });
        }
      })
      .catch((error) => {
        // ToastCommon(true, "error", addToast);
      });
  };

  const updateHCPCRegNo = (data) => {
    return axios
      .patch(
        `${HOSTMexxar}candidates/${candidateData.id}/hcpcRegNumber?regNumber=${data.registerNo}`
      )
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "success", addToast);
          resetCandidate();
          return res; // Pass the response to the next then()
        } else {
          setErrorMessage({ message: res.data.status, bool: true });
          throw new Error(res.data.status); // Reject the promise with an error
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error in updateHCPCRegNo:", error);
        // You can throw or handle the error as needed
      });
  };
  const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);

  // Handler for the save button
  const handleSave = (e) => {
    e.preventDefault();
    // Perform your save logic here

    // Assuming save is successful, update the state
    setIsSaveSuccessful(true);
  };
  const updateNMCRegNo = (data) => {
    axios
      .patch(
        `${HOSTMexxar}candidates/${candidateData.id}/nmcPin?pin=${data.registerNo}`
      )
      .then((res) => {
        if (res.data.status === "success") {
          resetCandidate();
          ToastCommon(true, "success", addToast);
        } else {
          setErrorMessage({ message: res.data.status, bool: true });
        }
      })
      .catch((error) => {
        // ToastCommon(true, "error", addToast);
      });
  };
  // const updateGMCRegNo = (data) => {
  //   axios
  //     .patch(
  //       `${HOSTMexxar}candidates/${candidateData.id}/hcpcRegNumber?regNumber=${data.registerNo}`
  //     )
  //     .then((res) => {
  //       //if this is success save the data in the candidate object to show the data in cards for later
  //       // ToastCommon(true, "success", addToast);
  //     })
  //     .catch((error) => {
  //       // ToastCommon(true, "error", addToast);
  //     });
  // };
  const [hCPCRegisteredData, setHCPCRegisteredData] = useState(null);
  const HCPCCheck = () => {
    setErrorMessage({ message: "", bool: false });
    setCheckIsProcessing(true);
  
    axios
      .get(`${HOSTMexxar}complianceCheck/hcpc/check?candidateId=${candidateData.id}`)
      .then((res) => {
        setCheckIsProcessing(false);
        if (res.data.status === "success") {
          resetCandidate();
          setHCPCRegisteredData(res.data.body);
  
          if (res.data.body?.pdfBase64) {
            convertToPDF(res.data.body.pdfBase64);
            setShowPDFModal(true); // Only show the modal if pdfBase64 is not null
          } else {
            // Handle the case where pdfBase64 is null
            setErrorMessage({ message: "PDF not available", bool: true });
          }
  
          ToastCommon(true, "success", addToast);
        } else {
          setErrorMessage({ message: res.data.status, bool: true });
        }
      })
      .catch((error) => {
        setCheckIsProcessing(false);
        ToastCommon(true, "error", addToast);
      });
  };
  

  
  const DBSCheck = () => {
    setCheckIsProcessing(true);
    axios
      .get(
        `${HOSTMexxar}complianceCheck/dbs/check?orgName=${CompanyName}&employeeForename=${consultantFirstName}&employeeSurname=${consultantLastName}&candidateSurname=${candidateLastName}&candidateId=${
          candidateData.id
        }`
        //  `https://permapi.mexxar.com/complianceCheck/dbs/check?orgName=Candy Healthcare&employeeForename=Hasintha&employeeSurname=Gamage&candidateSurname=Doe&candidateId=63`
      )
      .then((res) => {
        setCheckIsProcessing(false);
        if (res.data.status === "success") {
          convertToPDF(res.data.body?.pdfBase64);

          setShowPDFModal(true);
          ToastCommon(true, "success", addToast);
        } else {
          setErrorMessage({ message: res.data.message, bool: true });
        }
      })
      .catch((error) => {
        setCheckIsProcessing(false);
        ToastCommon(true, "error", addToast);
      });
  };
  const [isDobUpdateSuccessful, setIsDobUpdateSuccessful] = useState(false);

  const updateDob = (data) => {
    return axios
      .patch(
        HOSTMexxar +
          "candidates/" +
          candidateData.id +
          "/dateOfBirth?dateOfBirth=" +
          moment(data.dob).format("DD/MM/YYYY")
      )
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "success", addToast);
          setIsDobUpdateSuccessful(true); 
        } else {
          setErrorMessage({ message: res.data.status, bool: true });
        }
      })
      .catch((error) => {});
  };

  const onSubmit = (data) => {
    setErrorMessage({ message: "", bool: false }); 
    // Make sure to casecade these functions as below
    // or else the data will not save propely returning null values for the updated fields
    updateDob(data)
      .then(() => {
        if (type === "NMC") {
          // Handle NMC logic
          updateNMCRegNo(data);
        } else if (type === "GMC") {
          // Handle GMC logic
          // updateGMCRegNo(data);
        } else if (type === "HCPC") {
          // Handle HCPC logic
          updateHCPCRegNo(data)
            .then(() => {
              updateHCPCProfCode(data);
            })
            .catch((error) => {
              // Handle any errors that occurred in updateDob
              ToastCommon(true, "error", addToast);
              console.error("Error in updateHCPCRegNo:", error);
            });
        } else if (type === "DBS") {
          // Handle DBS logic
          updateDBSRegNo(data);
          // DBSCheck(data);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred in updateDob
        ToastCommon(true, "error", addToast);
        console.error("Error in updateDob:", error);
      });
  };



  const NMCCheck = () => {
    setCheckIsProcessing(true);
    axios
      .post(`${HOSTMexxar}complianceCheck/nmc/`, [candidateData?.id])
      .then((res) => {
        setCheckIsProcessing(false);
  
        if (res.data.status === "success") {
        
  
          setNmcCheckData(res.data.body);
       
          const nmcItem = res.data.body?.nmcItems[0]; 
  
          const nmcStatus = nmcItem?.status[0]?.comment;
          if (nmcStatus === "Registered" || nmcStatus === "Fail - Lapsed")  {
            convertToPDF(res.data.body?.pdfBase64);
            ToastCommon(true, "success", addToast);
            setShowPDFModal(true);
          } else {
            setErrorMessage({ message: "PDF not available.", bool: true });
          }
        } else {
          setErrorMessage({ message: res.data.status, bool: true });
        }
      })
      .catch((error) => {
        setCheckIsProcessing(false);
        ToastCommon(true, "error", addToast);
      });
  };
  useEffect(() => {
    if (nmcCheckData) {
   
    }
  }, [nmcCheckData]);

  const GMCCheck = () => {};

  const handleCheck = () => {
    switch (type) {
      case "NMC":
        NMCCheck();
        break;
      case "HCPC":
        HCPCCheck();
        break;
      case "GMC":
        GMCCheck();
        break;
      case "DBS":
        DBSCheck();
        break;
      default:
        // Handle default case or do nothing
        break;
    }
  };
  useEffect(() => {
    if (nmcCheckData) {
   
    }
  }, [nmcCheckData]);
  
  function getDefaultValue(type, candidateData) {
    switch (type) {
      case "NMC":
        return candidateData?.complianceNmcCheck?.nmcPin;
      case "GMC":
        return candidateData?.complianceGmcCheck?.gmcRegistrationNumber;
      case "HCPC":
        return candidateData?.complianceHcpcCheck?.hcpcRegistrationNumber;
      case "DBS":
        return candidateData?.complianceDbsCheck?.dbsReference;
      default:
        return "";
    }
  }

  const convertToPDF = (base64Array) => {
    const base64toBlob = (base64Data) => {
      const binaryData = atob(base64Data);
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }
      return new Blob([uint8Array], { type: "application/pdf" });
    };


    const blobs = base64toBlob(base64Array);

    setPdfBlobs(blobs);
  };

  const downloadNMCCheckPDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    
    const timestamp = moment(new Date()).format("MMMM Do YYYY, h:mm:ss a");
    doc.setFontSize(10);
    doc.text(`Downloaded at: ${timestamp}`, 20, 20);
  
    const imageUrl = "https://upload.wikimedia.org/wikipedia/en/0/06/Nursing_and_Midwifery_Council_%28logo%29.jpg";
    doc.addImage(imageUrl, "JPEG", 20, 30, 50, 50);
  
    const nmcResponseData = nmcCheckData;
  
    const tableWidth = 190; 
    const margin = 20;
    const startX = (pageWidth - tableWidth) / 2; 
    let yPos = 100;
    const rowHeight = 15;
  
    const colWidths = [
      30, 45, 30, 56, 30, 35, 30, 45
    ];
    const totalColWidth = colWidths.reduce((a, b) => a + b, 0);
    const adjustedColWidths = colWidths.map(w => (w / totalColWidth) * tableWidth);
  
    if (nmcResponseData && nmcResponseData.nmcItems && nmcResponseData.nmcItems.length > 0) {
      const tableHeaders = [
        "Date of Birth",
        "Registration Number",
        "Qualification",
        "Registered Name",
        "Start Date",
        "Fee Expiry Date",
        "Renewal Date",
        "Comment"
      ];
  
      doc.setFontSize(8);
      doc.setFillColor(200, 200, 200);
      doc.rect(startX, yPos, tableWidth, rowHeight, 'F');
      doc.setTextColor(0, 0, 0);
  
      tableHeaders.forEach((header, i) => {
        doc.text(header, startX + adjustedColWidths.slice(0, i).reduce((a, b) => a + b, 0) + 2, yPos + 7);
      });
  
      yPos += rowHeight;
  
      nmcResponseData.nmcItems.forEach((item) => {
        item.status.forEach((status) => {
          const rowData = [
            item.dateOfBirth,
            item.regNum,
            status.qualification,
            status.registeredName,
            status.startDate,
            status.feeExpiryDate,
            status.renewalDate,
            status.comment
          ];
  
          if (status.comment === "Invalid" ) {
            doc.setFillColor(255, 0, 0);
            doc.rect(startX, yPos, tableWidth, rowHeight, 'F');
            doc.setTextColor(255, 255, 255);
          } else {
            doc.setTextColor(0, 0, 0);
          }
  
          rowData.forEach((data, i) => {
            doc.text(data, startX + adjustedColWidths.slice(0, i).reduce((a, b) => a + b, 0) + 2, yPos + 7);
          });
  
          doc.setDrawColor(0, 0, 0);
          doc.rect(startX, yPos, tableWidth, rowHeight);
  
          yPos += rowHeight;
        });
      });
  
      doc.setDrawColor(0, 0, 0);
      doc.rect(startX, 100, tableWidth, yPos - 100);
    }
  
    const pdfBlob = doc.output("blob");
    const link = document.createElement("a");
    link.href = URL.createObjectURL(pdfBlob);
    link.download = `${candidateData?.firstName}_${candidateData?.lastName}_ID_${candidateData?.id}_` +
      `${defaultRegisterNoValue}-${moment(new Date()).format("DD-MM-YYYY hh:mm a")}.pdf`;
  
    link.click();
  };
  
   //------------ DBS Download PDF Function --------------//


  const downloadDBSCheckPDF = () => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(pdfBlobs);
 
    link.download = `${candidateData?.firstName}_${
      candidateData?.lastName
    }_ID_${candidateData?.id}_${defaultRegisterNoValue}-${moment(
      new Date()
    ).format("DD-mm-YYYY hh:mm: a")}.pdf`;

    link.click();
  };

 //--------------- HCPC Download PDF Function -----------------//

  const downloadHCPCCheckPDF = () => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(pdfBlobs);

    link.download = `${candidateData?.firstName}_${
      candidateData?.lastName
    }_ID_${candidateData?.id}_${defaultRegisterNoValue}-${moment(
      new Date()
    ).format("DD-mm-YYYY hh:mm: a")}.pdf`;

    link.click();
  };

  useEffect(() => {
    if (
      getDefaultValue(type, candidateData) === undefined ||
      getDefaultValue(type, candidateData) === ""
    ) {
      setDefaultRegisterNoValue("");
    } else {
      setDefaultRegisterNoValue(getDefaultValue(type, candidateData));
    }
    return () => {};
  }, [type, candidateData]);

  const modalRef = useRef(null);

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} ref={modalRef}>
        <FormGroup>
          <Row>
            <Col md={6}>Candidate DOB</Col>
            <Col md={6}>
              <Input
                type="date"
                value={candidateDOB}
                id="dob"
                onChange={(e) => setCandidateDOB(e.target.value)}
                name="dob"
                invalid={errors.dob}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              ></Input>
              {errors.dob && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    {errors.dob?.message}
                  </li>
                </ul>
              )}
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
            <Col md={6}>Select Online Check</Col>
            <Col md={6}>
              <Input
                type="text"
                name="type"
                id="type"
                disabled
                value={type}
              ></Input>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
            <Col md={6}>{type} Register No</Col>
            <Col md={6}>
              <Input
                type="text"
                value={defaultRegisterNoValue}
                onChange={(e) => setDefaultRegisterNoValue(e.target.value)}
                id="registerNo"
                name="registerNo"
                invalid={errors.registerNo}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              ></Input>
              {errors.registerNo && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    {errors.registerNo?.message}
                  </li>
                </ul>
              )}
            </Col>
          </Row>
        </FormGroup>
        {type === "DBS" ? (
          <FormGroup>
            <Row>
              <Col md={6}>Last Name</Col>
              <Col md={6}>
                <Input
                  type="text"
                  value={candidateLastName}
                  onChange={(e) => setCandidateLastName(e.target.value)}
                  id="lastName"
                  name="lastName"
                  invalid={errors.lastName}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                  })}
                ></Input>
                {errors.lastName && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      {errors.registerNo?.message}
                    </li>
                  </ul>
                )}
              </Col>
            </Row>
          </FormGroup>
        ) : null}

        {/* Add other form fields as needed */}
        {type === "HCPC" && (
          <FormGroup>
            <Row>
              <Col md={6}>Prof code</Col>
              <Col md={6}>
                <Input
                  type="text"
                  id="profCode"
                  name="profCode"
                  defaultValue={
                    candidateData?.complianceHcpcCheck?.hcpcProfCode
                  }
                  invalid={errors.profCode}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                  })}
                ></Input>
                {errors.profCode && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      {errors.profCode?.message}
                    </li>
                  </ul>
                )}
              </Col>
            </Row>
          </FormGroup>
        )}

        {type === "DBS" && (
          <div className="my-1">
            <PdfViewerModal
              isOpen={showPDFModal}
              setOpen={(data) => setShowPDFModal(data)}
              path={pdfBlobs}
              downloadPDF={downloadDBSCheckPDF}
            />
          </div>
        )}

        {type === "NMC" && (
          <div className="my-1">
            <PdfViewerModal
              isOpen={showPDFModal}
              setOpen={(data) => setShowPDFModal(data)}
              path={pdfBlobs}
              downloadPDF={downloadNMCCheckPDF}
            />
          </div>
        )}

        {type === "HCPC" && (
          <div className="my-1">
            <PdfViewerModal
              isOpen={showPDFModal}
              setOpen={(data) => setShowPDFModal(data)}
              path={pdfBlobs}
              downloadPDF={downloadHCPCCheckPDF}
            />
          </div>
        )}

{errorMessage.bool && (
  <Alert color="danger">
    {errorMessage.message}

    {/* Show NMC Invalid Results inside the error message */}

    <br/> <br/>
    {type === "NMC" && 
      nmcCheckData?.nmcItems[0]?.status[0]?.comment === "Invalid" && (
        <div>
          <FormGroup>
            <Row>
              <Col md={12} className="font-weight-bold">NMC Results</Col>
            </Row>
            <hr />
            <Row>
              <Col md={6}>Comment</Col>
              <Col md={6}>{nmcCheckData?.nmcItems[0]?.status[0]?.comment || "N/A"}</Col>
            </Row>
            <br />
            <Row>
              <Col md={6}>Registered Number</Col>
              <Col md={6}>{nmcCheckData?.nmcItems[0]?.regNum || "N/A"}</Col>
            </Row>
            <br />
            <Row>
              <Col md={6}>Qualification</Col>
              <Col md={6}>{nmcCheckData?.nmcItems[0]?.qualification || "N/A"}</Col>
            </Row>
            <br />
            <Row>
              <Col md={6}>Registered Name</Col>
              <Col md={6}>{nmcCheckData?.nmcItems[0]?.registeredName || "N/A"}</Col>
            </Row>
          </FormGroup>
        </div>
      )}
  </Alert>
)}


        <FormGroup className="mb-0">
          <Row>
            {checkIsProcessing && (
              <Alert color="warning">
                Please wait. Your check is being processed. Do not close the
                modal or window.
              </Alert>
            )}
          </Row>
          


          {type === "HCPC" && hCPCRegisteredData && (
            <Alert color="success">
              <FormGroup>
                <Row>
                  <Col md={12} className="font-weight-bold">
                    Results
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md={6}>Name</Col>
                  <Col md={6}>{hCPCRegisteredData?.detailitem?.name}</Col>
                </Row>
                <br/>
                <Row>
                  <Col md={6}>Registered From</Col>
                  <Col md={6}>
                    {moment(
                      hCPCRegisteredData?.detailitem?.registeredFrom
                    ).format("DD/MM/YYYY")}
                  </Col>
                </Row>{" "}
                <br/>
                <Row>
                  <Col md={6}>Registered To</Col>
           
                  <Col md={6}>
                    {moment(
                      hCPCRegisteredData?.detailitem?.registeredTo
                    ).format("DD/MM/YYYY")}
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col md={6}>Status</Col>
           
                  <Col md={6}>
                    <div color="success">
                      {hCPCRegisteredData?.detailitem?.status}
                    </div>
              
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col md={6}>Town</Col>
                  <Col md={6}>
                    {hCPCRegisteredData?.detailitem?.town === ""
                      ? "N/A"
                      : hCPCRegisteredData?.detailitem?.town}
                  </Col>
                </Row>{" "}
                <br/>
                <Row>
                  <Col md={6}>No Schema Location</Col>
                  <Col md={6}>{hCPCRegisteredData?.noSchemaLocation}</Col>
                </Row>
              </FormGroup>
            </Alert>
          )}
          <Row>
            <Col
              md={12}
              className="d-flex align-items-center justify-content-end"
            >
            {candidateDOB &&
        ((candidateData?.complianceDbsCheck?.dbsReference && type === "DBS") ||
          (candidateData?.complianceHcpcCheck?.hcpcRegistrationNumber && type === "HCPC") ||
          (candidateData?.complianceNmcCheck?.nmcPin && type === "NMC")) &&
        (candidateLastName !== "" || candidateLastName !== null) &&
        isDobUpdateSuccessful ? (
        <Button
          disable={checkIsProcessing}
          color="success"
          onClick={handleCheck}
        >
          {checkIsProcessing ? "Processing" : "Process"}
          {checkIsProcessing && (
            <Spinner
              className="mx-2"
              style={{
                height: "20px",
                width: "20px",
                marginRight: "5px",
              }}
              loading={checkIsProcessing}
            />
          )}
        </Button>
      ) : null}


      
              <Button className="btn btn-success mx-2" type="submit">
                Save
              </Button>
              <Button onClick={() => onClose()}>Close</Button>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </div>
  );
}
