import React, { useState } from "react";
import "./login.style.css";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import logo from "../../../images/logo.png";


import {
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Spinner,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { loginUser, setAuthetication } from "../../../redux/actions/authAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const onFormSubmit = (loginUser, formData) => {
  loginUser(formData);
};

const Login = (props) => {
  const { register, handleSubmit, watch, errors } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="container-fluid">
      <Row></Row>
      <div className="side-left  ">{/* <Imageslider />  */}</div>
      <div className="side-right ">
        <div className="logo-container p-4">
          <img className="logo-center" src={logo} alt="LOGO"></img>
        </div>
        <h4>Login</h4>
        <Form
          onSubmit={handleSubmit((data) => onFormSubmit(props.loginUser, data))}
        >
          {props.authenticated.invalidCredentials && (
            <div className="alert alert-danger" role="alert">
              Error: Your username or password incorrect
            </div>
          )}

          {props.authenticated.systemError && (
            <div className="alert alert-danger" role="alert">
              Error: Something went wrong please try again later
            </div>
          )}
          <FormGroup name="email">
            <Label for="email">Email Address</Label>
            <Input
              type="text"
              invalid={errors.email}
              style={{ backgroundColor: "#1b223e" }}
              placeholder="example@mexxar.com"
              id="email"
              name="email"
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
                pattern: {
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please enter a valid email address!",
                },
              })}
            ></Input>
            {errors.email && (
              <ul
                className="list-unstyled text-sm mt-1 text-danger filled"
                id="parsley-id-119"
              >
                <li className="parsley-required">{errors.email?.message}</li>
              </ul>
            )}
          </FormGroup>
          <Label for="password">Password</Label>

          <FormGroup name="password" row>
            <Col xs={11} sm={11} md={11} className="pr-3">
              <Input
                type={showPassword ? "text" : "password"}
                style={{ backgroundColor: "#1b223e" }}
                invalid={errors.password}
                id="password"
                placeholder="••••••••"
                name="password"
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                  pattern: {
                    value:
                      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                    message:
                      "Password must contain at least one Capital, Lower, Number, Special Character and 8 characters",
                  },
                })}
              ></Input>
              {errors.password && (
                <ul
                  className="list-unstyled text-sm mt-1 text-danger filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required">
                    {errors.password?.message}
                  </li>
                </ul>
              )}
            </Col>

            <Col xs={1} sm={1} md={1} className="mt-2 pl-0">
              {showPassword ? (
                <FontAwesomeIcon
                  id="showPassword"
                  icon={faEye}
                  className=" btn-raised btn-wave pointer"
                  onClick={() => setShowPassword(false)}
                  style={{
                    boxShadow: "none",
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faEyeSlash}
                  className=" btn-raised btn-wave pointer"
                  onClick={() => setShowPassword(true)}
                  style={{
                    boxShadow: "none",
                  }}
                />
              )}
            </Col>
          </FormGroup>
          <FormGroup>
            <Button
              size="sm"
              className="w-50 btn-cyan "
              color="primary"
              type="submit"
            >
              {props.authenticated.loggingInProgress && (
                <Spinner animation="border" />
              )}
              {!props.authenticated.loggingInProgress && "Login"}
            </Button>
          </FormGroup>

          {/* <Link component={}>Don't Have an account?</Link> */}
        </Form>
        <div className="flex-container">
          {/* <a href="" className="column text-right">
            Forgot Password?
          </a> */}
          <Link to="/forgot-password/forgot-password-page">
            <div className="column text-right">Forgot Password</div>
          </Link>
        </div>

        <div className="copyright text-center p-4">
          <p>
            Copyright {new Date().getFullYear()} @ Mexxar
          </p>
          <p>Powered by Mexxar</p>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth,
   };
}

export default connect(mapStateToProps, { loginUser })(Login);