import React, { useEffect, useState } from "react";
import PayrollCallBacks from "./callBack";
import TimesheetsReview from "./timesheets-review";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import CandidateReschedules from "../../widgets/payroll/candidate-payroll-reschedule-widget";
import EmployeePayrollWidgetUploaded from "../../widgets/payroll/candidate-payroll-uploaded-widget";
import EmployeePayrollWidgetVerified from "../../widgets/payroll/EmployeePayrollWidgetVerified";
import { Button, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { PAYROLL_WORKSPACE_SUMMARY } from "../../configs/api-config";
import { useIsMounted } from "../../customHooks/useIsMounted";
import axios from "axios";
import { connect } from "react-redux";
import TabNavigationMenu from "../../util/common/tabNavigation";
import EmployeePayrollWidgetRejected from "../../widgets/payroll/EmployeePayrollWidgetRejected";

const Payroll = (props) => {
  const { auth } = props;
  const isMounted = useIsMounted();
  const [summery, setSummery] = useState({});
  const [currentTab, setCurrentTab] = useState("pending");
  const [employeeID, setEmployeeID] = useState(auth.item.userTypeId);

  const tab = window.location.hash.substring(1);

  useEffect(() => {
    if (tab === "") {
      setCurrentTab("pending")
    } else {
      setCurrentTab(tab)
    }
  }, [tab])



  const MexxarApi = PAYROLL_WORKSPACE_SUMMARY;

  const getData = () => {
    if (employeeID) {
      axios
        .get(MexxarApi + "?employeeId=" + employeeID)
        .then((res) => {
          if (isMounted.current) {
            setSummery(res.data.body);
          }
        })
        .catch((err) => {
          //   console.log(err);
        });
    }
  };

  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
  };

  useEffect(() => {
    getData();
    return () => { };
  }, []);
  return (
    <>
      <DashboardLayout
        title="Payroll"
        subTitle="Payroll Process"
        showPayrollTargets={true}
        summeryData={summery}
      >
        <div className="m-2 d-flex justify-content-end mr-4  ">
          <div className="mx-2">
            <Link to="/payroll/scanTimeSheets">
              <Button color="success">Quick Timesheet Scan</Button>
            </Link>
          </div>
          <div>
            <Link to="/payroll/scanTimeSheetsBatches">
              <Button color="success">BTS-Normal</Button>
            </Link>
          </div>
          <div className="mx-2">
            <Link to="/payroll/scanTimeSheetsBatches2">
              <Button color="success">BTS-Normal-External</Button>
            </Link>
          </div>

          <div className="ml-3">
            <Link to="/payroll/candyscanTimeSheetsBatches">
              <Button color="success">C-BTS</Button>
            </Link>
          </div>
        </div>



        <TabNavigationMenu
          activated={currentTab}
          onChangeItem={switchTabHandler}
          itemList={[
            { name: "Pending", key: "pending" },
            { name: "Uploaded", key: "uploaded" },
            { name: "Verified", key: "verified" },
            { name: "Rejected", key: "rejected" },
            // { name: "Reschedules", key: "reschedules" },
          ]}
        />

        {currentTab === "pending" && <TimesheetsReview></TimesheetsReview>}
        {currentTab === "uploaded" && (
          <EmployeePayrollWidgetUploaded></EmployeePayrollWidgetUploaded>
        )}
        {currentTab === "verified" && (
          <EmployeePayrollWidgetVerified></EmployeePayrollWidgetVerified>
        )}
        {currentTab === "reschedules" && (
          <CandidateReschedules></CandidateReschedules>
        )}
        {currentTab === "reschedule" && <PayrollCallBacks />}
        {currentTab === "rejected" && (
          <EmployeePayrollWidgetRejected></EmployeePayrollWidgetRejected>
        )}
      </DashboardLayout>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Payroll);
