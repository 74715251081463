import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../configs/authConfig";
import { callMsGraph } from "./graph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import setAuthorizationToken from "../../util/auth/SetAuthorizationToken";
import { useHistory } from "react-router";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";

import Select from "react-select";
import moment from "moment";
import { OutlookSwitchIcon } from "../../util/common/outlookNavIconSwitch";
import NoSearchResults from "../errorPages/NoSearchResults";

const OutlookFilterandHeader = ({
  folderName,
  selectedFolder,
  selectedAccount,
  mailFolders,
  setEmailItems,
  emailItems,
  setShowMainBody,
  setSelectedItem,
}) => {
  let cancelTokenSource = axios.CancelToken.source();
  let pageSize = 10;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageEnd, setPageEnd] = useState(false);
  const { instance, accounts } = useMsal();
  const [localEmailItems, setLocalEmailItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchQuery2, setSearchQuery2] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [searchQuery3, setSearchQuery3] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [showFilterDropDown, setShowFilterDropDown] = useState(false);

  const [filterSelectedFolder, setFilterSelectedFolder] = useState({
    id: 0,
    value: "All",
    label: "All",
  });
  const [folders, setFolders] = useState([]);

  const handleBackClick = () => {
    setShowMainBody(true);
  };

  function getEmailByFolder(pageNumber) {
    setLoading(true);
    setError(false);
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: selectedAccount?.account,
      })
      .then((response) => {
        const url =
          "https://graph.microsoft.com/v1.0/me/mailFolders/" +
          filterSelectedFolder?.id +
          "/messages";
        const headers = {
          Authorization: "Bearer " + response?.accessToken,
        };
        const params = {
          $top: pageSize,
          // $skip: (pageNumber - 1) * pageSize,
          $search: String(searchQuery2) || undefined, // Only add $search if there's a query
        };

        // if (searchQuery2) {
        //   params.$search = searchQuery2;
        // }
        // if (searchQuery) {
        //   params.$search = searchQuery3;
        // }

        axios
          .get(url, {
            headers,
            params,
            cancelToken: cancelTokenSource.token,
          })
          .then((response) => {
            if (response) {
              reShapeLocalFilterResults(response?.data?.value);
            }
            //Check if there is a next page
            const nextLink = response?.data["@odata.nextLink"];
            if (nextLink) {
              setPageEnd(false);
            } else {
              setPageEnd(true);
            }

            setLoading(false);
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              console.log("Request canceled:", error.message);
            } else {
              setLoading(false);
              setError(true);
            }
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  //************Email read reciption request ************//

  function RequestEmailItemUpdate(item) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: selectedAccount?.accounts,
      })
      .then((response) => {
        axios
          .patch(
            `https://graph.microsoft.com/v1.0/me/messages/${item.id}`,
            {
              isRead: true,
            },
            {
              headers: {
                Authorization: "Bearer " + response?.accessToken,
              },
            }
          )
          .then((response) => {})
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  function base64toBlob(base64String, contentType) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  function RequestEmailGetAddAttachment(item) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: selectedAccount?.account,
      })
      .then((response) => {
        setAuthorizationToken(response?.accessToken, "email");
        // Add a "loading" message to the page
        document.getElementById("attached-files").innerHTML =
          "Downloading Attachments...";
        axios
          .get(
            `https://graph.microsoft.com/v1.0/me/messages/${item.id}/attachments`
          )

          .then((response) => {
            const attachments = response?.data?.value;

            const attachmentLinks = attachments.map((attachment) => {
              if (attachment.contentType === "application/pdf") {
                const base64String = attachment.contentBytes;
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {
                  type: "application/pdf",
                });
                const url = URL.createObjectURL(blob);
                return `<a href="${url}" download="${attachment.name}">${attachment.name}</a>`;
              } else if (attachment.contentType.startsWith("image/")) {
                const base64String = attachment.contentBytes;
                const blob = base64toBlob(base64String, "image/png");
                const url = URL.createObjectURL(blob);
                return `<a href="${url}" download="${attachment.name}">${attachment.name}</a>`;
                // return `<a href="${url}" download="${attachment.name}"><img src="${url}" alt="${attachment.name}"/></a>`;
              } else if (
                attachment.contentType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ) {
                const base64String = attachment.contentBytes;
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {
                  type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });
                const url = URL.createObjectURL(blob);
                return `<a href="${url}" download="${attachment.name}">${attachment.name}</a>`;
              } else if (
                attachment.contentType ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ) {
                const base64String = attachment.contentBytes;
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = URL.createObjectURL(blob);
                return `<a href="${url}" download="${attachment.name}">${attachment.name}</a>`;
              } else if (
                attachment.contentType === "application/octet-stream"
              ) {
                const base64String = attachment.contentBytes;
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {
                  type: "application/octet-stream",
                });
                const url = URL.createObjectURL(blob);
                return `<a href="${url}" download="${attachment.name}">${attachment.name}</a>`;
              } else {
                return `<a href="${attachment["@microsoft.graph.downloadUrl"]}" download="${attachment.name}">${attachment.name}</a>`;
              }
            });
            const attachedFiles = attachmentLinks.join(", ");
            document.getElementById("attached-files").innerHTML = attachedFiles;
          })
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  const history = useHistory();

  function RequestProfileData() {
    try {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          callMsGraph(response.accessToken).then((response) => {
            history.push("/dashboard/email/inbox/profiledata");
          });
        });
    } catch (error) {
      console.error(error);
    }
  }

  // Call this function to cancel the ongoing request
  function cancelRequest() {
    cancelTokenSource.cancel("Request canceled");
    cancelTokenSource = axios.CancelToken.source(); // Create a new CancelToken source
  }

  function goToPage(pageNumber) {
    if (pageNumber >= 1) {
      setCurrentPage(pageNumber);
    }
  }

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  //************Email Search Function************//

  function getAllEmails() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: selectedAccount?.account,
      })
      .then((response) => {
        const headers = {
          Authorization: `Bearer ${response?.accessToken}`,
          "Content-Type": "application/json",
        };

        axios
          .get(
            `https://graph.microsoft.com/v1.0/me/messages?$search="${searchQuery2}"`,
            {
              headers,
            }
          )
          .then((response) => {
            const searchResults = response.data.value;
            console.log("Search results:", searchResults);

            reShapeLocalFilterResults(searchResults);

            const sortedResults = searchResults.sort((a, b) =>
              a.from.emailAddress.name.localeCompare(b.from.emailAddress.name)
            );

            console.log("Sorted results:", sortedResults);
          })
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  function RequestEmailSearchWithDate() {
    const searchQuery = document.getElementById("searchBar").value;
    const dateTo = document.getElementById("dateTo").value;

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: selectedAccount?.accounts,
      })
      .then((response) => {
        const headers = {
          Authorization: `Bearer ${response?.accessToken}`,
          "Content-Type": "application/json",
        };
        let filterQuery = `receivedDateTime ge ${dateTo}T00:00:00Z`;
        if (searchQuery3) {
          filterQuery += ` and from/emailAddress/address eq '${searchQuery3}'`;
        }
        axios
          .get(
            `https://graph.microsoft.com/v1.0/me/messages?$filter=${filterQuery}`,
            {
              headers,
            }
          )
          .then((response) => {
            const searchResults = response.data.value;
            console.log("Search results:", searchResults);
            reShapeLocalFilterResults(searchResults);
          })
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  const reShapeLocalFilterResults = (data) => {
    if (filterSelectedFolder.value === "All") {
      // Display results for all folders
      const folderWithResults = mailFolders
        .map((folder) => {
          const results = data.filter(
            (result) => result.parentFolderId === folder.id
          );
          return {
            ...folder,
            searchResults: results,
          };
        })
        .filter((folder) => folder.searchResults.length > 0); // Filter out folders with no results
      setLocalEmailItems(folderWithResults);
    } else {
      // Filter results for the selected folder only
      const folder = mailFolders.find(
        (folder) =>
          folder.displayName.toLowerCase() ===
          filterSelectedFolder.value.toLowerCase()
      );

      if (folder) {
        const results = data.filter(
          (result) => result.parentFolderId === folder.id
        );

        const folderWithResults = {
          ...folder,
          searchResults: results,
        };

        setLocalEmailItems([folderWithResults]);
      } else {
        setLocalEmailItems([]);
      }
    }
  };

  const handleItemClick = (item) => {
    if (item.hasAttachments) {
      RequestEmailGetAddAttachment(item);
    }
    setSelectedItem(item);
    setShowMainBody(false);

    item.isRead = true;

    localStorage.setItem("emailItems", JSON.stringify(emailItems));
    const listItem = document.getElementById(`item-${item.id}`);

    if (listItem) {
      listItem.classList.add("bg-primary");
      listItem.querySelector(".item-title").classList.add("text-white");
    }

    RequestEmailItemUpdate(item);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("ddd h:mm A");
  };

  useDidMountEffect(() => {
    RequestEmailSearchWithDate();
  }, [searchQuery3]);

  useDidMountEffect(() => {
    getAllEmails(1);
  }, [searchQuery2, filterSelectedFolder]);

  useEffect(() => {
    // getEmailByFolder(1);
    // setShowMainBody(true);
    setCurrentPage(1);
    setPageEnd(false);
    return () => {
      cancelRequest(); // Cancel the request when the component unmounts or when a new request is made
    };
  }, [selectedFolder, selectedAccount]);

  useDidMountEffect(() => {
    getEmailByFolder(currentPage);
    return () => {};
  }, [currentPage]);

  useEffect(() => {
    setFolders([
      { id: 0, value: "All", label: "All" }, // Add the "All" option
      ...mailFolders.map((folder) => ({
        id: folder.id,
        value: folder.displayName,
        label: folder.displayName,
      })),
    ]);
  }, [selectedAccount, mailFolders]);

  useDidMountEffect(() => {
    setFilterSelectedFolder({
      value: selectedFolder?.displayName,
      label: selectedFolder?.displayName,
      id: selectedFolder?.id,
    });
  }, [selectedFolder]);

  const handleKeyDown = async (e) => {
    // Check if the Enter key is pressed
    if (e.key === "Enter") {
      // setEmailItems(localEmailItems); if we are passing this. make sure to change the object shape fit in to the original incoming shape
    }
  };

  const customStyles = {
    container: () => ({
      height: "30%",
      overflowY: "auto",
      zIndex: "999",
    }),
  };

  useDidMountEffect(() => {
    if (searchQuery2 !== "") {
      setShowFilterDropDown(true);
    } else {
      setShowFilterDropDown(true);
    }
  }, [searchQuery2]);

  const dropdownRef = useRef(null);

  // Function to handle clicks outside of the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowFilterDropDown(false); // Close dropdown
      }
    };

    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="w-100 mb-4">
      <Card>
        <CardBody>
          <Row className="my-2">
            <Col sm={12}> Welcome, {selectedAccount?.account?.name}</Col>
          </Row>
          <Row className="g-0">
            <Col sm={2}>
              <Select
                isSearchable={false} // Disable typing
                type="select"
                id="emailBoxes"
                name="emailBoxes"
                value={filterSelectedFolder}
                onChange={(option) => {
                  setFilterSelectedFolder(option); // Pass the selected option
                }}
                options={folders}
              />
            </Col>
            <Col sm={7} className="d-flex ">
              <Button
                // onClick={toggleFilter}
                disabled
                className="btn bg-transparent text-dark"
              >
                <span className="mx-1 text-white">Filter</span>
              </Button>
              <Input
                type="text"
                id="searchBar"
                className="w-100 form-control no-border no-shadow  typeahead tt-input "
                placeholder="Search ..."
                autoComplete="off"
                spellCheck="false"
                onKeyDown={handleKeyDown} // Listen for key press
                dir="auto"
                style={{
                  position: "relative",
                  verticalAlign: "top",
                  backgroundColor: "transparent",
                }}
                value={searchQuery2}
                onChange={(e) => setSearchQuery2(e.target.value)}
                onFocus={() => setIsFocused(true)} // Detect when the input is focused (clicked)
                onBlur={() => setIsFocused(false)} // Detect when the input loses focus
              />
            </Col>

            <Col sm={1}></Col>
          </Row>
        </CardBody>
      </Card>
      <div
        ref={dropdownRef} // Attach ref to the dropdown container
        style={{
          display: showFilterDropDown ? "block" : "none",
          position: "relative",
          top: 0,
          left: 0,
          height: "30vh",
          zIndex: 99, // Ensure it sits on top of other content
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: showFilterDropDown ? "block" : "none",
            borderRadius: "2px",
            width: "100%",
            maxHeight: "100%",
            overflowY: "auto",
          }}
          onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside content
        >
          <Card>
            <CardBody>
              <Row>
                <Col className="text-info">
                  Results are only shown for the {filterSelectedFolder?.label}{" "}
                  mail folder{filterSelectedFolder?.label === "All" ? "s" : ""}.
                  If you wish to search in every mail folder, please select
                  'All' from the dropdown or switch to another mail folder.
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  {localEmailItems.length > 0 ? (
                    localEmailItems.map((folder) =>
                      folder?.searchResults.length > 0 ? (
                        <div key={folder.id} className="p-2 m-2">
                          <h5>{folder?.displayName}</h5>
                          <hr />
                          {folder?.searchResults.map((email) => (
                            <div
                              key={email.id}
                              className="mb-2"
                              onClick={() => handleItemClick(email)}
                            >
                              <Row>
                                <Col sm={12}>
                                  <FontAwesomeIcon
                                    className="mr-2"
                                    color={
                                      OutlookSwitchIcon(folder.displayName)
                                        .outlookColor
                                    }
                                    icon={
                                      OutlookSwitchIcon(folder.displayName)
                                        .outlookSymbol
                                    }
                                  />
                                  <Label>{email.subject}</Label>
                                </Col>
                              </Row>
                              <Row className="">
                                <Col
                                  md={8}
                                  className="d-flex align-items-between"
                                >
                                  <small>{`From: ${email?.from?.emailAddress?.name}`}</small>
                                </Col>
                                <Col
                                  sm={4}
                                  className="d-flex justify-content-end align-items-center"
                                >
                                  <small>
                                    {`${formatDate(email.createdDateTime)}`}
                                  </small>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  md={12}
                                  className="d-flex align-items-between"
                                >
                                  <small>{`<${email?.from?.emailAddress?.address}>`}</small>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </div>
                      ) : (
                        // Show NoSearchResults when there are no results for the folder
                        <div className="p-2 m-2">
                          <NoSearchResults page="email" />
                        </div>
                      )
                    )
                  ) : (
                    // Render NoSearchResults when there are no folders at all
                    <div className="p-2 m-2">
                      <NoSearchResults page="email" />
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label>Enter Email</Label>
                <Input
                  type="text"
                  id="searchBar"
                  className="form-control border no-shadow  typeahead tt-input "
                  placeholder="Searasrch by email..."
                  autoComplete="off"
                  spellCheck="false"
                  dir="auto"
                  style={{
                    position: "relative",
                    verticalAlign: "top",
                    backgroundColor: "transparent",
                  }}
                  value={searchQuery3}
                  onChange={(e) => setSearchQuery3(e.target.value)}
                />
              </Col>

              <Col>
                <Label for="exampleDate">Filter Date</Label>
                <Input
                  type="date"
                  name="dateTo"
                  id="dateTo"
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                />
              </Col>

              <Col>
                <div className="d-flex flex-column align-items-end mt-4">
                  <Button
                    onClick={() => RequestEmailSearchWithDate()}
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                  >
                    Search
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default OutlookFilterandHeader;
