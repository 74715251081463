import React, { useState, useEffect } from "react";
import { Button, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import ModalComponent from "../../components/Modal/modalComponent";
import TimeSheet from "../../components/Modal/ModalBody/timeSheet";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import { HOSTMexxar } from "../../configs/api-config";
import TimeSheetUpload from "../../components/Modal/ModalBody/timeSheetUpload";

const EmployeePayrollWidget = (props) => {
  const { userProfile, refreshPage } = props;
  const [calendarShow, setCalendarShow] = useState(false);
  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [data, setData] = useState();

  const toggleShowTimeModal = (data) => {
    setData(data);
    setShowTimeModal(!showTimeModal);
  };

  return (
    <div
      style={{
        minWidth: "900px",
      }}
    >
      <div className="card mb-2 ">
        <div className="card-body">
          <div className="flex flex-row d-flex justify-content-between ">
            <div className="d-flex  " style={{ width: "500px" }}>
              <div className="d-flex align-items-center mr-3 ">
                <span className="mx-2">
                  <b className="badge badge-circle sm text-primary"></b>
                </span>
              </div>
              <div>
                <div>{userProfile.candidateName}</div>
                <small>
                  Booking Date :{" "}
                  {moment(userProfile.bookingDate).format("llll")}
                </small>
              </div>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ width: "60px" }}
            ></div>
            <div>
              <Button
                color="success"
                className="mr-2"
                onClick={() => {
                  toggleShowTimeModal(userProfile);
                }}
              >
                Upload Timesheet
              </Button>
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        show={calendarShow}
        header="Log new call back"
        closeModal={() => handleCalendarShow(false)}
      >
        <SmallCalendar />
        <div className="mt-3">
          {" "}
          <Button color="success">Send</Button>{" "}
          <Button color="secondary" onClick={() => handleCalendarShow(false)}>
            Cancel
          </Button>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showTimeModal}
        header="Upload Timesheet"
        size="lg"
        closeModal={() => setShowTimeModal(false)}
      >
        <TimeSheetUpload
          data={data}
          closeModal={() => setShowTimeModal(false)}
          getCandidates={refreshPage}
        ></TimeSheetUpload>
      </ModalComponent>
    </div>
  );
};

export default EmployeePayrollWidget;
