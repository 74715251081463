import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Button, FormGroup, Label, Input, Alert } from "reactstrap";
import ModalComponent from "../../../components/Modal/modalComponent";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import PasswordReset from "../../../components/Modal/ModalBody/Admin/passwordReset";
import { forEach, isNull } from "lodash";
import axios from "axios";
import {
  CALL_LOGS,
  HOSTMexxar,
  REGISTER_CANDIDATE,
} from "../../../configs/api-config";
import ToastCommon from "../../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import { CandidateActiveStatusBadge } from "../../../util/common";
import AddNoteModal from "../../../components/AddANote/AddANote";
import { connect } from "react-redux";
import AssignConsultant from "../../../components/Modal/ModalBody/assignConsultant";
import IsPrivileged from "../../../util/auth/privilegeHelper.js";

const Settings = (props) => {
  const {
    candidateId,
    candidateEmail,
    candidatePaymentMethod,
    reload,
    emailOptIn,
    candidateData,
    employee,
  } = props;

  const [emailOptInTemp, setEmailOptInTemp] = useState(emailOptIn);
  const MexxarApiCandidate = REGISTER_CANDIDATE;

  const handleEmailOptInChange = (event) => {
    setEmailOptInTemp(event.target.value === "true");
  };

  const { addToast } = useToasts();
  const [editable, setEditable] = useState(false);
  const [showAddRow, setShowAddRow] = useState(false);
  const [status, setStatues] = useState(false);

  const [payementMethod, setPayementMethod] = useState([
    { id: "SELECT", name: "Select" },
    { id: "PAYEE", name: "Payee" },
    { id: "LIMITED", name: "Limited" },
    { id: "UMBRELLA", name: "Umbrella" },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAssignConsultantModal, setShowAssignConsultantModal] =
    useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    id: candidatePaymentMethod,
    name: !isNull(candidatePaymentMethod)
      ? candidatePaymentMethod?.charAt(0).toUpperCase() +
        candidatePaymentMethod?.slice(1).toLowerCase()
      : "",
  });


  const changePaymentMethod = () => {
    axios
      .put(
        HOSTMexxar +
          "candidates/" +
          candidateId +
          "/payment-method?status=" +
          selectedPaymentMethod
      )
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "success", addToast);
          reload();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  };
  const toggleNotifications = () => {
    axios
      .patch(
        REGISTER_CANDIDATE +
          "/" +
          candidateId +
          "/emailOptIn?value=" +
          emailOptInTemp
      )
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "update", addToast);
          reload();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const resetPassowrd = () => {
    axios
      .post(HOSTMexxar + "guest/password/forgot", { email: candidateEmail })
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "sent", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const candidateActiveStatus = (candidateStatus) => {
    //Candidate status updating ex:Doment,Do not not call,Inactive
    axios
      .put(
        REGISTER_CANDIDATE +
          "/" +
          candidateId +
          "/" +
          "status" +
          "/" +
          candidateStatus
      )
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "update", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const handleSaveNote = (note) => {
    //loging a call note outside the calling page
    let tempStatusEnum;
    let tempLable;

    if (status === "ACTIVE") {
      tempStatusEnum = 0;
      tempLable = "The candidate has been Activated.";
    } else if (status === "STAND_BY") {
      tempStatusEnum = 5;
      tempLable = "The candidate has been set to standby.";
    } else if (status === "INACTIVE") {
      tempStatusEnum = 3;
      tempLable = "The candidate has been Deactivated.";
    }

    candidateActiveStatus(status);

    axios
      .post(CALL_LOGS, {
        candidateId: Number(candidateId),
        reason: tempLable + " Reason: " + note?.note,
        startTime: new Date().toISOString(),
        endTime: new Date().toISOString(),
        status: 6,
        candidateStatus: tempStatusEnum,
        incomingPhoneNo: 0,
        outgoingPhoneNo: 0,
        important: true,
      })
      .then((res) => {
        //  setCandidateId(res.data.body);
        if (res.data.status == "success") {
          ToastCommon(true, "success", addToast);
          reload();
          toggleModal();
        } else {
          ToastCommon(true, "error", addToast);
        }
        setStatues(null);
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const updateWorkFlowStatus = (data) => {
    let consultantIds = [];
    forEach(data, function (item) {
      consultantIds.push(item.id);
    });

    axios
      .all([
        axios.put(
          MexxarApiCandidate +
            "/" +
            candidateId +
            "/add-employees?role=REGISTRAR",
          consultantIds
        ),
        axios.put(
          MexxarApiCandidate + "/" + candidateId + "/workflow/REGISTRATION"
        ),
      ])
      .then(
        axios.spread((res1, res2) => {
          if (res1.data.status == "success" && res2.data.status == "success") {
            ToastCommon(true, "success", addToast);

            setShowAssignConsultantModal(!showAssignConsultantModal);
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
      )
      .catch((err) => {
        //console.log(err)
        //ToastCommon(true, "error", addToast);
      });
  };
  const [newEmail, setnewEmail] = useState('');
  
  const ChangeEmail = (candidateId,newEmail) => {
    axios
      .patch(
        `${HOSTMexxar}candidates/${candidateId}/email?newEmail=${newEmail}`,
        {}
      )
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "success", addToast);
          reload();
          
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  };
  



  return (
    <div className="row ">
      <div className=" col-md-12">
        <div className="card  ">
          <div className="card-body">
          

          <FormGroup row>
  <IsPrivileged privilege={["ADMIN"]}>
    <Col sm={12} md={12}>
      <Label>Username</Label>
    </Col>
    <Col sm={12} md={6} className="mt-1">
      <Input
        type="text"
        defaultValue={candidateEmail}
        name="UserName"
        placeholder=""
        onChange={(e) => setnewEmail(e.target.value)}
        disabled={candidateData?.status === "ACTIVE"} 
      />
    </Col>
    <Col sm={12} md={6}>
      {candidateData?.status !== "ACTIVE" && (
        <Button color="success" onClick={() => ChangeEmail(candidateId, newEmail)}>
          Change
        </Button>
      )}
    </Col>
  </IsPrivileged>
</FormGroup>



          
            <hr />
            {candidateData?.workFlowStatus === "MAINTENANCE" && (
              <FormGroup row>
                <Col sm={12} md={12}>
                  <Label>Change Workspace</Label>
                </Col>
                <Col sm={12} md={6} className="mt-1">
                  <Label>Send this candidate to "Registration Workspace"</Label>
                </Col>
                <Col sm={12} md={6}>
                  <Button
                    color="success"
                    onClick={() =>
                      setShowAssignConsultantModal(!showAssignConsultantModal)
                    }
                  >
                    Change
                  </Button>
                </Col>
              </FormGroup>
            )}
            <FormGroup row>
              <Col sm={12} md={12}>
                <Label>Reset password</Label>
              </Col>
              <Col sm={12} md={6} className="mt-1">
                <Label>Please click the button to reset password</Label>
                <FontAwesomeIcon
                  className={"mx-3"}
                  icon={faExclamationTriangle}
                />
              </Col>
              <Col sm={12} md={6}>
                <Button color="success" onClick={() => setShowAddRow(true)}>
                  Reset
                </Button>
              </Col>
            </FormGroup>
            <hr />
            <FormGroup row>
              <Col sm={12} md={12}>
                <Label>Payment Method </Label>
              </Col>
              <Col sm={12} md={6}>
                <Input
                  // disabled={auth.item.userType == "CANDIDATE" ? true : false}
                  type="select"
                  id="payementMethod"
                  name="payementMethod"
                  defaultValue={candidatePaymentMethod}
                  value={selectedPaymentMethod.id}
                  // invalid={errors.candidateRoleId}
                  onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                >
                  {/* <option value={0}>Select</option> */}
                  {payementMethod &&
                    payementMethod.map((items, id) => {
                      return (
                        <option
                          value={items.id}
                          key={id}
                          style={{ color: "#6A6767" }}
                        >
                          {items.name}
                        </option>
                      );
                    })}
                </Input>
              </Col>
              <Col sm={12} md={6}>
                <Button
                  color="success"
                  onClick={() => {
                    changePaymentMethod();
                  }}
                >
                  Change
                </Button>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={12} md={12}>
                <h4 className="bold">Notifications </h4>
                <hr />
                <Alert>
                  Please turn on notifications if you would like to receive
                  updates via email, SMS, or WhatsApp regarding your bookings or
                  document status.{" "}
                </Alert>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="emailOptIn"
                      value="true"
                      checked={emailOptInTemp}
                      onChange={handleEmailOptInChange}
                    />
                    Yes
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="emailOptIn"
                      value="false"
                      checked={!emailOptInTemp}
                      onChange={handleEmailOptInChange}
                    />
                    No
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <Button
                  color="success"
                  onClick={() => {
                    toggleNotifications();
                  }}
                >
                  Save
                </Button>
              </Col>
            </FormGroup>
            <FormGroup row>
              {candidateData.status !== "INACTIVE" && (
                <>
                  <Col sm={12} md={12}>
                    <Alert color="danger">
                      Caution: Deactivating a candidate will make them invisible
                      from the workspace. Deactivated candidates can be found by
                      navigating to the main candidates list page and using
                      advanced filters.
                    </Alert>
                  </Col>
                  <Col sm={12} md={6}>
                    <Label sm={12} md={6}>
                      Would you like to deactivate this candidate?
                    </Label>
                  </Col>
                  <Col sm={12} md={6}>
                    <Button
                      color="success"
                      onClick={() => {
                        setStatues("INACTIVE");
                        toggleModal();
                      }}
                    >
                      {"Deactivate"}
                    </Button>
                  </Col>
                </>
              )}

              {/* This code line is only accicable by the admins only */}
              <IsPrivileged privilege={["ADMIN"]}>
                {candidateData.status === "INACTIVE" && (
                  <>
                    <Col sm={12} md={6}>
                      <Label sm={12} md={6}>
                        Would you like to activate this candidate?
                      </Label>
                    </Col>
                    <Col sm={12} md={6}>
                      <Button
                        color="success"
                        onClick={() => {
                          setStatues("ACTIVE");
                          toggleModal();
                        }}
                      >
                        {"Activate"}
                      </Button>
                    </Col>
                  </>
                )}
              </IsPrivileged>
              {/* End */}

              {candidateData.status === "STAND_BY" && (
                <>
                  <Col sm={12} md={6}>
                    <Label sm={12} md={6}>
                      Would you like to activate this candidate?
                    </Label>
                  </Col>
                  <Col sm={12} md={6}>
                    <Button
                      color="success"
                      className="my-2"
                      onClick={() => {
                        setStatues("ACTIVE");
                        toggleModal();
                      }}
                    >
                      {"Activate"}
                    </Button>
                  </Col>
                </>
              )}

              {candidateData.status !== "STAND_BY" &&
                candidateData.status !== "INACTIVE" && (
                  <>
                    <Col sm={12} md={6}>
                      <Label sm={12} md={6}>
                        Would you like to set this candidate to standby?
                      </Label>
                    </Col>
                    <Col sm={12} md={6}>
                      <Button
                        className="my-2"
                        color="success"
                        onClick={() => {
                          setStatues("STAND_BY");
                          toggleModal();
                        }}
                      >
                        Standby
                      </Button>
                    </Col>
                  </>
                )}
            </FormGroup>
          </div>

          <ModalComponent
            show={showAssignConsultantModal}
            header="Assign a consultant"
            closeModal={() => setShowAssignConsultantModal(false)}
          >
            <AssignConsultant
              onCreate={(data) => updateWorkFlowStatus(data)}
              workflowToBe="REGISTRATION"
              closeModal={() => setShowAssignConsultantModal(false)}
            />
          </ModalComponent>

          <ModalComponent
            show={showAddRow}
            header="Reset Password"
            closeModal={() => setShowAddRow(false)}
          >
            <PasswordReset
              mode="Add"
              type={"candidate"}
              closeModal={() => setShowAddRow(false)}
              id={candidateId}
              candidateEmail={candidateEmail}
              candidateData={candidateData}
            />
          </ModalComponent>
        </div>
        <AddNoteModal
          isOpen={isModalOpen}
          toggle={toggleModal}
          onSave={handleSaveNote}
          important={true}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    employee: state?.auth.item,
  };
}

export default connect(mapStateToProps)(Settings);
