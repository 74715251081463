import {
  SAVE_MOBILE_TIMESHEETS_LOCALLY,
  REMOVE_TIMESHEETS_LOCALLY_ALL,
  REMOVE_TIMESHEETS_LOCALLY_ONE,
  GET_LOCAL_TIMESHEETS,
  MOBILE_TIME_SHEET_EXIST_OR_NOT,
  ADD_MOBILE_AI_TIMESHEET_DATA,
  ADD_MOBILE_SYSTEM_TIMESHEET_DATA,
  UPDATE_MOBILE_TIMESHEET_REVIEWED_DATA,
  UPDATE_TIMESHEET_SCAN_PROCESSING_STATUS,
  LOG_FAILED_TIMESHEETS,
  SCAN_MOBILE_STATUS_INDIVIDUAL,
  UPDATE_MOBILE_TIMESHEET_AI_REVIEWED_STATUS,
  FINAL_MANUALLY_REVIEW_CONFIRMATION,
  UPDATE_TIMESHEET_S3BUCKETFILEURL,
  SAVE_GENERATED_URL,
  PRE_MOBILE_VERIFIED_STATUS,
} from "../actions/types";

const initialState = {
  timesheets: [],
  scanProcessingStatus: "NOT_STARTED", //"NOT_STARTED, "SCANNING","DONE","INTERRUPTED"
  failedTimesheets: [],
  finalManualReviewConfirmation: "NOT_CONFIRMED",
  generatedUrl: "", //  "NOT_CONFIRMED" ,"CONFIRMED"
  exportObject: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_MOBILE_TIMESHEETS_LOCALLY:
      // return {
      //   ...state,
      //   timesheets: [...state.timesheets, ...action.payload],
      // };
      // return {
      //   timesheets: [...action.payload],
      // };
      return {
        ...state,
        timesheets: [...action.payload],
      };

    case REMOVE_TIMESHEETS_LOCALLY_ALL:
      return {
        ...initialState,
      };

    case REMOVE_TIMESHEETS_LOCALLY_ONE:
      console.log(
        "REMOVE_TIMESHEETS_LOCALLY_ONE",
        action.payload,
        state.timesheets.filter(
          (timesheet) => timesheet?.file?.id !== action?.payload
        )
      );
      return {
        ...state,
        timesheets: state.timesheets.filter(
          (timesheet) => timesheet?.file?.id !== action?.payload
        ),
      };

    case MOBILE_TIME_SHEET_EXIST_OR_NOT:
      const { timeSheetExist } = action.payload;

      return {
        ...state,
        timesheets: state.timesheets.map((timesheet) =>
          timesheet.file.bookingRef === action.payload.id
            ? { ...timesheet, timeSheetExist }
            : timesheet
        ),
      };
    case UPDATE_MOBILE_TIMESHEET_REVIEWED_DATA:
      console.log("action.payload:", action);

      const { id, reviewedData, timesheetReviewDateTime } =
        action.payload || {};

      // If reviewedData or id is undefined/null, return state unchanged
      if (!reviewedData || !id) {
        return state;
      }

      console.log(
        "mobile reducer inside reducer funtion---->",
        reviewedData,
        timesheetReviewDateTime,
        id
      );

      // Ensure state.exportObject is defined before filtering
      const existingTimesheets = state?.exportObject
        ? state.exportObject.filter((data) => data.Reference === id)
        : [];
      console.log(
        "mobile reducer inside reducer funtion---->existingTimesheets",
        existingTimesheets
      );
      // Ensure reviewedData is an array and map through it
      const exportingTimesheets = Array.isArray(reviewedData)
        ? reviewedData.map((newData) => {
            const matchingTimesheet = existingTimesheets.find(
              (timesheet) =>
                timesheet?.PayDescription === newData?.PayDescription
            );

            // Spread existing or new data based on match
            return matchingTimesheet
              ? {
                  ...matchingTimesheet,
                  ...newData, // Update with new fields
                }
              : {
                  ...newData, // Add new entry
                };
          })
        : [];
      console.log(
        "mobile reducer inside reducer funtion---->exportingTimesheets",
        exportingTimesheets
      );
      console.log(
        "mobile reducer inside reducer funtion---->spread",
        ...(state?.exportObject
          ? state.exportObject.filter((data) => data.Reference !== id)
          : [])
      );
      return {
        ...state,
        // Update timesheets only if state.timesheets is defined
        timesheets: state?.timesheets
          ? state.timesheets.map((timesheet) =>
              timesheet?.DBData?.referenceNo === id
                ? {
                    ...timesheet,
                    reviewedData: reviewedData[0] || {}, // Ensure reviewedData[0] is handled if undefined
                    manuallyReviewed: true,
                    timesheetReviewDateTime: timesheetReviewDateTime || null, // Handle undefined dateTime
                  }
                : timesheet
            )
          : state?.timesheets || [], // Ensure we return an empty array if timesheets is undefined
        // Update exportObject only if it's defined, otherwise default to empty array
        exportObject: [
          ...(state?.exportObject
            ? state.exportObject.filter((data) => data.Reference !== id)
            : []), // Remove old entries with the same reference
          ...exportingTimesheets, // Add updated/new entries
        ],
      };
    // case UPDATE_MOBILE_TIMESHEET_REVIEWED_DATA:
    //   console.log("hello angi this is mobile reducer for candy--->",action)
    //   const { reviewedData, timesheetReviewDateTime } = action.payload;

    //   return {
    //     ...state,
    //     timesheets: state.timesheets.map((timesheet) =>
    //       timesheet.file.bookingRef === action.payload.id
    //         ? {
    //             ...timesheet,
    //             reviewedData,
    //             manuallyReviewed: true,
    //             timesheetReviewDateTime: timesheetReviewDateTime,
    //           }
    //         : timesheet
    //     ),
    //   };

    case UPDATE_MOBILE_TIMESHEET_AI_REVIEWED_STATUS:
      return {
        ...state,
        timesheets: state.timesheets.map((timesheet) =>
          timesheet.file.bookingRef === action.payload.id
            ? { ...timesheet, aIReviewStatus: action.payload.status }
            : timesheet
        ),
      };
    case FINAL_MANUALLY_REVIEW_CONFIRMATION:
      return {
        ...state,
        finalManualReviewConfirmation: action.payload,
      };

    case ADD_MOBILE_AI_TIMESHEET_DATA:
      const { AIModalData } = action.payload;
      console.log("angi reducer mobile timesheets---090", action);
      return {
        ...state,
        timesheets: state.timesheets.map((timesheet) =>
          timesheet.file.bookingRef === action.payload.id
            ? { ...timesheet, AIModalData }
            : timesheet
        ),
      };
    case ADD_MOBILE_SYSTEM_TIMESHEET_DATA:
      const { DBData } = action.payload;
      return {
        ...state,
        timesheets: state.timesheets.map((timesheet) =>
          timesheet.file.bookingRef === action.payload.id
            ? { ...timesheet, DBData }
            : timesheet
        ),
      };
    case UPDATE_TIMESHEET_SCAN_PROCESSING_STATUS:
      return {
        ...state,
        scanProcessingStatus: action.payload,
      };

    // case UPDATE_TIMESHEET_S3BUCKETFILEURL:
    // return {
    //   ...state,
    //   s3bucketfileurl: action.payload,
    // };

    // case LOG_FAILED_TIMESHEETS:
    //   return {
    //     ...state,
    //     failedTimesheets: [
    //       ...state?.failedTimesheets,
    //       {
    //         file: action.payload.file,
    //         errorMessage: action.payload.errorMessage,
    //       },
    //     ],
    //   };
    case LOG_FAILED_TIMESHEETS:
      return {
        ...state,
        failedTimesheets: state.failedTimesheets.some(
          (item) => item.file.bookingRef === action.payload.file.bookingRef
        )
          ? state.failedTimesheets
          : [
              ...state?.failedTimesheets,
              {
                file: action.payload.file,
                errorMessage: action.payload.errorMessage,
              },
            ],
      };

    // reducer.js

    case SAVE_GENERATED_URL:
      return {
        ...state,
        generatedUrl: action.payload,
      };

    case SCAN_MOBILE_STATUS_INDIVIDUAL:
      return {
        ...state,
        timesheets: state.timesheets.map((timesheet) =>
          timesheet.file.bookingRef === action.payload.id
            ? {
                ...timesheet,
                scanStatus: {
                  status: action.payload.status,
                  message: action.payload.message,
                },
              }
            : timesheet
        ),
      };
    case PRE_MOBILE_VERIFIED_STATUS:
      return {
        ...state,
        timesheets: state.timesheets.map((timesheet) =>
          timesheet.file.bookingRef === action.payload.id
            ? {
                ...timesheet,
                preVerifiedAndProccessedStatus: {
                  status: action.payload.status,
                  message: action.payload.message,
                },
              }
            : timesheet
        ),
      };

    case GET_LOCAL_TIMESHEETS:
      return {
        ...state,
        timesheets: action.payload,
      };

    default:
      return state;
  }
}
