import { faCheckCircle, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alert, Button } from "reactstrap";
import { CompanyName } from "../../configs/api-config";
import mexxarLogo from "../../images/mexxarLogo.png";
import agencyLogo from "../../images/logo.png";
 

export default function RefreeFormSucess() {
  const { referee, candidate } = useParams();
  const decodedReferee = decodeURIComponent(referee);
  const decodedCandidate = decodeURIComponent(candidate);
  return (
    <div className="d-flex flex-column align-items-center w-100 h-100 justify-content-center p-5 m-5">
      <div className="card p-5 m-5">
        <p className="text-justify ">
          <Alert
            color={"success"}
            className="d-flex align-items-center  justify-content-between"
          >
            Form submitted successfully. Thank you!
            <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
          </Alert>
          <h5>Dear {decodedReferee},</h5>
          <p>
            Thank you for taking the time to complete the reference form for{" "}
            <u>{decodedCandidate}</u>. Your feedback will be instrumental in the
            &nbsp;
            {CompanyName}'s recruitment process.
          </p>
          <p>
            We have received your submission and will inform the&nbsp;
            {CompanyName} that you have completed this form. They will review
            your feedback and may contact you for further information or
            clarification if needed.
          </p>
          <p>
            Once again, we appreciate your help and contribution to this
            process. If you have any questions or concerns, please do not
            hesitate to contact us.
          </p>
        </p>

        <div className="d-flex justify-content-center flex-column align-items-center mt-4">
          <p>
            Copyright {new Date().getFullYear()} @ Mexxar
          </p>
          <p>Powered by Mexxar</p>
          <div className="center-div  ">
            <img
              className="mr-5"
              alt="LOGO"
              width="60"
              height="auto"
              src={agencyLogo}
            />{" "}
            <img
              className="mt-2"
              width="70"
              height="auto"
              alt="LOGO"
              src={mexxarLogo}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
