import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Col,
  Input,
  Label,
  Badge,
  UncontrolledTooltip,
  Button,
  Spinner,
} from "reactstrap";

import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import moment from "moment";
import { uniqBy, forEach,findIndex, split, isNull } from "lodash";
import ModalComponent from "../modalComponent";
import { ProfileStatus } from "../../../util/common";
import useDidMountEffect from "./intialRunUseEffect";
import { Add_New_DOCUMENT, HOSTMexxar, REGISTER_CANDIDATE } from "../../../configs/api-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RejectReasonInsideReview from "./rejectReasonInsideReview";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";

function AuditReject(props) {
  const { filesAndDocs, selecetData, candidateId, incomingPage,docId,candidateServiceType,workFlowStatus,checkPermissions } = props;

  const MexxarApiCandidate = REGISTER_CANDIDATE;
  const MexxarApiFileUpload = Add_New_DOCUMENT;
  const MexxarApiHost = HOSTMexxar;
  const [files, setFiles] = useState(null);
  const [framework, setFramework] = useState([]);
  const [speciality, setSpeciality] = useState([]);
  const [changedFramework, setChangedFramework] = useState();
  const [changedSpecility, setChangedSpecility] = useState();
  const [confirmReasonShow, setConfirmReasonShow] = useState(false);
  const [rejectReasonModalInReview, setRejectReasonModalInReview] = useState({
    bool: false,
    value: "",
    type: "",
  });
  const { addToast } = useToasts();
  const getSpecialiftiesAndFrameworkList = () => {
    axios
      .get(MexxarApiCandidate + "/" + Number(candidateId))
      .then((res) => {
       
        let frameworksTemp = [];
        if (res.data.body[0].preferredClients != undefined) {
          forEach(res.data.body[0].preferredClients, function (item) {
            frameworksTemp.push(item.framework);
          });
        }

        let frameworks = uniqBy(frameworksTemp, "id");

        let specialityTemp =
          res.data.body[0].candidateSpecialities.length === 0
            ? null
            : res.data.body[0].candidateSpecialities;

        setFramework(frameworks);
        setSpeciality(specialityTemp);
        setChangedFramework(frameworks[0].id);
        setChangedSpecility(specialityTemp[0].id);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  //Candidate's document list according to the framework ,role,speciality
  const getAllDocuments = () => {
    let role;
    if (incomingPage == "calling") {
      role = selecetData;
    } else {
      role = selecetData.match.params.candidateRole;
    }

    axios
      .get(
        MexxarApiCandidate +
          "/" +
          candidateId +
          "/documents/framework/" +
          Number(changedFramework) +
          "/role/" +
          role +
          "/speciality/" +
          Number(changedSpecility)
      )
      .then((res) => {
        setFiles(res.data.body);
console.log("Files",res.data.body)
        // let documentNameList = [];
        // forEach(res.data.body, function (item) {
        //   documentNameList.push({
        //     id: item.documentId,
        //     name: item.documentName,
        //   });
        // });

        // setDocument(documentNameList);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useDidMountEffect(() => {
    getAllDocuments();
  }, [changedSpecility]);

  useDidMountEffect(() => {
    getAllDocuments();
  }, [changedFramework]);

  useEffect(() => {
    getAllDocuments();
    getSpecialiftiesAndFrameworkList();
  }, []);

  useDidMountEffect(() => {
    let index = findIndex(filesAndDocs, (item) => {
      return item.documentId == Number(docId);  // Use the docId prop directly
    });
    setCurrentDoc(index);
  }, [filesAndDocs, docId]);

  
  const [currentDoc, setCurrentDoc] = useState();
  const [loadingDocumentId, setLoadingDocumentId] = useState(null);
  const handleVerify = (documentId) => {
    return new Promise((resolve, reject) => {
      setLoadingDocumentId(documentId);
      const verifiedTemp = new FormData();
    
       console.log("Function check Clicked Document ID: ", documentId);
     

      verifiedTemp.append("status", "APPROVED");
      verifiedTemp.append("candidateId", candidateId); 
      verifiedTemp.append("reason", "");
  
      axios
        .all([
          axios.put(
            `${MexxarApiFileUpload}/${documentId}/upload/audit-status`,
            verifiedTemp
          ),
          axios.put(
            `${MexxarApiHost}candidates/${candidateId}/audit/CONFIRM`
          ),
        ])
        .then(
          axios.spread((res, res2) => {
            if (res.data.status === "success" && res2.data.status === "success") {
              ToastCommon(true, "successfullyVerified", addToast);
              getAllDocuments();
              resolve();
            } else {
              ToastCommon(true, "error", addToast);
            
            }
          })
        )
        .catch((err) => {
          ToastCommon(true, "error", addToast);
          reject();
        });
    });
  };
  
  const handleConfirmDocument = (documentId) => {
    return new Promise((resolve, reject) => {
      const confirmTemp = new FormData();

      confirmTemp.append("status", "APPROVED");
      confirmTemp.append("candidateId", props.match.params.candidateId);
      confirmTemp.append("reason", "");

      axios
        .put(
           `${MexxarApiFileUpload}/${documentId}/upload/confirm-status`,
          
          confirmTemp
        )
        .then((res) => {
          if (res.data.status == "success") {
            ToastCommon(true, "successfullyConfirmed", addToast);
            getAllDocuments();
            resolve(); // resolve the Promise if the request was successful
          } else {
            ToastCommon(true, "error", addToast);
            reject(); // reject the Promise if the request was not successful
          }
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
          reject(); // reject the Promise if there was an error
        });
    });
  };
  
  return (
    <div>
      <div>
        <FormGroup row>
          <Col sm={6}>
            <Label for="candidateSpecialityId">Speciality</Label>

            <Input
              type="select"
              id="candidateSpecialityId"
              name="candidateSpecialityId"
              value={changedSpecility}
              onChange={(e) => setChangedSpecility(e.target.value)}
              // innerRef={register({
              //   required: {
              //     value: true,
              //     message: "This field is required!",
              //   },
              // })}
            >
              {speciality.map((items, id) => {
                return (
                  <option value={items.id} key={id}>
                    {items.name}
                  </option>
                );
              })}
            </Input>
          </Col>
          <Col sm={6}>
            <Label for="frameworkId">Framework</Label>

            <Input
              type="select"
              id="frameworkId"
              name="frameworkId"
              value={changedFramework}
              onChange={(e) => setChangedFramework(e.target.value)}
              // innerRef={register({
              //   required: {
              //     value: true,
              //     message: "This field is required!",
              //   },
              // })}
            >
              {framework.map((items, id) => {
                return (
                  <option value={items.id} key={id}>
                    {items.name}
                  </option>
                );
              })}
            </Input>
          </Col>
        </FormGroup>
      </div>

      <div className="form-row">
        <Label className="col-3 col-md-3 ">Name</Label>
        <Label className="col-2 col-md-2  ">Doc</Label>
        <Label className="col-2 col-md-2  ">Confirmed</Label>
        <Label className="col-2 col-md-2  ">Audited</Label>
        <Label className="col-1 col-md-1  " id="CRR">
          CRR
        </Label>
        <Label className="col-1 col-md-1  " id="ARR">
          ARR
        </Label>

        <UncontrolledTooltip placement="right" target="CRR">
          Confirmation reject reason
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="right" target="ARR">
          Audit reject reason
        </UncontrolledTooltip>

       
      </div>
      {files == null || files == undefined ? (
        <>No data</>
      ) : (
        files.map((file, id) => (
          <div className="form-row " key={id}>
            <label className="col-3  col-md-3 ">{file.documentName}</label>
            <div className="col-2 col-md-2  flex">
              <div>
                {file.documentLink != null ? (
                  <Badge color={"success"}>uploaded</Badge>
                ) : (
                  <Badge color={"warning"}>pending</Badge>
                )}
              </div>
              <div>
                {file.expireDate != null ? (
                  file.expireDate === "2500-01-01" ? (
                    <small>Exp: N/A</small> 
                  ) : (
                    <small>
                      Exp: {moment(file.expireDate).format("YYYY-MM-DD")}
                    </small>
                  )
                ) : (
                  <small></small>
                )}
              </div>
            </div>

            {/* Audit row */}
            <div className="col-2  col-md-2 ">
              <Badge color={ProfileStatus(file.confirmation).badgeColor}>
                {ProfileStatus(file.confirmation).badgeLable}
              </Badge>
            </div>
            <div className="col-2  col-md-2 ">


    
            {
  file.audition != null && file.audition === "PENDING" && (
    <Badge
    className={`${candidateServiceType === "TEMPORARY" && workFlowStatus === "AUDIT" ? 'pointer ' : ''}`}
      color="warning"
      onClick={() => {
      
        if (candidateServiceType === "TEMPORARY" && workFlowStatus === "AUDIT") {
          console.log("permission", checkPermissions("AUDIT_TEMP").length );
          if (checkPermissions("AUDIT_TEMP").length > 0) {
            console.log("Button Clicked Document ID:", file.documentId);
            handleVerify(file.documentId);
          } else {
            console.log("Insufficient permissions to verify document ID:", file.documentId);
          }
        } else {
          console.log("Conditions not met for this action.");
        }
      }}
    >
      {loadingDocumentId === file.documentId ? (
        <Spinner
          className="mx-2"
          style={{
            height: "20px",
            width: "20px",
            marginRight: "5px",
          }}
          size="sm"
        />
      ) : (
        "Pending"
      )}
    </Badge>
  )
}




  
              {file.audition != null && file.audition == "APPROVED" ? (
                <Badge color="success">Verified</Badge>
              ) : null}
              {file.audition != null && file.audition == "DECLINED" ? (
                <Badge color="danger">Decline</Badge>
              ) : null}
            </div>

            <div className="col-1  col-md-1 ">
              {file.confirmReason != null && file.confirmation === "DECLINED" ? (
                <>
                  <Badge
                    style={{ cursor: "pointer" }}
                    id="CRReason"
                    color="danger"
                    onClick={() =>
                      setRejectReasonModalInReview({
                        body: file.confirmReason,
                        bool: true,
                        type: "Confirm",
                      })
                    }
                  >
                    Reason
                  </Badge>
                </>
              ) : (
                <small></small>
              )}
            </div>

            <div className="col-1  col-md-1 ">
              {file.auditReason != null && file.audition == "DECLINE" ? (
                <>
                  <Badge
                    id="ARReason"
                    color="danger"
                    onClick={() =>
                      setRejectReasonModalInReview({
                        body: file.auditReason,
                        bool: true,
                        type: "Audit",
                      })
                    }
                  >
                    Reason
                  </Badge>
                </>
              ) : (
                <small></small>
              )}
            </div>
          </div>
        ))
      )}

      <ModalComponent
        show={rejectReasonModalInReview.bool}
        header={"Reject reason"}
        closeModal={() => setRejectReasonModalInReview({ bool: false })}
      >
        <RejectReasonInsideReview
          type={rejectReasonModalInReview.type}
          body={rejectReasonModalInReview.body}
          closeModal={() => setRejectReasonModalInReview({ bool: false })}
        ></RejectReasonInsideReview>
      </ModalComponent>
    </div>
  );
}

export default AuditReject;