import React from "react";
import { Badge } from "reactstrap"; // Assuming you're using Reactstrap for badges

const TabNavigationMenu = (props) => {
  const { activated, onChangeItem, itemList } = props;

  return (
    <div className="d-flex my-2">
      <div className="nav-active-border b-success px-3">
        <ul className="nav text-sm" role="tablist">
          {itemList.map((item) => {
            const { key, name, isBeta = false } = item; // Default isBeta to false

            return (
              <li
                className={`nav-item`}
                onClick={() => onChangeItem(key)}
                key={key}
              >
                <a
                  className={`nav-link ${key === activated ? "active" : ""}`}
                  href={`#${activated}`}
                  data-toggle="tab"
                >
                  {name}
                  {isBeta && (
                    <Badge color="warning" className="ml-1">
                      BETA
                    </Badge>
                  )}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default TabNavigationMenu;
