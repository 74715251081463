import React, { useState, useEffect } from "react";
import axios from "axios";
import { forEach } from "lodash";
import Select from "react-select";
import { useForm } from "react-hook-form";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";
import { EMPLOYEE_PERMISSIONS } from "../../../configs/api-config";
import { Button, Label, Form, FormGroup, Col, Row } from "reactstrap";

export default function AssignConsultant(props) {
  const { register, handleSubmit, errors } = useForm();
  const { onCreate, closeModal, workflowToBe } = props;

  const MexxarApiEmployee = EMPLOYEE_PERMISSIONS;

  const [registerData, setRegisterData] = useState(null);

  const [bookingConsultantName, setBookingConsultantName] = useState([]);

  const [consultantName, setConsultantName] = useState([]);

  const { addToast } = useToasts();

  const onSubmit = () => {
    onCreate(registerData);
  };

  const getConsultantsList = () => {
    axios
      .all([
        axios.get(MexxarApiEmployee + "/permissions/BOOKING"),
        axios.get(MexxarApiEmployee + "/permissions/MAINTENANCE"),
        axios.get(MexxarApiEmployee + "/permissions/REGISTRATION"),
        axios.get(MexxarApiEmployee + "/permissions/HEADHUNTER"),
      ])
      .then(
        axios.spread((res1, res2, res3, res4) => {
          let bookingConsultants = [];
          let maintenanceConsultants = [];
          let registrationConsultants = [];
          let headhuntingConsultants = [];

          forEach(res1.data.body, function (item) {
            bookingConsultants.push({
              value: item.firstName + " " + item.lastName,
              label: item.firstName + " " + item.lastName,
              id: item.id,
            });
          });
          forEach(res2.data.body, function (item) {
            maintenanceConsultants.push({
              value: item.firstName + " " + item.lastName,
              label: item.firstName + " " + item.lastName,
              id: item.id,
            });
          });
          forEach(res3.data.body, function (item) {
            registrationConsultants.push({
              value: item.firstName + " " + item.lastName,
              label: item.firstName + " " + item.lastName,
              id: item.id,
            });
          });
          forEach(res4.data.body, function (item) {
            headhuntingConsultants.push({
              value: item.firstName + " " + item.lastName,
              label: item.firstName + " " + item.lastName,
              id: item.id,
            });
          });
          if (workflowToBe == "HEADHUNTER") {
            setConsultantName(headhuntingConsultants);
          } else if (workflowToBe == "REGISTRATION") {
            setConsultantName(registrationConsultants);
          } else if (workflowToBe == "MAINTENANCE") {
            // Ensure both variables are arrays and remove duplicates
            let maintenanceAndBookingConsultants = Array.from(
              new Set(
                (Array.isArray(maintenanceConsultants)
                  ? maintenanceConsultants
                  : []
                ).concat(
                  Array.isArray(bookingConsultants) ? bookingConsultants : []
                )
              )
            );

            setConsultantName(maintenanceAndBookingConsultants);
            setBookingConsultantName(bookingConsultants);
          }
          // else if (workflowToBe == "BOOKING") {
          //   setConsultantName(bookingConsultants);
          // }
          // setConsultantName(bookingConsultants);
          // setMaintenacerName(maintenanceConsultants);
          // setRegisteresName(registrationConsultants);
          // setHeadunterName(headhuntingConsultants);
        })
      )
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    getConsultantsList();
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        {workflowToBe == "HEADHUNTER" ? (
          <Label sm={4}>Assign Headhunting consultants</Label>
        ) : null}

        {workflowToBe == "REGISTRATION" ? (
          <Label sm={4}>Assign Registration consultants</Label>
        ) : null}
        {workflowToBe == "MAINTENANCE" ? (
          <Label sm={4}>Assign Maintenance/Bookings consultants</Label>
        ) : null}

        <Col sm={8}>
          <Select
            isMulti
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                color: "#333333",
              }),
            }}
            name="clients"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(data) => setRegisterData(data)}
            options={consultantName}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          />
        </Col>
      </FormGroup>
      {/* {workflowToBe == "MAINTENANCE" ? (
        <FormGroup row>
          <Label sm={4}>Assign booking consultants</Label>
          <Col sm={8}>
            <Select
              isMulti
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                  color: "#333333",
                }),
              }}
              name="clients"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(data) => setRegisterData(data)}
              options={bookingConsultantName}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            />
          </Col>
        </FormGroup>
      ) : null} */}

      <Row className="justify-content-end">
        <div className="d-flex ">
          {" "}
          <Button className="mr-2" color="success" type="submit">
            Save
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}
