import React, { useState } from "react";
import {
  Button,
  Col,
  Label,
  Input,
  UncontrolledTooltip,
  Row,
  CardBody,
  Card,
  Collapse,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import ModalComponent from "../../components/Modal/modalComponent";
import TimeSheet from "../../components/Modal/ModalBody/timeSheet";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import { ClientLable, HOSTMexxar } from "../../configs/api-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import loadingAnimation from "../../../src/images/puff.svg";
import NoSearchResults from "../../pages/errorPages/NoSearchResults";
import NoConnection from "../../pages/errorPages/NoConnection";
import SortFunctionAscending from "../../components/sortFunction/sortByDateAscending";
import { useSpring, animated } from "react-spring";
import ShowReport from "../../components/Modal/ModalBody/showReport";
import usePayroll from "../../customHooks/usePayroll";
import { FunnelIcon } from "../../assets/icons/svg";
import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";

const filterByName = (name, dataSet) => {
  let filtered = dataSet;
  if (name !== "") {
    filtered = dataSet.filter((item) => {
      let searchableString = `${item.booking.candidateName}   ${item.booking.candidateName}  ${item.booking.clientId} ${item.booking.clientName}  `;

      let p = searchableString;
      return p.toLowerCase().includes(name.toLowerCase());
    });
  }
  let lengthofSearch = filtered.length;
  return { filtered, lengthofSearch };
};

const CandidateProfilePayrollUploadedWidget = ({
  candidateId,
  candidate,
  reload,
}) => {
  console.log("candidate", candidate);

  const MexxarAPI = HOSTMexxar;

  const currentDate = new Date();
  const defaultStartDay = moment(
    moment(currentDate).subtract(30, "days")
  ).format("YYYY-MM-DD");
  const tommorow = moment(moment(currentDate).add(1, "days")).format(
    "YYYY-MM-DD"
  );

  const [data, setData] = useState();
  // const [rows, setRows] = useState([]);
  // const [error, setError] = useState(false);
  const [showpdf, setShowpdf] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [calendarShow, setCalendarShow] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenFilter2, setIsOpenFilter2] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false);

  const [searchAdvanced, setSearchAdvanced] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [resetResults, setResetResults] = useState(false);

  const [getEndDate, setGetEndDate] = useState(tommorow);
  const [getStartDate, setGetStartDate] = useState(defaultStartDay);
  const { addToast } = useToasts();
  const [meritId, setMeritId] = useState(candidate?.meritId);

  const [meritPlacementIds, setMeritPlacementIds] = useState(
    candidate?.meritPlacementIds
  );
  const [idError, setIdError] = useState({
    meritPlacementIdEroor: false,
    meritIdEroor: false,
  });

  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);

  const toggleShowTimeModal = (data) => {
    setData(data);
    setShowTimeModal(!showTimeModal);
  };

  let url =
    MexxarAPI +
    "workspace/payrollfilter?cid=" +
    candidateId +
    "&ps=PENDING&hrs=PENDING&rs=VERIFIED";

  const { loading, error, rows, hasMore } = usePayroll(
    url,
    query,
    pageNumber,
    searchAdvanced,
    moment(getStartDate).format("YYYY/MM/DD"),
    moment(getEndDate).format("YYYY/MM/DD"),
    resetResults
  );

  const reset = () => {
    setResetResults(!resetResults);
    setGetStartDate(defaultStartDay);
    setGetEndDate(tommorow);
  };

  const handleSearchOnChange = (e) => {
    setQuery(e.target.value);
  };

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  const [pdf, setPdf] = useState();

  const addMeritPlacementId = (candidateId, meritPlacementIds) => {
    console.log("angi merit idsss------?>", meritPlacementIds);

    if (meritPlacementIds.length) {
      axios
        .patch(
          `${HOSTMexxar}candidates/${candidateId}/meritPlacementId?meritPlacementIds=${meritPlacementIds}`,
          {}
        )
        .then((res) => {
          if (res.data.status === "success") {
            ToastCommon(true, "success", addToast);
            reload();
          } else if (res.data.status === "failed") {
            ToastCommon(true, "duplicate", addToast);
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((error) => {
          ToastCommon(true, "error", addToast);
        });
    } else {
      setIdError({
        meritPlacementIdEroor: true,
        meritIdEroor: false,
      });
    }
  };

  const addMeritId = (candidateId, meritId) => {
    if (meritId || meritId !== "") {
      axios
        .patch(
          `${HOSTMexxar}candidates/${candidateId}/meritId?meritId=${meritId}`,
          {}
        )
        .then((res) => {
          if (res.data.status === "success") {
            ToastCommon(true, "success", addToast);
            reload();
          } else if (res.data.status === "failed") {
            ToastCommon(true, "duplicate", addToast);
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((error) => {
          ToastCommon(true, "error", addToast);
        });
    } else {
      setIdError({
        meritPlacementIdEroor: false,
        meritIdEroor: true,
      });
    }
  };

  return (
    <div>
      <div className="form-inline mb-2">
        <FontAwesomeIcon icon={faFileAlt} className={"ml-2 mr-3  "} />
        <Label className={"mr-3"}>
          {rows.length} {rows.length == 1 ? "Timesheet" : "Timesheets"}
        </Label>

        <Button
          onClick={() => setIsOpenFilter2(!isOpenFilter2)}
          className="btn bg-dark-lt text-dark mx-1"
        >
          <span className="mx-1"> Configure Merit ID</span>
        </Button>

        <Button
          onClick={() => setIsOpenFilter(!isOpenFilter)}
          className="btn bg-dark-lt text-dark"
        >
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>

        <Input
          onChange={(e) => handleSearchOnChange(e)}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Payroll..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />
      </div>
      <UncontrolledTooltip target="searchBar">
        Search candidates by Name/Id and {ClientLable}s by Name/Id
      </UncontrolledTooltip>

      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label for="exampleDate">Start Date</Label>
                <Input
                  id="startDate"
                  type="date"
                  value={getStartDate}
                  onChange={(e) => setGetStartDate(e.target.value)}
                />
              </Col>
              <Col>
                <Label for="exampleDate">End Date</Label>
                <Input
                  id="endDate"
                  type="date"
                  value={getEndDate}
                  onChange={(e) => setGetEndDate(e.target.value)}
                />
              </Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    // onClick={handleSearch}
                    onClick={() => {
                      setSearchAdvanced(!searchAdvanced);
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    onClick={reset}
                    style={{ width: "70px" }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
            <Row></Row>
          </CardBody>
        </Card>
      </Collapse>

      <Collapse isOpen={isOpenFilter2}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label for="meritId">Merit ID</Label>
                <Input
                  type="text"
                  name="meritId"
                  id="meritId"
                  invalid={idError.meritIdEroor}
                  defaultValue={meritId}
                  onChange={(e) => setMeritId(e.target.value)}
                />
                <span className="text-sm text-danger">
                  {idError.meritIdEroor ? "Id Missing" : null}
                </span>
              </Col>
              <Col>
                <Label for="meritPlacementIds">Add Merit Placement IDs</Label>
                <Input
                  type="text"
                  name="meritPlacementIds"
                  id="meritPlacementIds"
                  invalid={idError.meritPlacementIdEroor}
                  onChange={(e) => {
                    const idsArray = e.target.value
                      .split(",")
                      .map((id) => id.trim()); // Splitting by comma and trimming spaces
                    setMeritPlacementIds(idsArray);
                  }}
                />
                <span className="text-sm text-danger">
                  {idError.meritPlacementIdEroor ? "Id Missing" : null}
                </span>{" "}
                <span className="text-sm text-info">
                  Enter IDs separated by commas
                </span>
              </Col>

              <Col>
                <Label for="meritPlacementIds">Merit Placement IDs</Label>
                {meritPlacementIds.length > 0 && (
                  <ul>
                    {meritPlacementIds.map((id, index) => (
                      <li key={index}>{id}</li>
                    ))}
                  </ul>
                )}
              </Col>

              <Col>
                <div className="d-flex flex-column align-items-end ">
                  <br />
                  <Button
                    color="success m-1 btn-sm mt-12"
                    style={{ width: "140px" }}
                    onClick={() => {
                      setIdError({
                        meritPlacementIdEroor: false,
                        meritIdEroor: false,
                      });
                      addMeritId(candidateId, meritId);
                    }}
                  >
                    Add Merit ID
                  </Button>{" "}
                  <Button
                    color="success m-1 btn-sm mt-12"
                    style={{ width: "140px" }}
                    onClick={() => {
                      setIdError({
                        meritPlacementIdEroor: false,
                        meritIdEroor: false,
                      });
                      addMeritPlacementId(candidateId, meritPlacementIds);
                    }}
                  >
                    Merit Placement ID
                  </Button>
                </div>
              </Col>
            </Row>
            <Row></Row>
          </CardBody>
        </Card>
      </Collapse>

      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      {rows && !loading ? (
        filterByName(query, rows).lengthofSearch > 0 ? (
          filterByName(query, rows.sort(SortFunctionAscending)).filtered.map(
            (row, id) => {
              return (
                <animated.div style={animationProps} key={id}>
                  <div className="card mb-2 " key={id}>
                    <div className="card-body">
                      <div className="flex flex-row d-flex justify-content-between ">
                        <div className="d-flex  " style={{ width: "250px" }}>
                          <div>
                            <div>{row.hospitalName}</div>

                            <div className="item-mail text-muted h-1x d-none d-sm-block">
                              <small>
                                Booking Date :{" "}
                                {moment(row.booking.bookingDate).format("llll")}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{ width: "60px" }}
                        ></div>

                        <div className="textCenter-Custom">
                          <div>Submitted Date</div>
                          <div className="text-muted">
                            {" "}
                            {moment(row.submitedDate).format("llll")}
                          </div>
                        </div>
                        <div>
                          <Button
                            color="success"
                            className="mr-2"
                            onClick={() => {
                              toggleShowTimeModal(row);
                            }}
                          >
                            Show
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </animated.div>
              );
            }
          )
        ) : (
          <NoSearchResults />
        )
      ) : null}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}

      <ModalComponent
        show={calendarShow}
        header="Log new call back"
        closeModal={() => handleCalendarShow(false)}
      >
        <SmallCalendar />
        <div className="mt-3">
          {" "}
          <Button color="success">Send</Button>{" "}
          <Button color="secondary" onClick={() => handleCalendarShow(false)}>
            Cancel
          </Button>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showTimeModal}
        header="Time Sheet"
        size="lg"
        closeModal={() => setShowTimeModal(false)}
      >
        <TimeSheet
          data={data}
          closeModal={() => setShowTimeModal(false)}
        ></TimeSheet>

        <div className="mt-4">
          <Col sm={{ size: 8, offset: 8 }}></Col>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showpdf}
        header="Send Report"
        closeModal={() => setShowpdf(false)}
      >
        <ShowReport
          report={pdf}
          payroll={true}
          closeModal={() => setShowpdf(false)}
        ></ShowReport>
      </ModalComponent>
    </div>
  );
};

export default CandidateProfilePayrollUploadedWidget;
