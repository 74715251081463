import React, { useEffect, useState } from "react";

import axios from "axios";
import Previews from "../audit/docView/Previews";
import TimesheetData from "./TimesheetData";
import {
  Row,
  Col,
  FormGroup,
  Container,
  UncontrolledTooltip,
  Button,
  Spinner,
  Label,
} from "reactstrap";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import { HOSTMexxar } from "../../configs/api-config";
import { faCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { forEach, filter, isNull } from "lodash";
import ToastCommon from "../../components/toastCommon";
import PayrollSummary from "./PayrollSummary";
import moment from "moment";
import PendingTimesheetsforVerification from "./pendingTimesheetsforVerification";
import { connect, useDispatch } from "react-redux";
import {
  addAITimesheetData,
  addSystemTimesheetData,
  deleteAllTimesheetsLocally,
  saveTimesheetsLocally,
  timesheetExistStatus,
  updateScanProcessStatus,
  logFailedTimesheets,
  scanStatusIndividual,
  finalManualReviewConfirmation,
  updates3BucketFilrUrl,
  saveGeneratedUrl,
  updateTimesheetFileName,
  updateTimesheetUrl,
} from "../../redux/actions/timesheetBatchScanAction";
import PreviewsBatchTimeSheets from "../audit/docView/PreviewsBatchTimeSheets";
import TimesheetDataBatchView from "./TimesheetDataBatchView";
import * as XLSX from "xlsx";
// import TimesheetDataBatchView2 from "./TimesheetDataBatchView2";
// import TimeSheetDataBatchView2 from "./TimeSheetDataBatchView2";
import TimesheetDataBatchView2 from "./TimesheetDataBatchView2";
import FileDownloader from "./FileDownloader";

//******************Process******************/
//1. Upload timesheets(one or more up to 100 timesheets)
//2. Show uploaded Timehseets as a list
//3. Have a function to show the uploaded timesheets- (when user clicks image icon show the relavent Timesheet
//4. Click process-Start the timesheet processing.Show each process timesheet as a list
//5. Click Review option to review the Timesheet by comparing AI process data and DB data with view of the Timesheet
//6. Once all the timesheets been reviewed or the successfully reviewed timesheets should be import as an excelsheet.
//7. Only export the timesheets been reviewed

function InstructionUI() {
  return (
    <div className="card d-flex flex-column align-items-start justify-content-center p-5 text-justify">
      <h2>s3 bucket upload testing</h2>

      <h6>Please upload timesheets to start the scanning process</h6>
      <p className="ml-2">How to scan timesheets:</p>
      <small className="ml-2">
        1. Upload one or more timesheets to the left-side Drag & Drop Box. 📂
      </small>
      <small className="ml-2">
        2. Please wait while all your files are being uploaded. ⏳
      </small>
      <small className="ml-2">
        3. Once timesheets are uploaded, click the "Process" button to begin the
        AI scan. 🚀
      </small>
      <small className="ml-2">
        4. Wait until the scan process is complete. This might take some time.
        Enjoy a cup of coffee ☕️ :)
      </small>
      <small className="ml-2">
        5. Once the timesheets are processed, you will be able to review them
        manually. 👀
      </small>
      <small className="ml-2">6. Click "Verify" for each timesheet. ✅</small>
      <small className="ml-2">
        7. When all the verification is done, click "Confirm" to end the
        verification process. ✔️
      </small>
      <small className="ml-2">
        8. Finally, click "Export" to download your ".xlsx" 📊 file. Enjoy😉
      </small>
      <p className="ml-2 mt-2">
        Note: If there are any errors during the above process, we will provide
        sufficient instructions to resolve them. If you are unable to resolve
        any issues, please contact the "Mexxar Team" for support. 📧
      </p>
    </div>
  );
}
function TimeSheetBatchScan2(props) {
  const {
    timesheets,
    scanProcessingStatus,
    failedTimesheets,
    finalManualReview,
  } = props;
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();

  const [fileUploaded, setFileUploaded] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [processLoading, setProcessLoading] = useState(null);
  const [incommingAIdata, setIncommingAIdata] = useState(null);
  const [systemTimeSheetData, setSystemTimeSheetData] = useState(null);
  const [timeSheetFalse, setTimeSheetFalse] = useState(null);
  const [showSummary, setShowSummary] = useState(false);
  const [payrollData, setPayrollData] = useState(null);
  const [payrollError, setPayrollError] = useState(null);
  const [documentUploaded, setDocumentUploaded] = useState(false);
  const [review, setReview] = useState([]);
  const [selectedFileForReview, setSelectedFileForReview] = useState(null);
  const [savedRefNo, setSavedRefNo] = useState(null);
  const [savedRefNo2, setSavedRefNo2] = useState(null);
  const [ref3, setRef3] = useState(null);
  const [clients, setClients] = useState([{ id: 0, name: "Select" }]);

  const handleChangeStatus = (files) => {
    setSelectedFiles(files);

    //adding timeSheetExist: false, AIModalData: {}, DBData: {}, to each file
    const updatedFiles = files.map((file) => ({
      file: file,
      fileName: file.path.split(".")[0],
      fileExtention: file.path.split(".")[1],
      timeSheetExist: false,
      AIModalData: {},
      DBData: {},
      AIModalDataLoadingStatus: {},
      DBData: {},
      manuallyReviewed: false,
      generatedUrl: "",
      s3bucketfileurl: "",
      aIReviewStatus: "NOT_STARTED", //"NOT_STARTED","NOT_MATCHING","MATCHING",
      reviewedData: {},
      scanStatus: {
        status: "NOT_STARTED", //"NOT_STARTED, "SCANNING","DONE","FAILED"
        message: "",
      },
    }));

    dispatch(saveTimesheetsLocally(updatedFiles));
  };

  const getTimesheetFromSystem = (data, file) => {
    if (data) {
      axios
        .get(HOSTMexxar + "bookings/reference/" + data)
        .then((res) => {
          setSystemTimeSheetData(res.data.body);
          dispatch(addSystemTimesheetData(file?.preview, res.data.body));

          if (res.data.message === "Not Existing") {
            setTimeSheetFalse(true);
            setSavedRefNo2(data);

            console.log("before retry, reference number saved:", savedRefNo2);
            dispatch(timesheetExistStatus(file.preview, false));
          } else if (res.data.message === "fetched") {
            setTimeSheetFalse(false);
            dispatch(timesheetExistStatus(file.preview, true));
            // logAndSetRefNo(data, false);
            setSavedRefNo(data);

            console.log("After retry, reference number saved:", data);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  //AI extract reference no from timesheet data

  const refNoSet = (data, file) => {
    let tempArray = [];
    tempArray.push([data]);

    forEach(data, function (value, key) {
      const regExpStr = "d[0-9]Ref";
      const result = new RegExp(regExpStr).test(key.toString());

      if (result) {
        if (value.confidence) {
          //getTimesheetFromSystem("182937463748958");
          console.log("ref", value.value);

          getTimesheetFromSystem(value.value, file);
        }
      }
      // return 123456677
    });
  };

  function getrefNo(data) {
    const rowHighestCount = data.rowInfo.rowHighestCount;
    console.log("data", data);
    console.log("data2", rowHighestCount);
    const refNo = data[rowHighestCount + "Ref"].value;
    console.log("ref3", refNo);

    setRef3(refNo);

    console.log("previously scanned reff no:", ref3);

    return refNo;
  }

  // set AI data
  const prepareAIData = (data, file) => {
    let tempArray = [];
    tempArray.push([data]);

    let arrangeData = {};

    forEach(data, function (value, key) {
      let temp = [
        "Breakmin",
        "Date",
        "End",
        "Ref",
        "Start",
        "Totalhours",
        "Ward",
      ];

      let tempDateArray = [];

      forEach(temp, function (element) {
        const regExpStr = "d[0-9]" + element;
        const result = new RegExp(regExpStr).test(key.toString());

        if (result) {
          if (value.confidence) {
            if (element == "Start" || element == "End") {
              let condition = moment(value.value, "HH:mm", true).isValid();

              if (condition) {
                arrangeData[element] = value.value;
              }
            } else if (element == "Date") {
              let date = moment(value.value);
              if (date.isValid()) {
                arrangeData[element] = value.value;
              }
            } else if (element == "Breakmin" || element == "Totalhours") {
              const numericValue = parseInt(value.value);
              if (!isNaN(numericValue)) {
                arrangeData[element] = value.value;
              }
            } else {
              arrangeData[element] = value.value;
            }
          }
        }
      });
    });

    arrangeData["Band"] = data.band.value;
    arrangeData["HospitalName"] = data.hospitalName.value;
    arrangeData["CandidateId"] = data.locumId.value;
    arrangeData["CandidateName"] = data.locumName.value;
    arrangeData["Speciality"] = data.speciality.value;

    setIncommingAIdata(arrangeData);
    console.log("arrangeData:", arrangeData);
    dispatch(addAITimesheetData(file.preview, arrangeData));
  };

  const processTimeSheet = async (file) => {
    setProcessLoading(true);

    const fd = new FormData();
    fd.append("file", file);

    axios({
      method: "post",
      url: "https://time.mexxar.com/uploadtimesheet/",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      // withCredentials: true,
      data: fd,
      mode: "cors",
    })
      .then((res) => {
        if (res.data.status == "success") {
          refNoSet(res.data.body, file);
          prepareAIData(res.data.body, file);
        }

        setProcessLoading(false);
      })
      .catch((e) => {
        // console.log(e);
        setProcessLoading(false);
      });
  };

  useDidMountEffect(() => {
    setSystemTimeSheetData(null);
    setIncommingAIdata(null);
  }, [selectedFiles, showSummary]);

  useDidMountEffect(() => {
    if (isNull(incommingAIdata) && isNull(systemTimeSheetData)) {
      setSelectedFiles(null);
      setDocumentUploaded(!documentUploaded);
    }
  }, [showSummary]);

  const processBatchTimeSheet = async () => {
    let noOfFiles = selectedFiles?.length;

    for (let i = 0; i < noOfFiles; i++) {
      await processTimeSheet(selectedFiles[i]);
    }
  };

  // useEffect(() => {
  //   dispatch(deleteAllTimesheetsLocally());
  // }, []);

  ///new method

  // Process timesheets sequentially
  const processTimesheetsSequentially = async (timesheets) => {
    setSelectedFileForReview(null);
    dispatch(updateScanProcessStatus("SCANNING"));
    setProcessLoading(true);

    try {
      for (let i = 0; i < timesheets.length; i++) {
        await processSingleTimesheet(timesheets[i].file);
      }

      dispatch(updateScanProcessStatus("DONE"));
    } catch (error) {
      dispatch(updateScanProcessStatus("INTERRUPTED"));
    }

    setProcessLoading(false);
  };

  // Get file url in S3 Bucket //

  const [generatedUrl, setGeneratedUrl] = useState("");

  const processSingleTimesheet = async (file) => {
    dispatch(scanStatusIndividual(file?.preview, "SCANNING", ""));
    try {
      const fd = new FormData();
      fd.append("file", file, file.name);
      console.log("checkfile name", file);

      const scanResponse = await axios({
        method: "post",
        url: "https://time.mexxar.com/uploadtimesheet/",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
        data: fd,
        mode: "cors",
      });
      console.log("response", scanResponse.data);
      if (scanResponse.data.status === "success") {
        const ref = getrefNo(scanResponse.data.body);

        console.log("angi ref number", ref);

        const s3UploadFormData = new FormData();

        const referenceNumber = scanResponse.data.body;
        s3UploadFormData.append("file", file);
        s3UploadFormData.append("fileName", ref);

        const s3UploadResponse = await axios.post(
          "https://time.mexxar.com/timesheet/file/upload",
          s3UploadFormData,

          {
            params: {
              bucketName: "mexxar-timesheet",
              folderName: "mex_candy",
            },
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            },
            mode: "cors",
          }
        );

        if (s3UploadResponse.status === 200) {
          refNoSet(scanResponse.data.body, file);
          prepareAIData(scanResponse.data.body, file);

          dispatch(scanStatusIndividual(file?.preview, "DONE", "success"));
          dispatch(updateTimesheetFileName(file?.preview, ref)); //Change the file name to the refernce number
          console.log("angi s3 upload function----------->", file?.preview);

          getBucketUrl(file?.preview, ref);
        } else {
          dispatch(logFailedTimesheets(file, "S3 upload failed"));
          dispatch(
            scanStatusIndividual(file?.preview, "FAILED", "S3 upload failed")
          );
        }
      } else {
        dispatch(logFailedTimesheets(file, scanResponse.data.message));

        dispatch(
          scanStatusIndividual(
            file?.preview,
            "FAILED",
            scanResponse.data.message
          )
        );
      }
    } catch (error) {
      dispatch(logFailedTimesheets(file, error.message));

      dispatch(scanStatusIndividual(file?.preview, "FAILED", error.message));
    }
  };

  const getBucketUrl = async (fileId, BookingReferenceNo) => {
    try {
      let timesheet = timesheets.filter(function (item) {
        return item.file.preview == fileId;
      });

      const fileName = BookingReferenceNo + "." + timesheet[0].fileExtention;

      const response = await axios.get(
        "https://time.mexxar.com/timesheet/url",
        {
          params: {
            fileName: fileName,
            folderName: "mex_candy",
            linkDuration: "86400",
          },
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
          mode: "cors",
        }
      );

      if (response.status === 200) {
        const url = response.data.body.url;

        const expirationTime = new Date();
        expirationTime.setMinutes(expirationTime.getMinutes() + 0);

        localStorage.setItem("generatedUrl", url);
        localStorage.setItem(
          "generatedUrlExpiration",
          expirationTime.toISOString()
        );

        console.log(
          "angi s3 getBucketUrl function-----response.status=200------>",
          fileId
        );

        setGeneratedUrl(url);
        dispatch(saveGeneratedUrl(url));
        dispatch(updateTimesheetUrl(fileId, url));

        console.log("  URL generated successfully:", url);
      } else {
        console.error("Failed to generate URL", response.status);
        console.error("Error message:", response.data.message);
      }
    } catch (error) {
      console.error("Error generating URL:", error.message);
    }
  };

  const toggleReview = (data) => {
    const index = data.file;
    // Toggle to review

    if (review.includes(index.preview)) {
      setReview(review.filter((rowIndex) => rowIndex !== index.preview));
      setSelectedFileForReview(null);
    } else {
      setReview([index.preview]);
      setSelectedFileForReview(data);
    }
  };

  const extractReviewedData = () => {
    return timesheets.map((obj) => obj.reviewedData);
  };

  //********************* Export data to excel sheet *********************//

  const exportToExcel = () => {
    console.log("angi export to excell data", timesheets);
    const reviewedData = extractReviewedData();

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(reviewedData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate the Excel file and download it
    XLSX.writeFile(workbook, "reviewed_data.xlsx");
  };

  const restart = () => {
    dispatch(deleteAllTimesheetsLocally());
    setSelectedFileForReview(null);
  };

  //clear the selected candidate when changing the reviewing timehsheet
  useDidMountEffect(() => {
    setSelectedCandidate(null);
  }, [review]);

  return (
    <div
      className="  m-3 p-3 "
      // className="d-flex p-2 m-2 align-items-between justify-content-center "
      style={{ width: "100%", overflowX: "hidden" }}
    >
      <div className="row justify-content-around ">
        <div
          className="  navbar-expand-lg "
          style={{ position: "fixed", top: "50px", left: "50px", zIndex: 999 }}
        >
          <Link
            to={{
              pathname: "/dashboard/payroll",
            }}
          >
            <div className="btn btn-sm btn-icon btn-rounded btn-raised  bg-dark-overlay ">
              <FontAwesomeIcon
                className="feather feather-more-vertical text-fade"
                icon={faChevronLeft}
              />
            </div>
          </Link>
        </div>

        <div class="card p-3  col-sm-12 col-md-6 d-flex align-items-between justify-content-start h-80">
          {timesheets?.length > 0 && (
            <>
              <Button
                className="m-2"
                disabled={scanProcessingStatus == "SCANNING"}
                color={`${timeSheetFalse ? "danger" : "success"}`}
                onClick={() => {
                  // processBatchTimeSheet();
                  processTimesheetsSequentially(timesheets);
                }}
              >
                {scanProcessingStatus == "SCANNING" && (
                  <Spinner
                    animation="border"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "5px",
                    }}
                  />
                )}
                {scanProcessingStatus == "SCANNING" && "Processing"}
                {scanProcessingStatus == "INTERRUPTED" && "Retry"}
                {scanProcessingStatus == "NOT_STARTED" && "Process"}
                {scanProcessingStatus == "DONE" && (
                  <>
                    <FontAwesomeIcon icon={faCheck} className="mx-2" />{" "}
                    Reprocess
                  </>
                )}
              </Button>
              {scanProcessingStatus == "SCANNING" && (
                <Button onClick={restart}>Cancel</Button>
              )}
              {scanProcessingStatus == "DONE" && (
                <Button onClick={restart}>Start New Set</Button>
              )}
            </>
          )}

          <PreviewsBatchTimeSheets
            showFile={selectedFileForReview}
            expandStatus={expand}
            fileUploaded={(data) => setFileUploaded(data)}
            onUpload={(data) => handleChangeStatus(data)}
            documentUploaded={documentUploaded}
            showUploadSection={scanProcessingStatus !== "SCANNING"}
            review={review}
          />
        </div>
        <div class="col-sm-12 col-md-6">
          {/* {timesheetBatchScanAIData?.timesheetsAiData?.map((item)=>{
            return(
              <PendingTimesheetsforVerification toggleReview={toggleReview} />
            )
          })} */}

          {timesheets?.length == 0 && <InstructionUI />}

          {timesheets?.length > 0 && (
            <div className="card">
              <div className="card-body">
                <h5>Process Your Timesheets </h5>
                <p className="text-justify">
                  Hi,Please wait while Mexxi 🤖, our Mexxaar AI, scans your
                  timesheets. Take a break, have a ☕️, and enjoy as Mexxi works
                  its magic. ✨
                </p>
              </div>
            </div>
          )}

          {/***** Allocate 15 seconds avarage time for one timesheet *****/}
          {timesheets?.length > 0 && (
            <div className="card">
              <div className="card-body">
                <h6>
                  You have uploaded {timesheets?.length} Timesheet(s) to scan.{" "}
                </h6>
              </div>
            </div>
          )}

          {scanProcessingStatus == "DONE" && (
            <div className="card">
              <div className="card-body p-2">
                <Button
                  onClick={() =>
                    dispatch(finalManualReviewConfirmation("CONFIRMED"))
                  }
                  color={`${
                    failedTimesheets?.length > 0 ? "warning" : "success"
                  }`}
                >
                  Confirm
                </Button>
                <div></div>
              </div>

              {failedTimesheets?.length > 0 && (
                <div className="mx-3">
                  <Label>
                    These files are not included due to mentioned errors.Please
                    scan them seperatly or retry scaning and review before
                    confirming
                  </Label>
                  <ul>
                    {failedTimesheets?.map((file, index) => {
                      return (
                        <div
                          className="d-flex align-items-center justify-content-between "
                          key={index}
                        >
                          <div className="d-flex flex-column">
                            <small> File Name: {file?.file?.name} </small>
                            <small> Reason : {file?.errorMessage}</small>
                            {/* <small> Attampts : {file?.attampts}</small>   //do this later */}
                          </div>
                          <div>
                            <small>
                              <Button
                                onClick={() =>
                                  processSingleTimesheet(file?.file)
                                }
                              >
                                Retry
                              </Button>
                            </small>
                            <div>
                              {generatedUrl && (
                                <p>Generated URL: {generatedUrl}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}

          {scanProcessingStatus == "DONE" &&
            finalManualReview == "CONFIRMED" && (
              <div className="card">
                <div className="card-body p-2">
                  <label className="mx-2">
                  Please note that only reviewed and verified timesheets will be exported to the Excel sheet
                  </label>
                  <div className="d-flex flex-row">
                    <Button
                      onClick={exportToExcel}
                      color={`${
                        failedTimesheets?.length > 0 ? "warning" : "success"
                      }`}
                      className="mx-1"
                    >
                      Export to excel sheet
                    </Button>
                    <FileDownloader
                      fileUrls={[
                        "https://mexxar-timesheet.s3.amazonaws.com/mex_candy/4150221431.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAZEEGYM2EAWIB23M2%2F20240209%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240209T063033Z&X-Amz-Expires=10000&X-Amz-SignedHeaders=host&X-Amz-Signature=844356b4d68a7c2bead36b92f17ef9f6bb8c70d3f68821c63a836967eb672fc5",
                        "https://mexxar-timesheet.s3.amazonaws.com/mex_candy/4315212.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAZEEGYM2EAWIB23M2%2F20240209%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240209T063122Z&X-Amz-Expires=10000&X-Amz-SignedHeaders=host&X-Amz-Signature=daac61048fe4a5877e4b19193548d8cc6ac0b739fe9114f1cdeb3ce9ee7e7f8c",
                      ]}
                    />
                  </div>
                </div>
              </div>
            )}
          {timesheets?.map((file, index) => {
            return (
              <div key={index}>
                <PendingTimesheetsforVerification
                  toggleReview={toggleReview}
                  file={file}
                  selectedFile2={file}
                  refNum={ref3}
                />
                {review?.includes(file.file?.preview) && (
                  <TimesheetDataBatchView2
                    selectedFile={file}
                    refNum={ref3}
                    newRefNumber={savedRefNo}
                    systemTimeSheetData={file?.DBData}
                    timeSheetExist={file?.timeSheetExist}
                    incommingAIdata={file?.AIModalData}
                    // processTimeSheet={processTimeSheet}
                    processLoading={processLoading}
                    setShowSummary={(data) => setShowSummary(data)}
                    selectedCandidate={selectedCandidate}
                    setSelectedCandidate={(data) => setSelectedCandidate(data)}
                    retry={(data) => getTimesheetFromSystem(data, file?.file)}
                    setPayrollData={(data) => {
                      setPayrollData(data);
                    }}
                    setPayrollError={(data) => {
                      setPayrollError(data);
                    }}
                  />

                  // <TimesheetDataBatchView2></TimesheetDataBatchView2>
                )}
              </div>
            );
          })}

          {/********* For batch scan only *********/}

          {showSummary && payrollData && (
            <PayrollSummary
              summary={payrollData}
              setShowSummary={(data) => {
                setShowSummary(data);
                setPayrollData(null);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    timesheets: state?.locallySaveTimesheetsReducer?.timesheets,
    scanProcessingStatus:
      state?.timesheets?.scanProcessingStatus || "NOT_STARTED",
    failedTimesheets: state?.timesheets?.failedTimesheets || [],
    finalManualReview:
      state?.timesheets?.finalManualReviewConfirmation || "NOT_CONFIRMED",
  };
}

export default connect(mapStateToProps, {})(TimeSheetBatchScan2);
