import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useMsal } from "@azure/msal-react";
import NoOutlookResult from "./NoOutlookResult";
import { loginRequest } from "../../configs/authConfig";
import OutlookBodySection from "./outlookBodySection";
import { callMsGraph } from "./graph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faFlag,
 } from "@fortawesome/free-solid-svg-icons";
import { faSearch, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import setAuthorizationToken from "../../util/auth/SetAuthorizationToken";
import { useHistory } from "react-router";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import loadingAnimation from "../../../src/images/puff.svg";
import NoConnection from "../errorPages/NoConnection";
import { generateAvatar } from "../../util/common/letterAvatar";
import { FunnelIcon } from "../../assets/icons/svg";
import Select from "react-select";
import { isEmpty } from "lodash";

const OutlooksMainBodyOfMails = ({
  folderName,
  selectedFolder,
  selectedAccount,
  emailItems,
  setEmailItems,
  setShowMainBody,
  showMainBody,
  selectedItem,
  setSelectedItem,
}) => {
  let cancelTokenSource = axios.CancelToken.source();
  let pageSize = 10;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageEnd, setPageEnd] = useState(false);
  const { instance, accounts } = useMsal();
  // const [emailItems, setEmailItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchQuery2, setSearchQuery2] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [searchQuery3, setSearchQuery3] = useState("");
  const [dateTo, setDateTo] = useState("");

  const handleItemClick = (item) => {
    if (item.hasAttachments) {
      RequestEmailGetAddAttachment(item);
    }
    setSelectedItem(item);
    setShowMainBody(false);

    item.isRead = true;

    localStorage.setItem("emailItems", JSON.stringify(emailItems));
    const listItem = document.getElementById(`item-${item.id}`);

    if (listItem) {
      listItem.classList.add("bg-primary");
      listItem.querySelector(".item-title").classList.add("text-white");
    }

    RequestEmailItemUpdate(item);
  };

  const handleBackClick = () => {
    setShowMainBody(true);
  };

  function getEmailList(pageNumber) {
    setLoading(true);
    setError(false);
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: isEmpty(selectedAccount)
          ? accounts[0]
          : selectedAccount?.account,
      })
      .then((response) => {
        const url =
          "https://graph.microsoft.com/v1.0/me/mailFolders/" +
          selectedFolder.folderId +
          "/messages";
        const headers = {
          Authorization: "Bearer " + response?.accessToken,
        };
        const params = {
          $top: pageSize,
          $skip: (pageNumber - 1) * pageSize,
        };

        if (searchQuery) {
          params.$search = searchQuery2;
        }
        if (searchQuery) {
          params.$search = searchQuery3;
        }

        axios
          .get(url, {
            headers,
            params,
            cancelToken: cancelTokenSource.token,
          })
          .then((response) => {
            if (response) {
              setEmailItems(response?.data?.value);
            }
            //Check if there is a next page
            const nextLink = response?.data["@odata.nextLink"];
            if (nextLink) {
              setPageEnd(false);
            } else {
              setPageEnd(true);
            }

            setLoading(false);
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              console.log("Request canceled:", error.message);
            } else {
              setLoading(false);
              setError(true);
            }
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  //************Email read reciption request ************//

  function RequestEmailItemUpdate(item) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: selectedAccount?.accounts,
      })
      .then((response) => {
        axios
          .patch(
            `https://graph.microsoft.com/v1.0/me/messages/${item.id}`,
            {
              isRead: true,
            },
            {
              headers: {
                Authorization: "Bearer " + response?.accessToken,
              },
            }
          )
          .then((response) => {})
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  function base64toBlob(base64String, contentType) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  const [emailBody, setEmailBody] = useState(""); // State to hold the email body

  function RequestEmailGetAddAttachment(item) {
    console.log("Attachments fetched: 011"); // Log fetched attachments

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: isEmpty(selectedAccount)
          ? accounts[0]
          : selectedAccount?.account,
      })
      .then((response) => {
        setAuthorizationToken(response?.accessToken, "email");
        document.getElementById("attached-files-main-email").innerHTML =
          "Downloading Attachments...";
        axios
          .get(
            `https://graph.microsoft.com/v1.0/me/messages/${item.id}/attachments`
          )
          .then((response) => {
            const attachments = response?.data?.value;
            const uniqueAttachmentNames = new Set();
            const cidToAttachmentMap = {};

            // Map attachments to their Content IDs
            const attachmentLinks = attachments
              .filter((attachment) => {
                if (!uniqueAttachmentNames.has(attachment.name)) {
                  uniqueAttachmentNames.add(attachment.name);
                  if (attachment.contentId) {
                    const imageName = attachment.name; // Extract CID without domain
                    cidToAttachmentMap[imageName] = attachment;
                    console.log(
                      `Mapped CID ${imageName} to attachment:`,
                      attachment
                    ); // Log CID mapping
                  }
                  return true;
                }
                return false;
              })
              .map((attachment) => {
                const base64String = attachment.contentBytes;
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {
                  type: attachment.contentType,
                });
                const url = URL.createObjectURL(blob);
                return `<a href="${url}" download="${attachment.name}">${attachment.name}</a>`;
              });

            const attachedFiles = attachmentLinks.join(", ");
            document.getElementById("attached-files-main-email").innerHTML =
              attachedFiles;

            const bodyContent = item?.body?.content; // Get the original email body
            console.log("Original email body:", bodyContent); // Log the original body

            let updatedBodyContent = bodyContent;
            console.log("cidToAttachmentMap:", cidToAttachmentMap); // Log fetched attachments

            updatedBodyContent = updatedBodyContent.replace(
              /<img[^>]+src="cid:([^"]+)"[^>]*>/g,
              (match, cid) => {
                const cidWithoutDomain = cid.split("@")[0]; // Strip off any domain part of the CID
                const attachment = cidToAttachmentMap[cidWithoutDomain]; // Look up the CID without the domain
                console.log(`Processing CID: ${cid}`); // Log current CID being processed
                if (attachment) {
                  console.log("Found matching attachment:", attachment); // Log matched attachment

                  const base64String = attachment.contentBytes;
                  const byteCharacters = atob(base64String);
                  const byteNumbers = new Array(byteCharacters.length);
                  for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);
                  const blob = new Blob([byteArray], {
                    type: attachment.contentType,
                  });
                  const newUrl = URL.createObjectURL(blob);
                  console.log("Replaced CID with new image URL:", newUrl); // Log new image URL
                  return `<img src="${newUrl}" alt="${attachment.name}" style="max-width: 100%; height: auto;" />`;
                }
                console.log("No matching attachment found for CID:", cid); // Log if no match found
                return match; // Return the original image tag if no match found
              }
            );

            console.log(
              "Updated email body after replacement:",
              updatedBodyContent
            ); // Log updated body content

            // Update the email body state
            setEmailBody(updatedBodyContent); // Save the new body in state
          })
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  // Call this function to cancel the ongoing request
  function cancelRequest() {
    cancelTokenSource.cancel("Request canceled");
    cancelTokenSource = axios.CancelToken.source(); // Create a new CancelToken source
  }

  function goToPage(pageNumber) {
    if (pageNumber >= 1) {
      setCurrentPage(pageNumber);
    }
  }

  useEffect(() => {
    getEmailList(1);
    setShowMainBody(true);
    setCurrentPage(1);
    setPageEnd(false);
    return () => {
      cancelRequest(); // Cancel the request when the component unmounts or when a new request is made
    };
  }, [selectedFolder, selectedAccount]);

  useDidMountEffect(() => {
    getEmailList(currentPage);
    return () => {};
  }, [currentPage]);

  return (
    <div className="d-flex w-100" id="content-body">
      <div className="d-flex flex-column flex" id="mail-list">
        <div style={{ height: "100vh" }}>
          {showMainBody && loading && !error && (
            <div className="d-flex justify-content-center align-items-center">
              <div>
                <img src={loadingAnimation} style={{ height: 100 }}></img>
              </div>
            </div>
          )}

          {!showMainBody && error && (
            <div>
              <NoConnection error={error}></NoConnection>
            </div>
          )}

          {!showMainBody && (
            <OutlookBodySection
              item={selectedItem}
              emailBody={emailBody}
              handleBackClick={handleBackClick}
              selectedAccount={selectedAccount}
            />
          )}
          {showMainBody && !loading && !error && emailItems?.length === 0 && (
            <NoOutlookResult />
          )}

          {showMainBody && !loading && !error && emailItems?.length > 0 && (
            <div className="d-grid gap-2 ml-3 scroll-y mx-4 mb-0 card">
              {emailItems.map((item, index) => (
                <div className="list list-row pointer " key={index}>
                  <div
                    className={`p-2 bg-light border  ${
                      !item.isRead ? "bg-danger text-white" : ""
                    }`}
                  >
                    <div
                      className="list-item  "
                      style={{ height: "55px" }}
                      onClick={() => handleItemClick(item)}
                      key={item.id}
                    >
                      <div>
                        <span
                          className={`w-40 avatar ${
                            generateAvatar(
                              item.from?.emailAddress?.name,
                              folderName
                            ).colorClass
                          }`}
                          data-toggle-class="loading"
                        >
                          {
                            generateAvatar(
                              item.from?.emailAddress?.name,
                              folderName
                            ).initials
                          }
                        </span>
                      </div>
                      <div className="flex   ">
                        <div
                          className={`item-title h-1x font-bold mt-2 ${
                            item.isRead
                              ? "bg-light"
                              : "text-info font-weight-bold"
                          }`}
                        >
                          {item.sender?.emailAddress?.name}
                        </div>

                        <div
                          className={`item-title h-1x ${
                            item.isRead
                              ? "bg-light"
                              : "text-info font-weight-bold"
                          }`}
                        >
                          {item.subject}
                        </div>
                      </div>
                      <div>
                        <div>
                          {item.hasAttachments && (
                            <FontAwesomeIcon
                              icon={faPaperclip}
                              color="#d1d1d1"
                            />
                          )}
                        </div>
                      </div>{" "}
                      <div>
                        <div>
                          {item?.flag?.flagStatus==="flagged" && (
                            <FontAwesomeIcon
                              icon={faFlag}
                              color="#dc3545"
                            />
                          )}
                        </div>
                      </div>
                      <div className="no-wrap">
                        <div className="item-date text-muted text-sm d-none d-md-block">
                          {/* {new Date(item.sentDateTime).toLocaleDateString()}
                           */}

                          {new Date(item.sentDateTime).toLocaleString()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="px-3 py-3 mt-auto"></div>
              {/* Pagination buttons */}
              <div className="px-3 py-3 mt-auto">
                <div className="d-flex align-items-center">
                  <div className="d-flex">
                    {currentPage > 1 && (
                      <Button
                        className="mx-2"
                        style={{ boxShadow: "none" }}
                        onClick={() => goToPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </Button>
                    )}

                    <Label className="mt-2">{currentPage}</Label>
                    {!pageEnd && (
                      <Button
                        className="mx-2"
                        style={{ boxShadow: "none" }}
                        onClick={() => goToPage(currentPage + 1)}
                        disabled={pageEnd}
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </Button>
                    )}
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OutlooksMainBodyOfMails;
