import Axios from "axios";
import React, { useEffect, useState } from "react";
import "./hospitalsMap.style.css";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital, faClock, faCar, faBus, faBicycle, faWalking } from "@fortawesome/free-solid-svg-icons";
import {
  REGISTER_CANDIDATE,
  POSTAL_CODES,
  CLIENTS,
  GoogleMapKey,
  ClientLable,
} from "../../configs/api-config";
import SliderComponent from "../../components/Slider/silder";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import { isNull, filter, forEach } from "lodash";
import loadingAnimation from "../../../src/images/puff.svg";
import { Alert, Label } from "reactstrap";
import {
  consumeCredits,
  getCredits,
  getTransactions,
} from "../../util/common/creditTransactions";
import { UncontrolledTooltip } from "reactstrap/lib";
import ToastCommon from "../../components/toastCommon";

const HospitalsMap = (props) => {
  const { addToast } = useToasts();
  const { candidateId, postalCode, enable, mode } = props;

  const MexxarApi = REGISTER_CANDIDATE;
  const MexxarApiClients =
    CLIENTS +
    "/filter?offset=100&page=1" +
    `${postalCode?.county && "&pc_county=" + postalCode?.county}`;
  const MexxarApiPostal = POSTAL_CODES;
  const [places, setPlaces] = useState([]);
  const [finalResults, setFinalResults] = useState([]);
  const [finalResultsDetails, setFinalResultsDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [longitude, setLongitude] = useState(postalCode?.longitude);
  const [latitude, setLatitude] = useState(postalCode?.latitude);
  const [incomingRadius, setIncomingRadius] = useState(5);
  const [zoom, setZoom] = useState(11);
  const [clients, setClients] = useState(null);
  const [hoverItemName, setHoverItemName] = useState(null);
  const [creditError, setCreditError] = useState({ bool: false, message: "" });


  const AnyReactComponent = ({ text, isSystemHospital }) => (
    <div>
      <FontAwesomeIcon
        size={hoverItemName === text.toUpperCase() ? "3x" : "2x"}
        color={isSystemHospital ? "#458AF7" : "#e91e63"}
        icon={faHospital}
      />
    </div>
  );

  const getLongitudeLatitude = () => {
    isNull(postalCode)
      ? setLongitude(null)
      : setLongitude(Number(postalCode?.longitude));

    isNull(postalCode)
      ? setLatitude(null)
      : setLatitude(Number(postalCode?.latitude));
  };



  const getPlaces = () => {
    setLoading(true);
    Axios.get(
      mode === "individual"
        ? `${MexxarApi}/${candidateId}/nearest-clients?radius=${incomingRadius * 1000}`
        : `${MexxarApi}/nearest-clients?radius=${incomingRadius * 1000}&postalCode=${postalCode?.code}`
    )
      .then((res) => {
        setLoading(false);
        if (res.data.results !== null) {
          let fin = [];
          let dests = "";


          res.data.results.forEach((item) => {
            const isSystemHospital = clients.some(client => client.value.toUpperCase() === item.name.toUpperCase());



            dests += `${item.geometry.location.lat},${item.geometry.location.lng}|`;

            fin.push({
              name: item.name,
              time: null,
              far: null,
              marketId: item.id,
              isSystemHospital,
              lat: item?.geometry?.location?.lat,
              lng: item?.geometry?.location?.lng,
            });
          });

          setPlaces(res.data.results);
          getMiles(dests, fin);

        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  const getMiles = (desti, fin) => {

    consumeCredits(1, "CLIENT_MAP") //consuming cretis
      .then((result) => {
        if (result?.status === "Failed") {
          setCreditError({ bool: true, message: result?.message });
        } else if (result?.status === "Success") {
          setCreditError({ bool: false, message: "" });
        }
      })
      .catch((err) => {
        console.error("Error consuming credits:", err);
      });

    Axios.get(
      mode === "individual"
        ? encodeURI(
          `${MexxarApi}/${candidateId}/nearest-clients-distance?destinations=${desti}`
        )
        : encodeURI(
          `${MexxarApi}/nearest-clients-distance?destinations=${desti}&postalCode=${postalCode?.code}`
        )
    )

      // )
      .then((res) => {
        if (res.data.rows.length > 0) {
          let data = [...fin];
          res.data.rows[0].elements.map((item, i) => {
            data[i].time = item.duration.text;
            data[i].far = item.distance.text;

          });

          let tempData = [];
          forEach(data, function (item) {

            if (item.name != null) {
              tempData.push(item);
            }
          });
          setFinalResultsDetails(tempData);
        }
        else {
          console.error(`Error fetching nearest clients' distance `, error);
        }




      });
  };



  const [transportSteps, setTransportSteps] = useState([]);

  const getTransport = (item) => {


    const { lat, lng, name } = item;




    const destination = `${lat},${lng}`;


    Axios.get(
      encodeURI(`${MexxarApi}/nearest-clients-distance-transport?destinations=${destination}&postalCode=${postalCode?.code}`)
    )
      .then((res) => {


        if (res.data.routes && res.data.routes.length > 0) {
          const steps = res.data.routes[0].legs[0].steps;
          const allSteps = [];

          const processSteps = (steps) => {
            steps.forEach((step) => {
              allSteps.push({
                time: step.duration?.text || 'N/A',
                far: step.distance?.text || 'N/A',
                name: step.html_instructions || 'N/A',
                travelMode: step.travel_mode || 'N/A',
                endLocationLat: step.end_location?.lat || 'N/A',
                endLocationLng: step.end_location?.lng || 'N/A',
              });

              if (step.steps && step.steps.length > 0) {
                processSteps(step.steps);
              }
            });
          };

          processSteps(steps);

          // Update the transport steps
          setTransportSteps(allSteps);
        }
      })
      .catch((error) => {
        console.error(`Error fetching nearest clients' distance for destination ${destination}:`, error);
      });
  };


  const getClients = () => {
    axios
      .get(MexxarApiClients)
      .then((res) => {
        let tempArray = [];
        forEach(res.data.body.content, function (item) {
          tempArray.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });

        setClients(tempArray);
      })
      .catch((err) => {
        // Handle error
      });
  };

  useEffect(() => {
    if (enable) {
      getClients();
      getLongitudeLatitude();
    }
    return () => { };
  }, [enable, postalCode, incomingRadius, longitude]);

  useDidMountEffect(() => {
    getCredits()
      .then((result) => {
        let creditResponse = result?.response;
        const clientMapCredits = creditResponse?.body?.filter(
          (item) => item.creditServiceType === "CLIENT_MAP"
        );
        if (clientMapCredits?.[0].creditBalance > 0) {
          setCreditError({
            bool: false,
            message: "",
            creditBalance: clientMapCredits?.[0]?.creditBalance,
          });
          if (enable) {
            getPlaces();
            getLongitudeLatitude();
          }
        } else {
          setCreditError({
            bool: true,
            message: "Credit error",
            creditBalance: 0,
          });
        }
      })
      .catch((err) => {
        console.error("Error consuming credits:", err);
      });

    return () => { };
  }, [clients, incomingRadius, postalCode]);

  const mouseEnter = (key, childProps) => {
    setHoverItemName(childProps.text.toUpperCase());
  };

  const mouseLeave = (key, childProps) => {
    setHoverItemName(null);
  };
  const [expandedItem, setExpandedItem] = useState(null);
  const [clickedIcon, setClickedIcon] = useState(null);

  return (
    <div className="card">
      {creditError.bool ? (
        <Alert color="danger">
          Please contact Mexxar to Recharge
          <br />
          Error: {creditError?.message} | Credit Balance:{" "}
          {creditError?.creditBalance}{" "}
        </Alert>
      ) : (
        ""
      )}
      {enable ? (
        <>
          <div className="mt-4" style={{ height: 400, width: "auto" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: GoogleMapKey }}
              center={{ lat: latitude, lng: longitude }}
              onChildMouseEnter={mouseEnter}
              onChildMouseLeave={mouseLeave}
              defaultZoom={zoom}
            >
              {places.map((item, i) => (
                <AnyReactComponent
                  key={i}
                  lat={item.geometry.location.lat}
                  lng={item.geometry.location.lng}
                  text={item.name}
                  isSystemHospital={clients?.some(client => client?.value.toUpperCase() === item.name.toUpperCase())}
                />
              ))}
            </GoogleMapReact>
          </div>
          <SliderComponent onStop={(data) => setIncomingRadius(data)} />
          <div
            style={{
              width: "100%",
              maxWidth: "700px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "10px",
              marginTop: "2rem",
            }}
          >
            <div className="d-flex align-items-center" style={{ flexBasis: "120px", flexGrow: 1, margin: "5px 0" }}>
              <FontAwesomeIcon color="#31c971" icon={faWalking} className="mr-1" />
              <small>Public Transport</small>
            </div>
            <div className="d-flex align-items-center" style={{ flexBasis: "100px", flexGrow: 1, margin: "5px 0" }}>
              <FontAwesomeIcon color="#f54394" icon={faCar} className="mr-1" />
              <small>Drive</small>
            </div>
            <div className="d-flex align-items-center" style={{ flexBasis: "100px", flexGrow: 1, margin: "5px 0" }}>
              <FontAwesomeIcon color="#458AF7" icon={faHospital} className="mr-1" />
              <small>Hospital</small>
            </div>
            <div className="d-flex align-items-center" style={{ flexBasis: "200px", flexGrow: 1, margin: "5px 0" }}>
              <FontAwesomeIcon color="#e91e63" icon={faHospital} className="mr-1" />
              <small>Unregistered Hospital</small>
            </div>
          </div>

          <>
            {finalResultsDetails?.length > 0 && (
              <div
                style={{
                  maxHeight: 700,
                  width: 700,
                  overflowY: "scroll",
                  marginTop: "2rem",
                }}
              >
                {finalResultsDetails.map((item, i) => {
                  const isSystemHospital = item.isSystemHospital;
                  const isExpanded = expandedItem === i;

                  const toggleExpand = (index, icon) => {
                    if (expandedItem === index && clickedIcon === icon) {
                      setExpandedItem(null);
                      setClickedIcon(null);
                    } else {
                      setExpandedItem(index);
                      setClickedIcon(icon);
                      getTransport(item);
                    }
                  };

                  return (
                    <div key={i}>
                      <div
                        className={`p-1 d-flex ${item.name.toUpperCase() === item.name.toUpperCase()}`}
                      >
                        <div className={`p-1 d-flex ${hoverItemName == item.name.toUpperCase() ? "bg-primary" : null}`}>
                          <div className="py-2" style={{ width: "30px", cursor: "pointer" }}>
                            <FontAwesomeIcon
                              color="#31c971"
                              icon={faWalking}
                              style={{ fontSize: '19px' }}
                              onClick={() => toggleExpand(i, 'walking')}
                            />
                          </div>
                          <div className="py-2" style={{ width: "30px", cursor: "pointer" }}>
                            <FontAwesomeIcon
                              color="#f54394"
                              icon={faCar}
                              style={{ fontSize: '19px' }}
                              onClick={() => toggleExpand(i, 'car')}
                            />
                          </div>
                          <div className="py-2" style={{ width: "30px" }}>
                            <FontAwesomeIcon
                              color={isSystemHospital ? "#458AF7" : "#e91e63"}
                              icon={faHospital}
                              style={{ fontSize: '19px' }}
                            />
                          </div>
                          <div className="py-2" style={{ width: "230px" }}>
                            <small>{item.name}</small>
                          </div>
                        </div>
                      </div>

                      {isExpanded && clickedIcon === 'car' && (
                        <div
                          style={{
                            backgroundColor: "#1e2645",
                            padding: "1rem",
                            marginTop: "0.5rem",
                            borderRadius: "8px",
                            border: "1px solid #333",
                            color: "#fff",
                            width: 600,
                          }}
                        >
                          <div
                            className="d-flex align-items-center py-2"
                            style={{ width: "100%" }}
                          >
                            <div className="d-flex align-items-center" style={{ width: "70px" }}>
                              <FontAwesomeIcon color="#31c971" icon={faClock} className="mr-1" />
                              <small>{item.time}</small>
                            </div>

                            <div className="d-flex align-items-center" style={{ width: "70px" }}>
                              <FontAwesomeIcon color="#f54394" icon={faCar} className="mr-1" />
                              <small>{item.far}</small>
                            </div>

                            <div className="d-flex align-items-center" style={{ width: "20px" }}>
                              <FontAwesomeIcon color={isSystemHospital ? "#458AF7" : "#e91e63"} icon={faHospital} />
                            </div>

                            <div className="d-flex align-items-center" style={{ width: "230px" }}>
                              <small>{item.name}</small>
                            </div>
                          </div>

                        </div>
                      )}

                      {isExpanded && clickedIcon === 'walking' && (
                        <div
                          style={{
                            backgroundColor: "#1e2645",
                            padding: "1rem",
                            marginTop: "0.5rem",
                            borderRadius: "8px",
                            border: "1px solid #333",
                            color: "#fff",
                            width: 600,
                          }}
                        >
                          <h5 style={{ marginBottom: "1rem", color: "#fff" }}>Steps to Destination:</h5>
                          {transportSteps.length > 0 ? (
                            transportSteps.map((step, stepIndex) => (
                              <div
                                key={stepIndex}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "1rem",
                                  color: "#fff",
                                }}
                              >
                                <div style={{ marginRight: "10px" }}>
                                  {step.travelMode === 'WALKING' && (
                                    <FontAwesomeIcon icon={faWalking} color="#4CAF50" />
                                  )}
                                  {step.travelMode === 'DRIVING' && (
                                    <FontAwesomeIcon icon={faCar} color="#2196F3" />
                                  )}
                                  {step.travelMode === 'TRANSIT' && (
                                    <FontAwesomeIcon icon={faBus} color="#FF9800" />
                                  )}
                                  {step.travelMode === 'BICYCLING' && (
                                    <FontAwesomeIcon icon={faBicycle} color="#FF5722" />
                                  )}
                                </div>

                                <div style={{ flexGrow: 1 }}>
                                  <div style={{ fontSize: "14px", fontWeight: "bold", color: "#fff" }}>
                                    {step.name.replace(/<\/?[^>]+(>|$)/g, "")}
                                  </div>
                                  <div style={{ fontSize: "12px", color: "#bbb" }}>
                                    <span>{step.far} • </span>
                                    <span>{step.time}</span>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <p style={{ color: "#fff" }}>No transport steps available.</p>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </>


        </>
      ) : (
        <div className="d-flex justify-content-center justify-items-center">
          <Label className="text-center p-3 d-flex align-items-center justify-content-center">
            The map is disabled. Please turn it on to see the Hospitals around
            the area
          </Label>
        </div>
      )}
      {loading && (
        <div className="d-flex justify-content-center justify-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }} alt="Loading" />
          </div>
        </div>
      )}
    </div>
  );
};

export default HospitalsMap;