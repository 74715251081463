import React, { useState, useEffect } from "react";
import { Card, CardBody, Label, Button } from "reactstrap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToasts } from "react-toast-notifications";

import {
  faPhone,
  faMicrophoneSlash,
  faMicrophone,
  faUpload,
  faChevronLeft,
  faChevronRight,
  faUser,
  faPhoneSquare,
  faMapPin,
  faMapMarkerAlt,
  faExpand,
  faPeopleArrows,
  faEdit,
  faCheck,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Badge } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import CircleCard from "../../components/CircleContent/button";
import loading from "../../../src/images/puff.svg";
import moment from "moment";
import BookingSummery from "../../components/Modal/ModalBody/bookingSummery";
import ModalComponent from "../../components/Modal/modalComponent";
import ToastCommon from "../../components/toastCommon";
import { HOSTMexxar } from "../../configs/api-config";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import { sendNotification } from "../../util/common/sendNotification";

const BookingSummeryWorkSpace = (props) => {
  const { userProfile, reset } = props;

  const { addToast } = useToasts();

  const [rowId, setRowId] = useState([]);
  const [dayData, setDayData] = useState(null);

  const [showCancleBooking, setShowCancleBooking] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [bookingPaidStatus, setBookingPaidStatus] = useState(null);

  useDidMountEffect(() => {
    //console.log("bonus",userProfile.bonus);
    let TempDayData = {
      availabilityStatus: "BOOKED",
      bonus: userProfile.bonus,
      bookingAttendedStatus: "CONFIRMED",
      bookingCallStatus: "",
      bookingDate: userProfile.bookingDate,
      date: userProfile.bookingDate,
      hospitalId: userProfile.clientId,
      hospitalName: userProfile.clientName,
      id: userProfile.id,
      referenceNo: userProfile.referenceNo,
      shift: userProfile.shift,
      shiftFrom: userProfile.shiftFrom,
      shiftTo: userProfile.shiftTo,
      uplift: userProfile.upLift,
      wardId: userProfile.ward.id,
      wardName: userProfile.ward.name,
    };

    setDayData(TempDayData);
  }, [rowId,bookingPaidStatus]);

  const changeConfirmStatus = () => {
    axios
      .put(
        HOSTMexxar + "bookings/" + userProfile.id + "/booking-status/CONFIRM"
      )
      .then((res) => {
        if (res.data.status === "success") {

          ToastCommon(true, "success", addToast);
          reset();
        }
      })
 
      .catch((err) => {
        ToastCommon(true, "error", addToast);

        //console.log(err)
        //toastCommon(true, "error");
      });
  };



 

  const changeBookingPaid = () => {
    const newStatus =
      userProfile.bookingPaidStatus === "PAID" ? "NOT_PAID" : "PAID";
  
    axios
      .patch(
        `${HOSTMexxar}bookings/paidStatus/${userProfile.id}?bookingPaidStatus=${newStatus}`
      )
  
      .then((res) => {
        if (res.data.status === "Success") {
       

         const bookingDetails = res.data.body[0]; 
         const { shiftName, referenceNo, bookingDate, shiftFrom, shiftTo } = bookingDetails;
 
     
         const formattedBookingDate = moment(bookingDate).format("dddd, MMMM D, YYYY");
 
       
         const formattedShiftFrom = shiftFrom ? moment(shiftFrom).format("h:mm A") : "Not specified";
         const formattedShiftTo = shiftTo ? moment(shiftTo).format("h:mm A") : "Not specified";
 
         sendNotification({
           notificationType: "BOOKING",
           title: `Booking Status Changed to ${newStatus} - Ref: ${referenceNo}, Date: ${formattedBookingDate}`,
           content: `Your booking has been updated to '${newStatus}' with details:\n` +
                    `Shift: ${shiftName}\n` +
                    `Shift Time: ${formattedShiftFrom} - ${formattedShiftTo}`,
           candidateId: userProfile.id,
           startDate: new Date(),
           addToast: addToast,
         });
          ToastCommon(true, "success", addToast);
          reset();
      

        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };


  const handleCancelOrRejectBooking = () => {
    setRowId(userProfile.candidatesId);
    setShowCancleBooking(true);
    // sendNotification({
    //   notificationType: "BOOKING",
    //   title: "Booking Status Changed",
    //   content: `Your booking has been canceled or rejected.`,
    //   candidateId: userProfile.id,
    //   startDate: new Date(),
    //   addToast: addToast
    // });
  };



  useDidMountEffect(() => {
    axios.get(HOSTMexxar + "candidates/" + rowId).then((res) => {
      if (res.data.status == "success") {
        setSelectedCandidate(res.data.body[0]);
      }
    });
  }, [rowId]);

  return (
    <div className="">
      <div>
        <div className="card  list-row mb-2 ">
          <div className="list-item " data-id="7" data-sr-id="44">
            <div className="d-flex align-items-center mr-1 ">
              <span className="mx-1">
                <b className="badge badge-circle sm text-primary"></b>
              </span>
            </div>

            <div>
              <a data-pjax-state="" href="/employee/candidate/6">
                <span></span>
              </a>
            </div>
            <div className="flex" style={{ flex: 4 }}>
              <a className="item-author text-color" data-pjax-state="">
                {userProfile.candidateName}
              </a>
              <div>
                <small className="text-muted">{userProfile.clientName}</small>
              </div>
              <div className="item-mail text-muted h-1x d-none d-sm-block">
                <span className="mr-1">
                  <small className="text-muted">
                    {moment(userProfile.bookingDate).format("ddd MMM DD YYYY")}{" "}
                    by {userProfile.employeeName}
                  </small>
                </span>
              </div>
            </div>

            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ flex: 2 }}
            >
              <div>Shift Type</div>
              <div>{userProfile.shift.name}</div>
            </div>
            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ flex: 2 }}
            >
              <div>Ward</div>
              <div>{userProfile.ward.name}</div>
            </div>

            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ flex: 2 }}
            >
              <div>Reference No.</div>
              <div>{userProfile.referenceNo}</div>
            </div>

            <div className="d-flex justify-content-center align-items-center flex-column ">
              <div>Paid Status</div>

              <Badge
                className="mt-2"
                color={
                  userProfile.bookingPaidStatus === "PAID"
                    ? "success"
                    : "danger"
                }
                onClick={changeBookingPaid}
                style={{ cursor: "pointer" }}
              >
                {userProfile.bookingPaidStatus === "PAID" ? "Paid" : "Not Paid"}
              </Badge>
            </div>

            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ flex: 2 }}
            >
              <div>
                {userProfile.bookingStatus == "UNCONFIRMED" ? (
                  <div className="d-flex ">
                    <Button
                      className="mx-2"
                      color="success"
                      onClick={changeConfirmStatus}
                    >
                      Accept
                    </Button>
                    <Button
                      color="danger"
                   
                      onClick={handleCancelOrRejectBooking}
                    >
                      Reject
                    </Button>
                  </div>
                ) : (
                  <Button
                    color="danger"
                
                    onClick={handleCancelOrRejectBooking}
                  >
                    Cancel Booking
                  </Button>
                )}
              </div>
            </div>

            <ModalComponent
              show={showCancleBooking}
              header="Booking Summary"
              closeModal={() => setShowCancleBooking(false)}
            >
              <BookingSummery
                // currentResetState={resetSheetData}
                reset={() => {
                  setShowCancleBooking(false);
                  reset();
                }}
                candidate={selectedCandidate}
                candidateId={Number(rowId)}
                dayData={dayData}
                heading="Booking summary"
                //  selectedRowData={selectedRowData}
                closeModal={() => setShowCancleBooking(false)}
                // reset={(e) => getAllRows(e)}
                // currentResetState={resetSheetData}
                incomingPage="BOOKING_WORKSPACE"
              />{" "}
            </ModalComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingSummeryWorkSpace;
