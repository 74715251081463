

import React from "react";
import axios from "axios";
import { HOSTMexxar, TRAININGS } from "../../configs/api-config";

export const sendNotification = (data) => {
  const { notificationType, title, content, candidateId, startDate, addToast } = data;
  const MexxarApiTRAININGS = TRAININGS;

  switch (notificationType) {
    case "EVENT":
    case "TRAINING":
    case "BOOKING":
    case "TIME_SHEET": 
    case "NOTICE": 
      sendRequest({
        notificationType: notificationType,
        title: title,
        content: content,
        candidateId: candidateId,
        eventDate: startDate
      }, addToast);
      break;
    default:
      console.error("Invalid notificationType:", notificationType);
      break;
  }
};

const sendRequest = (requestData, addToast) => {
  axios.post(
    HOSTMexxar + "mobile/notifications/fcm/create",
    requestData,
    {
      params: {
        topic: "mexxar-candidate-" + requestData.candidateId
      }
    }
  )
  .then((res) => {
    if (res.data.status === "success") {
     
    } else {
      console.error("Notification sending failed:", res.data);
     
    }
  })
  .catch((err) => {
    console.error("Error sending notification:", err);
   
  });
};

