export const formatToHHMMSS = (time) => {
  if (!time || !time.includes(":")) {
    return ""; // Invalid or empty input
  }

  // Split the time by ":"
  let parts = time.split(":");

  // Ensure the length is no more than 3 (HH, MM, SS)
  if (parts.length > 3) {
    parts = parts.slice(0, 3); // Take only the first three parts (HH, MM, SS)
  }

  // If it's only HH:MM, append ":00" to make HH:MM:SS
  if (parts.length === 2) {
    parts.push("00");
  }

  // Ensure HH, MM, SS are formatted correctly
  return parts.map((part) => part.padStart(2, "0")).join(":");
};
