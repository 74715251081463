import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { forEach, filter, isEmpty } from "lodash";
import useDidMountEffect from "./intialRunUseEffect";
import _ from "lodash";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../toastCommon";
import { Button, Label, Input, Form, FormGroup, Col, Alert } from "reactstrap";
import {
  REGISTER_CANDIDATE,
  WARDS,
  SHIFTS,
  CREATE_BOOKING,
  BOOKING_WORKSPACE_CANDIDATELIST,
  HOSTMexxar,
  ClientLable,
  CompanyName,
} from "../../../configs/api-config";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../configs/authConfig";
import { sendEmail } from "../../../pages/email/graph";
import outlookLogo from "../../../../src/images/Outlook-Logo.png";
import useCandidateList from "../../../customHooks/useCandidateList";
import { sendNotification } from "../../../util/common/sendNotification";
import { useTokenHandler } from "../../../pages/email/outlookFunctions";

function BookACandidate(props) {
  const { register, handleSubmit, watch, errors } = useForm();

  const {
    onCreate,
    closeModal,
    candidateId,
    reset,
    currentResetState,
    dayData,
    candidateWhenClick,
    auth,
    incommingPage,
    user,
    userProfile,
  } = props;

  const selectedAccount = JSON.parse(
    localStorage.getItem("selectedOutlookAccount")
  );
  const { checkTokenExpiration } = useTokenHandler();

  const MexxarApi = HOSTMexxar;
  const MexxarShift = SHIFTS;
  const MexxarCandidate = REGISTER_CANDIDATE;
  const MexxarCreateBooking = CREATE_BOOKING;
  const MexxarApiBookingWorkspace = BOOKING_WORKSPACE_CANDIDATELIST;

  const { addToast } = useToasts();

  const [query, setQuery] = useState("");

  const [check, setCheck] = useState(false);
  const [shifts, setShifts] = useState(null);
  const [likeWards, setLikeWards] = useState([]);
  const [candidates, setCandidates] = useState(null);
  const [likeClients, setLikeClients] = useState([]);
  const [currentShift, setCurrentShift] = useState(0);
  const [selectedWard, setSelectedWard] = useState(0);
  const [dataErrorStatus, setDataErrorStatus] = useState(false);
  const [dateSelected, setDateSelected] = useState(null);
  const [previousBooking, setPreviousBooking] = useState(false);

  const [candidateSpecialities, setCandidateSpecialities] = useState();
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [matchingLikeWardsWithClient, setMatchingLikeWardsWithClient] =
    useState(null);
  const [selectedCandidates, setSelectedCandidates] = useState("");
  const [outlookLoggedIn, setOutlookLoggedIn] = useState(false);
  const [outlookLoggedInError, setOutlookLoggedInError] = useState(false);
  const [wardCheck, setWardCheck] = useState({
    wardId: null,
    doesNotExistAsLikeWard: false,
    wardDoesNotExist: false,
  });
  const [pageNumber, setPageNumber] = useState(1);

  const [filterCustom, setFilterCustom] = useState({
    value: "ACTIVE",
    bool: true,
  });

  let url = REGISTER_CANDIDATE + "/status/" + filterCustom.value;

  const { loading, error, rows, hasMore } = useCandidateList(
    url,
    filterCustom,
    query,
    pageNumber
  );

  const onSubmit = (data) => {
    if (!dataErrorStatus) {
      addBooking(data);
    }
  };

  let shiftStartingDate = moment(userProfile.date).format("YYYY-MM-DD");
  let shiftStartingTime = moment(userProfile.startTime, "HH:mm").format(
    "HH:mm:ss"
  );

  let shiftEndingTime = moment(userProfile.endTime, "HH:mm").format("HH:mm:ss");

  let shiftSTime = moment(shiftStartingDate).add(shiftStartingTime).toDate();
  let shiftETime = moment(shiftStartingDate).add(shiftEndingTime).toDate();

  const addBooking = (data) => {
    let BStatus;
    check ? (BStatus = 1) : (BStatus = 0);

    let currentDate = moment(new Date()).toDate();

    let arrangData = {
      bookingDate: shiftStartingDate,
      bookingStatus: BStatus,
      bookingAttendedStatus: 4,
      createdDate: currentDate,
      referenceNo: userProfile?.refNo,
      candidateId: selectedCandidates.id,
      clientsId: Number(userProfile?.clients?.id),
      specialityId: Number(data.specialityId),
      shiftId: Number(data.shiftId),
      wardId: Number(wardCheck?.wardId),
      shiftTo: shiftETime,
      shiftFrom: shiftSTime,
      bonus: data.bonus !== undefined ? Number(data?.bonus) : 0,
      upLift: data.upLift !== undefined ? Number(data?.upLift) : 0,
      totalHours:
        data.totalHours !== undefined ? parseInt(data?.totalHours, 10) : 0,
      breakTime:
        data.breakTime !== undefined ? parseInt(data?.breakTime, 10) : 0,
    };
    setDisableCreateButton(true);

    axios
      .post(MexxarCreateBooking, arrangData)
      .then((res) => {
        if (res.data.status == "success") {
          closeModal();

          //sending the confirmation email to the candidate
          if (!previousBooking) {
            sendBookingConfirmationEmail(
              arrangData,
              selectedCandidates,
              Number(userProfile?.clients?.id),
              Number(userProfile.departmentId)
            );
            sendNotification({
              notificationType: "BOOKING",
              title: "New Booking Created",
              content:
                "Ref: " +
                `${userProfile?.refNo}` +
                " | " +
                `${userProfile?.clients.name}` +
                " | " +
                userProfile?.ward +
                " | " +
                shiftStartingDate +
                " | " +
                moment(shiftStartingTime, "HH:mm:ss").format("HH:mm") +
                " to " +
                moment(shiftEndingTime, "HH:mm:ss").format("HH:mm"),
              startDate: shiftStartingDate,
              addToast: addToast,
            });
            deleteRow(userProfile?.id); //delete the row after making the booking
          }

          ToastCommon(true, "success", addToast);
         } else {
          ToastCommon(true, "duplicate", addToast);
          setDisableCreateButton(false);
        }
      })
      .catch((err) => {
        // setDisableCreateButton(false);
        console.log("eroor------->", err);
        ToastCommon(true, "error", addToast);
      });
  };

  const { instance, accounts } = useMsal();

  //Function : sending the confirmation email to the candidate
  const sendBookingConfirmationEmail = (
    data,
    candidateWhenClick,
    selectedClient,
    selectedWard
  ) => {
    let subject = `Booking Confirmation: ${data?.referenceNo}`;
    let body = `<p>Hello ${candidateWhenClick?.value},</p>
                <p>We are writing to confirm your booking for ${moment(
                  data?.bookingDate
                ).format(
                  "ddd, DD-MM-YYYY"
                )} with ${CompanyName}. Please find the details below:</p>
                <ul>
                    <li>Reference no: ${data?.referenceNo}</li>
                    <li>Date: ${moment(data?.bookingDate).format(
                      "ddd, DD-MM-YYYY"
                    )}</li>
                    <li>${ClientLable}: ${selectedClient?.name}</li>
                    <li>Ward: ${selectedWard?.name}</li>
                    <li>Start time: ${moment
                      .utc(data?.shiftFrom)
                      .local()
                      .format("h:mm A")}</li>
                    <li>End time: ${moment
                      .utc(data?.shiftTo)
                      .local()
                      .format("h:mm A")}</li>
                </ul>
    `;

    let to = [
      {
        id: selectedCandidates?.id,
        value: selectedCandidates?.candidate?.email,
        label: selectedCandidates?.candidate?.email,
      },
    ];

    let cc = [];
    let bcc = [];
    let file = [];

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: selectedAccount ? selectedAccount : accounts[0],
      })
      .then((response) => {
        sendEmail(response?.accessToken, to, cc, bcc, subject, body, file, user)
          .then((response) => {
            ToastCommon(true, "sent", addToast);
            closeModal();
          })
          .catch((error) => {});
      })
      .catch((error) => console.log(error));
  };

  const getDateField = () => {
    axios
      .get(
        MexxarApiBookingWorkspace +
          "?startDate=" +
          moment(dateSelected).format("YYYY/MM/DD") +
          "&endDate=" +
          moment(dateSelected).add(1, "days").format("YYYY/MM/DD")
      )
      .then((res) => {
        // setInitialRows(res.data.body);

        let listOfCandidates = [];
        for (let i = 0; i < res.data.body.length; i++) {
          listOfCandidates.push({
            value:
              res.data.body[i].candidate.firstName +
              " " +
              res.data.body[i].candidate.lastName,
            label:
              res.data.body[i].candidate.firstName +
              " " +
              res.data.body[i].candidate.lastName,
            id: res.data.body[i].candidate.id,
            // candidate: res.data.body[i],
          });
        }
        setCandidates(listOfCandidates);
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  const deleteRow = (id) => {
    axios
      .delete(HOSTMexxar + "otherShift/" + id)
      .then((res) => {
        ToastCommon(true, "delete", addToast);
       })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const getCandidateSpecialities = (candidate) => {
    let preferedClient = [];
    _.forEach(candidate?.preferredClients, function (client) {
      preferedClient.push({
        id: client.id,
        name: client.name,
      });
    });

    let specialities = [];
    _.forEach(candidate?.candidateSpecialities, function (speciality) {
      specialities.push({
        id: speciality.id,
        name: speciality.name,
      });
    });

    // Set like wards, clients, and specialities
    setLikeWards(candidate?.likeWards);
    setLikeClients(preferedClient);
    setCandidateSpecialities(specialities);

    // Assuming `userProfile?.clients` is already available
    // Otherwise, pass the necessary client here
    if (candidate?.preferredClients?.length > 0) {
      getWardsInSelectedClient(
        candidate.preferredClients[0],
        candidate?.likeWards
      ); // Run the function with the first preferred client
    }
  };

  const getShifts = () => {
    axios.get(MexxarShift).then((res3) => {
      setShifts(res3.data.body);
    });
  };

  const getWardsInSelectedClient = async (client, likeWards) => {
    try {
      const { data } = await axios.get(
        `${MexxarApi}client-wards/clients/${client?.id}`
      );
      const wards = data.body;

      // Filter matching preferred wards and map to desired format
      const wardsList = wards
        .filter((ward) =>
          likeWards.some((item) => ward?.wardId === item?.wardId)
        )
        .map((ward) => ({
          id: ward.wardId, // Ward's original ID for booking purposes
          name: ward.wardName,
          label: ward.wardName,
        }));

      const ward = _.find(wards, { wardName: userProfile?.ward });
      const wardIsInCandidateLikeList = _.find(wardsList, {
        name: userProfile?.ward,
      });

      setWardCheck({
        wardId: isEmpty(ward) ? userProfile?.ward : ward?.wardId,
        doesNotExistAsLikeWard: isEmpty(wardIsInCandidateLikeList),
        wardDoesNotExist: isEmpty(ward),
      });

      // Set the first ward as the selected ward and the matching wards list
      setSelectedWard(wardsList[0]);
      setMatchingLikeWardsWithClient(wardsList);
    } catch (error) {
      console.error("Error fetching wards:", error);
    }
  };

  function checkOutlookLoginStatus() {
    if (accounts.length > 0) {
      // User is logged in
      setOutlookLoggedIn(true);
    } else {
      // User is not logged in
      setOutlookLoggedIn(false);
    }
  }

  const handleLoginOutLook = () => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        // Successful login

        if (response && response?.account) {
          setOutlookLoggedIn(true);
        } else {
          setOutlookLoggedIn(false);
          setOutlookLoggedInError(true);
        }
      })
      .catch((error) => {
        // Handle login error
        setOutlookLoggedIn(false);
        setOutlookLoggedInError(true);
      });
  };

  const handleInputChange = (e) => {
    setQuery(e);
  };
  const getCandidates = () => {
    let listOfCandidates = [];
    for (let i = 0; i < rows.length; i++) {
      listOfCandidates.push({
        value: rows[i].id,
        label: `${rows[i].firstName} ${rows[i].lastName}`,
        id: rows[i].id,
        candidate: rows[i],
      });
    }

    if (query != "") {
      setCandidates(listOfCandidates);
    } else {
      setCandidates([]);
    }
  };

  function checkIdExists(selectedId, array) {
    return array.some((item) => item.id === selectedId);
  }
  function calculateTotalHours(startTime, endTime) {
    // Parse the time strings into Date objects
    const start = new Date(`1970-01-01T${startTime}Z`);
    const end = new Date(`1970-01-01T${endTime}Z`);

    // Calculate the difference in minutes
    let diffInMinutes = (end - start) / 1000 / 60; // Convert milliseconds to minutes

    // Handle the case where the end time is on the next day
    if (diffInMinutes < 0) {
      diffInMinutes += 24 * 60; // Add 24 hours in minutes
    }

    // Calculate hours and minutes
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;

    // Format hours and minutes to always have two digits (e.g., "17:02")
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    // Return the formatted time as "HH:mm"
    return `${formattedHours}:${formattedMinutes}`;
  }

  useEffect(() => {
    getCandidates();
    return () => {};
  }, [rows]);

  useEffect(() => {
    getShifts();
    return () => {};
  }, []);

  useDidMountEffect(() => {
    getCandidateSpecialities(selectedCandidates.candidate);

    return () => {};
  }, [selectedCandidates]);

  useEffect(() => {
    getDateField();
    return () => {};
  }, [dateSelected]);

  useEffect(() => {
    // Call the function to check the login status
    checkOutlookLoginStatus();
    return () => {};
  }, []);

  useEffect(() => {
    checkTokenExpiration();
  }, [accounts]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Col sm={3}>
            <Label>Tag Candidates</Label>
          </Col>
          <Col sm={9}>
            <Select
              // isMulti
              name="candidateId"
              className="basic-multi-select"
              placeholder="Search candidates ..."
              classNamePrefix="select"
              onChange={(data) => setSelectedCandidates(data)}
              isLoading={loading}
              options={candidates}
              onInputChange={handleInputChange}
              noOptionsMessage={() => "No results found"}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Candidate Specialities</Label>
          <Col sm={8}>
            <Input
              type="select"
              id="specialityId"
              name="specialityId"
              // invalid={errors.wardId}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            >
              {candidateSpecialities &&
                candidateSpecialities.map((item, id) => {
                  return (
                    <option value={item.id} key={id}>
                      {item.name}
                    </option>
                  );
                })}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Shift type</Label>
          <Col sm={8}>
            <Input
              type="select"
              id="shiftId"
              name="shiftId"
              defaultValue={currentShift}
              invalid={currentShift === 0 && selectedCandidates}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
              onChange={(e) => setCurrentShift(e.target.value)}
            >
              <option value={0}>Select</option>

              <>
                {shifts &&
                  shifts.map((item, id) => {
                    return (
                      <option value={item.id} key={id}>
                        {item.name}
                      </option>
                    );
                  })}
              </>
            </Input>
            <Label className="mt-2  text-sm">
              Start Time: {userProfile?.startTime.slice(0, -3)} - End Time:{" "}
              {userProfile?.endTime.slice(0, -3)}
            </Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>{ClientLable}</Label>
          <Col sm={8}>
            <Label className="mt-2">{userProfile?.clients?.name}</Label>
          </Col>
        </FormGroup>{" "}
        <FormGroup row>
          <Label sm={3}>Department</Label>
          <Col sm={8}>
            <Label className="mt-2">{userProfile?.ward}</Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Total Hours</Label>
          <Col sm={8}>
            <Input
              type="text"
              defaultValue={calculateTotalHours(
                shiftStartingTime,
                shiftEndingTime
              )}
              placeholder="Total Hours"
              name="totalHours"
              innerRef={register}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Break Minutes</Label>
          <Col sm={8}>
            <Input
              type="number"
              placeholder="Break Minutes"
              name="breakTime"
              innerRef={register}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4}>Do Not Send Confirmation</Label>
          <Col sm={4}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="previousBooking"
                  value={previousBooking}
                  onClick={() => setPreviousBooking(!previousBooking)}
                />
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
        {wardCheck?.doesNotExistAsLikeWard && selectedCandidates ? (
          <Alert
            className="d-flex align-items-center justify-content-center flex-column "
            color="warning"
          >
            <li>
              {selectedCandidates?.label} does not have {userProfile?.ward} as a
              liked ward. Please assign it first.
            </li>
          </Alert>
        ) : null}{" "}
        {wardCheck?.wardDoesNotExist ? (
          <Alert
            className="d-flex align-items-center justify-content-center flex-column "
            color="warning"
          >
            <li>
              We cannot find {userProfile?.ward} as a ward added to{" "}
              {userProfile?.clients?.name}.Plese add it before creating the
              booking.
            </li>
          </Alert>
        ) : null}
        {!checkIdExists(userProfile?.clients?.id, likeClients) &&
        selectedCandidates ? (
          <Alert
            className="d-flex align-items-center justify-content-center flex-column "
            color="danger"
          >
            <li>
              This {ClientLable} is not assgined to selected candidate.Please
              select a different candidate to book the shift
            </li>
          </Alert>
        ) : null}
        {!outlookLoggedIn && !previousBooking && (
          // {!outlookLoggedIn && (
          <Alert
            className="d-flex align-items-center justify-content-center flex-column "
            color="danger"
          >
            <p>
              To send a booking confirmation via email, kindly ensure that you
              are logged in first
            </p>
            <img src={outlookLogo} style={{ height: 100 }}></img>
            <u className="text-info pointer ml-4" onClick={handleLoginOutLook}>
              Login
            </u>
          </Alert>
        )}
        <Alert color={"info"}>
          Please be aware that confirmation email is sending from{" "}
          {selectedAccount
            ? selectedAccount?.username
            : accounts?.[0]?.username}
        </Alert>
        <div className="d-flex justify-content-end">
          {!previousBooking && (
            <Button
              className="mr-2"
              color="success"
              type="submit"
              disabled={
                currentShift === 0 ||
                disableCreateButton ||
                !outlookLoggedIn ||
                !checkIdExists(userProfile?.clients?.id, likeClients)
              }
            >
              Create
            </Button>
          )}

          {previousBooking && (
            <Button
              className="mr-2"
              color="success"
              type="submit"
              disabled={
                currentShift === 0 ||
                disableCreateButton ||
                !checkIdExists(userProfile?.clients?.id, likeClients)
              }
            >
              Create
            </Button>
          )}

          <Button color="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.auth.item,
  };
}

export default connect(mapStateToProps, {})(BookACandidate);
