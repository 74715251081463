import axios from "axios";
import { HOSTMexxar } from "../../configs/api-config";
import { sendNotification } from "./sendNotification";
import moment from "moment";

export const rejectTimesheet = async (
  row,
  reason,
  status = "DECLINE",
  addToast,
  setRows
) => {
  const encodedReason = encodeURIComponent(reason);

  try {
    const res = await axios.patch(
      `${HOSTMexxar}timesheets/rejectTimesheet/${
        row.id
      }?status=${status}&rejectReason=${encodedReason}`
    );

    if (res.data.status === "Success") {
      console.log(`Timesheet ${status} check`, res.data);

      // Optional rows update
      if (setRows && typeof setRows === "function") {
        setRows((prevRows) =>
          prevRows.filter(
            (item) => item.booking.id !== row.booking.id && item.id !== row.id
          )
        );
      }

      const { bookingRef, dateOfShift, startTime, endTime } = res.data.body[0];
      const formattedDate = moment(dateOfShift).format("dddd, MMMM D, YYYY");

      // Send notification
      sendNotification({
        notificationType: "TIME_SHEET",
        title: `Timesheet for Booking ${bookingRef} on ${formattedDate} ${
          status === "DECLINE" ? "Rejected" : "Updated"
        }`,
        content: `Your timesheet has been ${
          status === "DECLINE" ? `rejected due to: ${reason}` : `updated`
        }. Scheduled time was from ${startTime} to ${endTime}.`,
        candidateId: row.booking.candidateId,
        startDate: new Date(),
        addToast: addToast,
      });

      // Return success so it can be handled externally
      return { success: true };
    }
  } catch (err) {
    // Return failure so it can be handled externally
    return { success: false };
  }
};
