import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Alert, Input, UncontrolledTooltip } from "reactstrap";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import axios from "axios";
import ModalComponent from "../../../components/Modal/modalComponent";
import NewOneField from "../../../components/Modal/ModalBody/Admin/newOneField";
import Delete from "../../../components/Modal/ModalBody/delete";
import filter from "lodash/filter";
import { useToasts } from "react-toast-notifications";
import NewClient from "../../../components/Modal/ModalBody/newClient";
import { CLIENTS, ClientLable, HOSTMexxar } from "../../../configs/api-config";
import ConfigureWards from "../../../components/Modal/ModalBody/Admin/configureWards";
import ToastCommon from "../../../components/toastCommon";
import WardsList from "../../../components/Modal/ModalBody/Admin/wardsList";
import { Link } from "react-router-dom";
import { toLower } from "lodash";
import loading from "../../../../src/images/puff.svg";
import { FunnelIcon } from "../../../assets/icons/svg";
import useClientsFetch from "../../../customHooks/useClientsFetch";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import { useSpring } from "react-spring";

const Clients = () => {
  const MexxarApi = CLIENTS;
  const MexxarHost = HOSTMexxar;

  const { addToast } = useToasts();

  /***************** Search filter ******************/
  const [query, setQuery] = useState(null);
  const [reset, setReset] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [remember, setRemember] = useState(true); /// <--------filter settings options saving and retreving/ if need activate later with below checkbox
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [searchAdvanced, setSearchAdvanced] = useState(false);
  const [callResponse, setCallResponse] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");

  const [mode, setMode] = useState("");
  const [rowID, setRowID] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showWards, setShowWards] = useState(false);

  const [showAddRow, setShowAddRow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [showConfigWards, setShowConfigWards] = useState(false);

  let url = HOSTMexxar + "clients/filter?";

  const {
    loadingClients,
    errorClients,
    rowsClients,
    hasMoreClients,
    totalElementsClients,
  } = useClientsFetch(url, query, pageNumber, searchAdvanced, reset);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loadingClients) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreClients) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingClients, hasMoreClients]
  );

  const addNewWard = (data) => {
    axios
      .post(MexxarHost + "client-wards", data)
      .then((res) => {
        if (res.data.status == "success") {
          setShowConfigWards(!showConfigWards);

          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const deleteRow = () => {
    axios
      .delete(MexxarApi + "/" + rowID)
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "delete", addToast);
          setReset(!reset);
        } else {
          ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  const deleteAllRows = () => {
    //console.log(rowID);
    selectedItems.forEach((item) => {
      axios
        .delete(MexxarApi + "/" + item)
        .then((res) => {
          setReset(!reset);
          setSelectedItems([]);
          setSelectAll(false);
        })
        .catch((err) => {
          //console.log(err)
        });
    });
  };

  const onItemSelect = (rowId) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == rowId;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item != rowId;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
    } else {
      setSelectedItems([...selectedItems, rowId]);
    }
  };

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rowsClients.forEach((item) => {
        ids.push(item.id);
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };

  function handleSearch(e) {
    if (e.target.value == "") {
      setQuery(null);
    } else {
      setQuery(e.target.value);
    }
    setPageNumber(1);
  }

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  /******* Use to set page number to default value of 1 when changing the employee or search advance filters  *******/
  useDidMountEffect(() => {
    setPageNumber(1);
    return () => {};
  }, [searchAdvanced, reset]);
  /*******End *******/

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  return (
    <DashboardLayout
      title={`${ClientLable}s`}
      subTitle={`Manage ${ClientLable}s`}
    >
      <div className="form-inline">
        <Button
          onClick={toggleFilter}
          className="btn bg-dark-lt text-dark"
          disabled
        >
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={handleSearch}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder={`Search ${ClientLable}...`}
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
          }}
        />
        <UncontrolledTooltip target="searchBar">
          Search {ClientLable}s by Name/Email/Phone No.
        </UncontrolledTooltip>
      </div>
      <div className="d-flex justify-content-between py-2  ">
        <div>
          {selectedItems.length > 0 ? (
            <Button
              color="danger"
              size="sm"
              onClick={() => {
                setShowDeleteAll(true);
              }}
            >
              Delete
            </Button>
          ) : null}
        </div>
        <div>
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowAddRow(true);
            }}
          >
            Add {ClientLable}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <table
            id="datatable"
            className="table table-theme table-row v-middle dataTable no-footer"
            role="grid"
            ariaDescribedby="datatable_info"
          >
            <thead>
              <tr role="row">
                <th
                  className="sorting_asc"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaSort="ascending"
                  ariaLabel="ID: activate to sort column descending"
                  style={{ width: "26px" }}
                >
                  <label className="ui-check m-0">
                    <input
                      type="checkbox"
                      name="id"
                      checked={selectAll}
                      onChange={() => checkAll()}
                    />
                    <i></i>
                  </label>
                </th>
                <th
                  className="sorting_asc"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaSort="ascending"
                  ariaLabel="ID: activate to sort column descending"
                  style={{ width: "26px" }}
                >
                  <span className="text-muted">ID</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">{ClientLable}</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Framework</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Email</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  width="150px"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Contact</span>
                </th>

                <th
                  className="sorting_disabled d-flex justify-content-end mr-3"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Tasks"
                >
                  <span className="text-muted d-none d-sm-block mr-3">
                    Actions
                  </span>
                </th>
                {/* <th
                  className="sorting_disabled d-flex justify-content-end mr-3"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Tasks"
                >
                  <span className="text-muted d-none d-sm-block mr-3">
                    Departments
                  </span>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {rowsClients?.map((row, i) => {
                if (rowsClients.length === i + 1) {
                  return (
                    <tr
                      className="odd"
                      ref={lastBookElementRef}
                      key={row.email}
                      role="row"
                    >
                      <td
                        style={{ minWidth: "30px", textAlign: "center" }}
                        className="sorting_1"
                      >
                        <label className="ui-check m-0">
                          <input
                            type="checkbox"
                            name="id"
                            checked={isSelected(row.id)}
                            onChange={() => onItemSelect(row.id)}
                          />{" "}
                          <i></i>
                        </label>
                      </td>
                      <td
                        style={{ minWidth: "30px", textAlign: "center" }}
                        className="sorting_1"
                      >
                        <small className="text-muted">{row.id}</small>
                      </td>
                      <td className="">{row.name}</td>
                      {row.framework.name ? (
                        <td className="">{row.framework.name}</td>
                      ) : (
                        ""
                      )}

                      <td className="">{row.email}</td>
                      <td className="">{row.primaryMobile}</td>

                      <td>
                        <div className="d-flex justify-content-end align-items-center">
                          <FontAwesomeIcon
                            // color="#448bff"
                            icon={faEdit}
                            id="editClient"
                            className="shadow-none fa-lg mb-2 btn-raised btn-wave mx-3 pointer"
                            onClick={() => {
                              setShowUpdateRow(true);
                              setRowID(row.id);
                            }}
                            size="1x"
                          />
                          <UncontrolledTooltip target="editClient">
                            Edit {ClientLable}
                          </UncontrolledTooltip>
                          <FontAwesomeIcon
                            // color="#FF5603"
                            icon={faTrashAlt}
                            id="deleteClient"
                            onClick={() => {
                              setShowDeleteRow(true);
                              setRowID(row.id);
                            }}
                            className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave pointer"
                            size="1x"
                          />
                          <UncontrolledTooltip target="deleteClient">
                            Delete {ClientLable}
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column align-items-between">
                          <Button
                            color="primary "
                            className="mb-1"
                            size="sm"
                            onClick={() => {
                              setShowConfigWards(true);
                              setRowID(row.id);
                              setRowData(row);
                              setMode("Add");
                            }}
                          >
                            Add Department
                          </Button>
                          <Link
                            to={{
                              pathname:
                                "/dashboard/admin/client-wards/" + row.id,
                            }}
                          >
                            <Button
                              color="primary "
                              size="sm"
                              // onClick={() => {
                              //   setShowWards(true);
                              //   setRowID(row.id);
                              // }}
                            >
                              Show Departments
                            </Button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr className="odd" key={row.email} role="row">
                      <td
                        style={{ minWidth: "30px", textAlign: "center" }}
                        className="sorting_1"
                      >
                        <label className="ui-check m-0">
                          <input
                            type="checkbox"
                            name="id"
                            checked={isSelected(row.id)}
                            onChange={() => onItemSelect(row.id)}
                          />{" "}
                          <i></i>
                        </label>
                      </td>
                      <td
                        style={{ minWidth: "30px", textAlign: "center" }}
                        className="sorting_1"
                      >
                        <small className="text-muted">{row.id}</small>
                      </td>
                      <td className="">{row.name}</td>
                      {row.framework.name ? (
                        <td className="">{row.framework.name}</td>
                      ) : (
                        ""
                      )}

                      <td className="">{row.email}</td>
                      <td className="">{row.primaryMobile}</td>

                      <td>
                        <div className="d-flex justify-content-end align-items-center">
                          <FontAwesomeIcon
                            // color="#448bff"
                            icon={faEdit}
                            id="editClient"
                            className="shadow-none fa-lg mb-2 btn-raised btn-wave mx-3 pointer"
                            onClick={() => {
                              setShowUpdateRow(true);
                              setRowID(row.id);
                            }}
                            size="1x"
                          />
                          <UncontrolledTooltip target="editClient">
                            Edit {ClientLable}
                          </UncontrolledTooltip>
                          <FontAwesomeIcon
                            // color="#FF5603"
                            icon={faTrashAlt}
                            id="deleteClient"
                            onClick={() => {
                              setShowDeleteRow(true);
                              setRowID(row.id);
                            }}
                            className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave pointer"
                            size="1x"
                          />
                          <UncontrolledTooltip target="deleteClient">
                            Delete {ClientLable}
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column align-items-between">
                          <Button
                            color="primary "
                            className="mb-1"
                            size="sm"
                            onClick={() => {
                              setShowConfigWards(true);
                              setRowID(row.id);
                              setRowData(row);
                              setMode("Add");
                            }}
                          >
                            Add Department
                          </Button>
                          <Link
                            to={{
                              pathname:
                                "/dashboard/admin/client-wards/" + row.id,
                            }}
                          >
                            <Button
                              color="primary "
                              size="sm"
                              // onClick={() => {
                              //   setShowWards(true);
                              //   setRowID(row.id);
                              // }}
                            >
                              Show Departments
                            </Button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* add new client modal */}
      <ModalComponent
        show={showAddRow}
        header={`Add New ${ClientLable} `}
        className="modal-dialog modal-lg"
        closeModal={() => setShowAddRow(false)}
      >
        <NewClient
          rowID={rowID}
          mode="Add"
          closeModal={() => setShowAddRow(false)}
          success={() => {
            setReset(!reset);
            setShowAddRow(false);
          }}
        />
      </ModalComponent>

      {/* update client modal */}
      <ModalComponent
        show={showUpdateRow}
        header={`Update ${ClientLable}`}
        className="modal-dialog modal-lg "
        closeModal={() => setShowUpdateRow(false)}
      >
        <NewClient
          rowID={rowID}
          mode="Update"
          success={() => {
            setReset(!reset);
            setShowUpdateRow(false);
          }}
          closeModal={() => setShowUpdateRow(false)}
        />
      </ModalComponent>

      <ModalComponent
        show={showWards}
        header="Departments"
        className="modal-dialog modal-lg"
        scrollable={true}
        closeModal={() => setShowWards(false)}
      >
        <WardsList rowID={rowID} closeModal={() => setShowWards(false)} />
      </ModalComponent>

      <ModalComponent
        show={showConfigWards}
        header="Configure Departments "
        className="modal-dialog modal-lg"
        scrollable={true}
        closeModal={() => setShowConfigWards(false)}
      >
        <ConfigureWards
          mode={mode}
          //    rowData={rowData}
          clientId={rowID}
          onCreate={(data) => addNewWard(data)}
          closeModal={() => setShowConfigWards(false)}
        />
      </ModalComponent>
      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteRow();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(false)}
      />
      <Delete
        show={showDeleteAll}
        isConfirm={() => {
          deleteAllRows();
          setShowDeleteAll(!showDeleteAll);
        }}
        closeModal={() => setShowDeleteAll(false)}
      />
    </DashboardLayout>
  );
};

export default Clients;
