import axios from "axios";
import { HOSTMexxar } from "../../configs/api-config";
import { forEach } from "lodash";

export function getTransactions() {
  let transactionTypes = [];

  axios
    .get(HOSTMexxar + "transactionType")
    .then((res) => {
      forEach(res.data.body, function (item) {
        transactionTypes.push({
          value: item.type,
          label: item.type,
          id: item.id,
        });
      });
    })
    .catch((err) => {
      //console.log("cannot get specialities ",err)
      // toastCommon(true, "error");
    });
  return transactionTypes;
}

export function consumeCredits(amount, creditServiceType) {
  return axios
    .post(
      `${HOSTMexxar}transaction/employeeTransaction?amount=${amount}&creditServiceType=${creditServiceType}`
    )
    .then((res) => {
      return { response: res.data };
    })
    .catch((err) => {
      return { response: "error" }; // Handle the error gracefully
    });
}

export function getCredits( ) {
   return axios
    .get(`${HOSTMexxar}credit`)
    .then((res) => {
       return { response: res.data };
    })
    .catch((err) => {
      return { response: "error" }; // Handle the error gracefully
    });
}
