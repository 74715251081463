import {
  UNCONFIRMED_BOOKING_NOTIFICATION,
  TODO_NOTIFICATION,
  RESCHEDULE_NOTIFICATION,
  EVENT_NOTIFICATION,
  TRAINING_NOTIFICATION,
  EMAIL_NOTIFICATION,
  CLIENT_CREATION_NOTIFICATION,
} from "./types";

export const todoNotificationAction = (id, message) => (dispatch) => {
  dispatch({
    type: TODO_NOTIFICATION,
    id: id,
    message: message,
  });
};

export const rescheduleNotificationAction = (id, message) => (dispatch) => {
  dispatch({
    type: RESCHEDULE_NOTIFICATION,
    id: id,
    message: message,
  });
};

export const eventNotificationAction = (id, message) => (dispatch) => {
  dispatch({
    type: EVENT_NOTIFICATION,
    id: id,
    message: message,
  });
};
export const trainingNotificationAction = (id, message) => (dispatch) => {
  dispatch({
    type: TRAINING_NOTIFICATION,
    id: id,
    message: message,
  });
};

export const emailNotificationAction = (id, message) => (dispatch) => {
  dispatch({
    type: EMAIL_NOTIFICATION,
    id: id,
    message: message,
  });
};

export const unconfirmedBookingNotificationAction =
  (id, message) => (dispatch) => {
    dispatch({
      type: UNCONFIRMED_BOOKING_NOTIFICATION,
      id: id,
      message: message,
    });
  };
  export const clientCreationNotificationAction =
  (id, message) => (dispatch) => {
    dispatch({
      type: CLIENT_CREATION_NOTIFICATION,
      id: id,
      message: message,
    });
  };