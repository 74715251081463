import { useEffect, useState } from "react";
import axios from "axios";
import useDidMountEffect from "../components/Modal/ModalBody/intialRunUseEffect";

export default function useOnboardingFetch(
  url,
  query,
  pageNumber,
  searchAdvanced,
  reset,
  status,
  getEndDate,
  getStartDate,
  search,
  mobileCandidates=false,

) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setRows([]);
  }, [query]);

  useDidMountEffect(() => {
    setRows([]);
  }, [searchAdvanced,reset]);

  useEffect(() => {

    const params = {
      page: pageNumber,
      offset: 20,
      q: query,
    };

    if (mobileCandidates) {
      params.mobileAppSignUpStatus = status === "" ? null : status;
    } else {
      params.onboardingStatus = status === "" ? null : status;
    }
    
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      url: url,
      params: params,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
    .then((res) => {
      if (res.data.status === "success") {
        setTotalElements(res.data.body.totalElements);
        const sortedData = res.data.body.content.sort((a, b) => {
          var dateA = new Date(a.lastContactedRef).getTime();
          var dateB = new Date(b.lastContactedRef).getTime();
          return dateB - dateA; // Change the order to display the latest updated date first
        });
        setHasMore(res.data.body.content.length > 0);
        setRows((prevBooks) => [...new Set([...prevBooks, ...sortedData])]);
      } else {
        setError(true);
      }
      setLoading(false);
    })
    .catch((e) => {
      if (axios.isCancel(e)) return;
      setLoading(false);
      setError(true);
    });
    return () => cancel();
  }, [searchAdvanced, query, pageNumber, reset]);
  

  return { loading, error, rows, hasMore, totalElements };
}
