import React, { useState, useEffect } from "react";

import {
  WARDS,
  REGISTER_CANDIDATE,
  CLIENTS,
  ClientLable,
} from "../../../configs/api-config";

import axios from "axios";
import Select from "react-select";
import { useForm } from "react-hook-form";
import ToastCommon from "../../toastCommon";
import _, { difference, forEach, uniqBy } from "lodash";
import { useToasts } from "react-toast-notifications";
import { Button, Label, Form, FormGroup, Col } from "reactstrap";
import useDidMountEffect from "./intialRunUseEffect";

export default function LikeWard(props) {
  const MexxarWards = WARDS;
  const MexxarApiClients = CLIENTS;
  const MexxarCandidate = REGISTER_CANDIDATE;

  const { register, handleSubmit, errors } = useForm();

  const {
    onCreate,
    closeModal,
    candidateId,
    type,
    heading,
    reset,
    currentResetState,
  } = props;

  const { addToast } = useToasts();

  const [likeWards, setLikeWards] = useState([]);
  const [candidateLikeWards, setCandidateLikeWards] = useState([]);

  const [clientWards, setClientWards] = useState([]);
  const [selectedClient, setSelectedClient] = useState();

  const [disLikeWards, setDisLikeWards] = useState([]);
  const [candidateDisLikeWards, setCandidateDisLikeWards] = useState([]);

  const [wardsInLikeClients, setWardsInLikeClients] = useState([]);
  const [prefferedClients, setPrefferedClients] = useState([]);

  const prepareCandidatesDisLikeWards = () => {
    let clientData = _.find(candidateDisLikeWards, {
      clientId: selectedClient?.id,
    });
    if (clientData) {
      setDisLikeWards(clientData.dislikeWards);
    } else {
      setDisLikeWards([]);
    }
  };

  const prepareCandidatesLikeWards = () => {
    let clientData = _.find(candidateLikeWards, {
      clientId: selectedClient?.id,
    });
    if (clientData) {
      setLikeWards(clientData.likeWards);
    } else {
      setLikeWards([]);
    }
  };

  const getClientWards = () => {
    let ClientsWard = [];
    axios
      .get(MexxarApiClients + "/" + selectedClient?.id + "/wards")
      .then((res) => {
        if (res.data.status == "success") {
          forEach(res.data.body, function (item) {
            ClientsWard.push({
              value: item.wardName,
              label: item.wardName,
              id: item.id,
            });
          });
          setWardsInLikeClients(uniqBy(ClientsWard, "id"));
        } else {
          // ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  useDidMountEffect(() => {
    getClientWards();
  }, [selectedClient]);

  useDidMountEffect(() => {
    prepareCandidatesLikeWards();
    prepareCandidatesDisLikeWards();
  }, [wardsInLikeClients]);

  const getWardData = () => {
    axios
      .get(MexxarCandidate + "/" + candidateId)
      .then((res2) => {
        //--------------Arranging candidate's like ward list--------------//

        //......Step 01-Check the prefferred client list.And create an object accordingonly to preffered client.

        let likeWardData = [];

        forEach(res2.data.body[0]?.preferredClients, function (item) {
          let clientData = {
            clientId: item.id,
            likeWards: [],
          };
          likeWardData.push(clientData);
        });
        //......Step 02-Check the like wards listed down.And push them to above object.
        //......Scenario 1- If a added prefferred client removed later on.That previous preffered client and its like wards will be also map to above object

        if (res2.data.body[0].likeWards.length > 0) {
          forEach(res2.data.body[0]?.likeWards, function (item) {
            let clientData = _.find(likeWardData, {
              clientId: item.clientId,
            });

            if (!clientData) {
              clientData = {
                clientId: item.clientId,
                likeWards: [],
              };
              likeWardData.push(clientData);
            }

            clientData.likeWards.push({
              value: item.wardName,
              label: item.wardName,
              id: item.id,
            });
          });
        }
        setCandidateLikeWards(likeWardData);
        //--------------End of : Arranging candidate's like ward list--------------//

        //--------------Arranging candidate's Dislike ward list--------------//
        //......Step 01-Check the prefferred client list.And create an object accordingonly to preffered client.

        let dislikeWardData = [];

        forEach(res2.data.body[0]?.preferredClients, function (item) {
          let clientData = {
            clientId: item.id,
            dislikeWards: [],
          };
          dislikeWardData.push(clientData);
        });

        //......Step 02-Check the dislike wards listed down.And push them to above object.
        //......Scenario 1- If a added prefferred client removed later on.That previous preffered client and its dislike wards will be also map to above object
        if (res2.data.body[0].dislikeWards.length > 0) {
          forEach(res2.data.body[0]?.dislikeWards, function (item) {
            let clientData = _.find(dislikeWardData, {
              clientId: item.clientId,
            });

            if (!clientData) {
              clientData = {
                clientId: item.clientId,
                dislikeWards: [],
              };
              dislikeWardData.push(clientData);
            }

            clientData.dislikeWards.push({
              value: item.wardName,
              label: item.wardName,
              id: item.id,
            });
          });
        }
        setCandidateDisLikeWards(dislikeWardData);
        //--------------End of : Arranging candidate's Dislike ward list--------------//
        //........Arranging candidate's preferredClients list.......//
        let preferredClients = res2.data.body[0]?.preferredClients;

        if (preferredClients) {
          let clientList = preferredClients
            .filter(item => item?.status === 'ACTIVE')  
            .map(item => ({
              value: item.name,
              label: item.name,
              id: item.id,
            }));
        
          setPrefferedClients(clientList);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const onsubmitAll = async () => {
    let likeWardList = [];
    for (const client of prefferedClients) {
      try {
        const response = await axios.get(MexxarApiClients + "/" + client?.id + "/wards");
        const wards = response?.data?.body;
        likeWardList = likeWardList.concat(wards.map(ward => ward?.id));
      } catch (error) {
        console.error("Error fetching wards:", error);
      }
    }
  
    // Call the Axios POST request
    axios
      .post(MexxarCandidate + "/" + candidateId, {
        likeWards: likeWardList,
        dislikeWards: [],
      })
      .then((res) => {
        if (res.data.status === "success") {
          closeModal();
          ToastCommon(true, "success", addToast);
          reset(!currentResetState);
        } else {
          // ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };
  
  
  
console.log("angi candidateLikeWards---->",candidateLikeWards)
  const onsubmit = () => {
    //prepare Like wards to submit
    let LikeWardsArray = candidateLikeWards;
    let obj =
      LikeWardsArray.length > 0 &&
      _.find(LikeWardsArray, { clientId: selectedClient?.id });

    if (obj) {
      obj.likeWards = likeWards;
    }

    let likeWardIds = _.flatMap(
      LikeWardsArray,
      (obj) => obj.likeWards?.map((w) => w.id) ?? []
    ); //<------------like wards ids .....//

    //prepare Dis-Like wards to submit
    let DisLikeWardsArray = candidateDisLikeWards;
    let obj2 =
      DisLikeWardsArray.length > 0 &&
      _.find(DisLikeWardsArray, { clientId: selectedClient?.id });

    if (obj2) {
      obj2.dislikeWards = disLikeWards;
    }

    let dislikeWardIds = _.flatMap(
      DisLikeWardsArray,
      (obj) => obj.dislikeWards?.map((w) => w.id) ?? []
    ); //<------------dislike wards ids .....//

    //......check if newly added like wards in dislike ward list
    //......if so take that ward from dislike wards and put in like wards.....//
    //......if the type is dislike do the opposite......//
    let temp = [];
    if (type === "Like") {
      let reArrangedsubtractArray = dislikeWardIds.filter(
        (x) => !likeWardIds.filter((y) => y === x).length
      );
      temp = reArrangedsubtractArray;
    } else {
      let reArrangedsubtractArray = likeWardIds.filter(
        (x) => !dislikeWardIds.filter((y) => y === x).length
      );
      temp = reArrangedsubtractArray;
    }

    axios
      .post(MexxarCandidate + "/" + candidateId, {
        likeWards: type === "Like" ? likeWardIds : temp,
        dislikeWards: type === "Like" ? temp : dislikeWardIds,
      })
      .then((res) => {
        if (res.data.status == "success") {
          closeModal();
          ToastCommon(true, "success", addToast);
          reset(!currentResetState);
        } else {
          // ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  useEffect(() => {
    getWardData();
  }, []);

  return (
    <Form handleSubmit={onsubmit}>
      <FormGroup row>
        <Label sm={3}>Select Preferred {ClientLable}</Label>
        <Col sm={8}>
          <Select
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                color: "#333333",
              }),
            }}
            defaultValue={selectedClient}
            // isMulti
            name="clientId"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(data) => setSelectedClient(data)}
            options={prefferedClients}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>{heading}</Label>
        <Col sm={8}>
          {type === "Like" ? (
            <Select
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                  color: "#333333",
                }),
              }}
              value={likeWards}
              isMulti
              name="candidateSpecialityId"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(data) => setLikeWards(data)}
              options={wardsInLikeClients}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            />
          ) : (
            <Select
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                  color: "#333333",
                }),
              }}
              value={disLikeWards}
              isMulti
              name="candidateSpecialityId"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(data) => setDisLikeWards(data)}
              options={wardsInLikeClients}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            />
          )}
        </Col>
      </FormGroup>
      <div className="d-flex justify-content-end">
        <Button className="mr-2" color="success" onClick={onsubmitAll}>
          Add All As Like
        </Button>
        <Button className="mr-2" color="success" onClick={onsubmit}>
          Add
        </Button>
        <Button color="secondary" onClick={() => closeModal()}>
          Close
        </Button>
      </div>
    </Form>
  );
}
