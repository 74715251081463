import React, { useState, useEffect } from "react";
// import "./working-card-widget.style.css";
import {
  Badge,
  Card,
  Input,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
  Col,
  Row,
} from "reactstrap";
import logo from "../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMars,
  faVenus,
  faPhone,
  faEnvelope,
  faCalendar,
  faPhoneSlash,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import ModalComponent from "../../components/Modal/modalComponent";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import EmailNav from "../../components/Modal/ModalBody/emailNav";
import loading from "../../../src/images/puff.svg";
import { GenderSwitch } from "../../util/common";
import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import {
  RESCHEDULE,
  HostMexxarURL,
  HOSTMexxar,
  REGISTER_CANDIDATE,
} from "../../configs/api-config";
import { connect } from "react-redux";
import ReferenceSummery from "../../components/Modal/ModalBody/referenceFormSummery";
import ReferenceFormTemplate from "../../components/Modal/ModalBody/referenceFormTemplate";
import ReferenceForm from "../../pages/referenceForm/referenceForm";
import AvatarMexxar from "../../util/common/avatarMexxar";
import ReferenceFormTemplateHistory from "../../components/Modal/ModalBody/referenceFormTemplateHistory";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";


function identifyObject(objects) {
  let zeroArr = [];
  let oneArr = [];
  let twoArr = [];
  objects.forEach((obj) => {
    let lastChar = obj.identifier[obj.identifier.length - 1];
    let propertyName = obj.identifier.slice(0, -1);
    let newObject = {};
    newObject[propertyName + "ID"] = obj.id;
    newObject[propertyName] = obj.value;

    if (lastChar === "0") {
      zeroArr.push(newObject);
    } else if (lastChar === "1") {
      oneArr.push(newObject);
    } else if (lastChar === "2") {
      twoArr.push(newObject);
    }
  });

  let finalZeros = Object.assign({}, ...zeroArr);
  let finalOnes = Object.assign({}, ...oneArr);
  let finalTwos = Object.assign({}, ...twoArr);

  return { finalZeros, finalOnes, finalTwos };
}

const ReferenceWidget = (props) => {
  const { userProfile, auth, getData1 } = props;

  console.log("angi reference for info 600---->", userProfile)
  const [calendarShow, setCalendarShow] = useState(false);
  let isStatus = "pending";

  const MexxarApi = REGISTER_CANDIDATE;
  const MexxarUrl = HostMexxarURL;
  const MexxarApiReschedule = RESCHEDULE;
  const [rowID, setRowID] = useState(false);
  const { addToast } = useToasts();

  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);
  const [showEmailModal, setShowEmailModal] = useState(false);

  const [refId, setRefId] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState({});


  const [viewModal, setViewModal] = useState(false);
  const [showReferenceFormTemplate, setShowReferenceFormTemplate] =
    useState(false);
  const [showReferenceFormTemplateHistory, setShowReferenceFormTemplateHistory] =
    useState(false);

  const [row, setRow] = useState(null);
  const [rows0, setRows0] = useState([]);
  const [rows1, setRows1] = useState([]);
  const [rows2, setRows2] = useState([]);

  const toggleShowEmailModal = () => {
    setShowEmailModal(!showEmailModal);
  };





  const getData = () => {
    // setGetLoading(true);
    axios
      .get(
        MexxarApi +
        "/" +
        userProfile?.candidateId +
        "/advance-profile-details/category/REFERENCES"
      )
      .then((res) => {

        let list = res.data.body;
        let result = identifyObject(list);

        setRows0([result?.finalZeros]);
        setRows1([result?.finalOnes]);
        setRows2([result?.finalTwos]);

        if (refId === 0) {
          setRow([result?.finalZeros])
        } else if (refId === 1) {
          setRow([result?.finalOnes])
        } else if (refId === 2) {
          setRow([result?.finalTwos])
        }


      })
      .catch((err) => {
        // ToastCommon(true, "error", addToast);
      });
    // setGetLoading(false);
  };
  const toggleM = () => setShowEmailModal(!showEmailModal);

  const toggleR = () => {
    setShowReferenceFormTemplate(!showReferenceFormTemplate);
  };
  const toggleH = () => {
    setShowReferenceFormTemplateHistory(!showReferenceFormTemplateHistory)
  };








  const handleSelectedCandidate = (candidateId) => {
    axios.get(`${MexxarApi}candidates/${candidateId}`).then((candidate) => {
      setSelectedCandidate(candidate.data.body[0]);
    });
  };

  const resheduleCall = (data) => {
    let rescheduleDate = moment(data.date).add(data.time).toISOString();
    //  console.log("erschedule data and time",data)
    axios
      .post(MexxarApiReschedule, {
        callerId: auth.item.id,
        receiverId: userProfile.candidateId,
        callDate: moment(new Date()).toISOString(),
        rescheduleDate: rescheduleDate,
      })
      .then((res) => {
        //  setCandidateId(res.data.body);
        ToastCommon(true, "success", addToast);
        setCalendarShow(!calendarShow);
      })
      .catch((err) => {
        //console.log("eror in getting response", err);
        // ToastCommon(true, "error", addToast);
      });
  };

  const sendRef = (id, refId) => {
    axios({
      method: "post",
      url:
        HOSTMexxar +
        "candidates/" +
        id +
        "/send-reference-email?identifier=" +
        refId,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      // withCredentials: true,
      mode: "cors",
    })
      .then((res) => {
        if (res.data.status == "success" || res.data.message == "created") {
          ToastCommon(true, "send", addToast);
          //  getData();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };



  useEffect(() => {
    //  getData();
    // getData1()
    return () => { };
  }, [row, userProfile]);


  return (
    <>
      <div
        className=" card d-flex flex-row main bg-light p-1 "
        style={{ minWidth: "900px", marginBottom: "5px" }}
      >
        <AvatarMexxar
          candidateId={userProfile?.candidateId}
          primaryPhone={userProfile?.primaryMobile}
          avatar={userProfile?.avatar}
          gender={userProfile?.gender}
        />

        <div className="pl-2 d-flex flex-column " style={{ flex: 3 }}>
          <div className="d-flex ml-1 mb-1">
            <div>
              {userProfile.candidateFirstName}&nbsp;
              {userProfile.candidateLastName}
            </div>
          </div>
          <div className=" d-flex ">
            <div className="mx-2 ">
              {/* <Link
                    to={{
                      pathname: "/dashboard/call/"+userProfile.candidateId+"/"+userProfile.primaryPhone,
                      aboutParams: {
                        imageLink: userProfile.image,
                      },
                    }} */}
              <Link to={"/dashboard/call/" + userProfile.candidateId}>
                <RoundIconButton
                  buttonColor="#00C426"
                  icon={faPhone}
                  iconSize="fa-md"
                  buttonSize="25px"
                ></RoundIconButton>
              </Link>
            </div>
            <div className="mx-2 ">
              <RoundIconButton
                buttonColor="#458AF7"
                icon={faEnvelope}
                buttonSize="25px"
                click={() => {
                  toggleShowEmailModal();
                  handleSelectedCandidate(userProfile.candidateId);
                }}
              ></RoundIconButton>
            </div>
            <div className=" mx-2  d-flex align-items-center ">
              <FontAwesomeIcon
                color="#FF5603"
                icon={faCalendarAlt}
                onClick={() => {
                  setCalendarShow(!calendarShow);
                  setRowID(userProfile.candidateId);
                }}
                className="fa-lg mb-2 btn-raised btn-wave "
              />
            </div>
          </div>
        </div>
        <div className="d-flex  align-items-center" style={{ flex: 1 }}>
          <FontAwesomeIcon
            // color={userProfile.gender == "1" ? "#458AF7" : "#f54394"}
            color={GenderSwitch(userProfile.gender).genderColor}
            // icon={userProfile.gender == "1" ? faMars : faVenus}
            icon={GenderSwitch(userProfile.gender).genderSymbol}
          />
        </div>

        <div
          className="d-flex flex-column  align-items-center "
          style={{ flex: 4 }}
        >
          <div style={{ height: "4vh" }}>R/Form 1 Status</div>
          <div style={{ height: "3vh" }}>
            {(() => {
              switch (userProfile.referenceFormStatus0) {
                case "NOT_SENT":
                  return <Badge className="bg-warning">Not Sent</Badge>;

                case "SENT":
                  return <Badge className="bg-info">Sent</Badge>;

                case "EXPIRED":
                  return <Badge className="bg-danger">Expired</Badge>;
                case "FILLED":
                  return <Badge className="bg-success">Filled</Badge>;
                case "SUBMITTED":
                  return <Badge className="bg-primary">Submitted</Badge>;
                case "APPROVED":
                  return <Badge className="bg-success">Approved</Badge>;
                case "REJECTED":
                  return <Badge className="bg-danger">Rejected</Badge>;
                case "RESUBMIT":
                  return <Badge className="bg-info">Sent</Badge>;

                default:
                  return null;
                  break;
              }
            })()}
          </div>

          <div style={{ height: "3vh" }} className="mt-2">
            <small>
              {userProfile.candidateReferenceForm0SentByFirstName
                ? userProfile.candidateReferenceForm0SentByFirstName +
                " " +
                userProfile.candidateReferenceForm0SentByLastName
                : "No Data"}
            </small>
          </div>
          <div style={{ height: "3vh" }}>
            <small className="text-muted">
              {userProfile?.candidateReferenceFormSendDate0 &&
                moment(userProfile?.candidateReferenceFormSendDate0).format(
                  "ddd YYYY MM DD hh:mm a"
                )}
            </small>
          </div>
        </div>

        <div
          className="d-flex flex-column  align-items-center "
          style={{ flex: 4 }}
        >
          <div style={{ height: "4vh" }}>R/Form 2 Status</div>
          <div style={{ height: "3vh" }}>
            {(() => {
              switch (userProfile.referenceFormStatus1) {
                case "NOT_SENT":
                  return <Badge className="bg-warning">Not Sent</Badge>;

                case "SENT":
                  return <Badge className="bg-info">Sent</Badge>;

                case "EXPIRED":
                  return <Badge className="bg-danger">Expired</Badge>;
                case "FILLED":
                  return <Badge className="bg-success">Filled</Badge>;
                case "SUBMITTED":
                  return <Badge className="bg-primary">Submitted</Badge>;
                case "APPROVED":
                  return <Badge className="bg-success">Approved</Badge>;
                case "REJECTED":
                  return <Badge className="bg-danger">Rejected</Badge>;
                case "RESUBMIT":
                  return <Badge className="bg-info">Sent</Badge>;

                default:
                  return null;
                  break;
              }
            })()}
          </div>
          <div style={{ height: "3vh" }} className="mt-2">
            <small>
              {userProfile.candidateReferenceForm1SentByFirstName
                ? userProfile.candidateReferenceForm1SentByFirstName +
                " " +
                userProfile.candidateReferenceForm1SentByLastName
                : "No Data"}
            </small>
          </div>
          <div style={{ height: "3vh" }}>
            <small className="text-muted">
              {userProfile?.candidateReferenceFormSendDate1 &&
                moment(userProfile?.candidateReferenceFormSendDate1).format(
                  "ddd YYYY MM DD hh:mm a"
                )}
            </small>
          </div>
        </div>
        <div
          className="d-flex flex-column  align-items-center "
          style={{ flex: 4 }}
        >
          <div style={{ height: "4vh" }}>R/Form 3 Status</div>
          <div style={{ height: "3vh" }}>
            {(() => {
              switch (userProfile.referenceFormStatus2) {
                case "NOT_SENT":
                  return <Badge className="bg-warning">Not Sent</Badge>;

                case "SENT":
                  return <Badge className="bg-info">Sent</Badge>;

                case "EXPIRED":
                  return <Badge className="bg-danger">Expired</Badge>;
                case "FILLED":
                  return <Badge className="bg-success">Filled</Badge>;
                case "SUBMITTED":
                  return <Badge className="bg-primary">Submitted</Badge>;
                case "APPROVED":
                  return <Badge className="bg-success">Approved</Badge>;
                case "REJECTED":
                  return <Badge className="bg-danger">Rejected</Badge>;
                case "RESUBMIT":
                  return <Badge className="bg-info">Sent</Badge>;

                default:
                  return null;
                  break;
              }
            })()}
          </div>
          <div style={{ height: "3vh" }} className="mt-2">
            <small>
              {userProfile.candidateReferenceForm2SentByFirstName
                ? userProfile.candidateReferenceForm2SentByFirstName +
                " " +
                userProfile.candidateReferenceForm2SentByLastName
                : "No Data"}
            </small>
          </div>
          <div style={{ height: "3vh" }}>
            <small className="text-muted">
              {userProfile?.candidateReferenceFormSendDate2 &&
                moment(userProfile?.candidateReferenceFormSendDate2).format(
                  "ddd YYYY MM DD hh:mm a"
                )}
            </small>
          </div>
        </div>

        <div
          className="d-flex align-items-center justify-content-center "
          style={{ flex: 5, width: "110px" }}
        >
          {userProfile.referenceFormStatus0 == "NOT_SENT" ? (
            <Button
              className="bg-primary text-light no-border"
              onClick={() => {
                toggleR();
                setRefId(0);
                setSelectedCandidate(userProfile.candidateId);

              }}
            >
              Show Ref 1
            </Button>
          ) : userProfile.referenceFormStatus0 == "SUBMITTED" ? (
            <Button
              className="bg-secondary text-light no-border"
              onClick={() => {
                setViewModal(!viewModal);
                setRefId(0);

              }}
            >
              Pending
            </Button>

          ) : userProfile.referenceFormStatus0 == "APPROVED" ? (
            <Button
              className="bg-success text-light no-border"
              onClick={() => {
                setViewModal(!viewModal);
                setRefId(0);
              }}
            >
              Ref 1 Approved
            </Button>
          ) : userProfile.referenceFormStatus0 == "REJECTED" ? ( // </div> //   </Button> //     Resend Ref 1 //   > //     onClick={() => sendRef(userProfile.candidateId, 0)} //     className="bg-primary text-light no-border" //   <Button // <div>
            <Button
              className="bg-warning text-light no-border"
              onClick={() => {
                toggleH();
                setRefId(0);
                setSelectedCandidate(userProfile.candidateId);


              }}
            >
              Resent Ref 1
            </Button>
          )
            : userProfile.referenceFormStatus0 == "RESUBMIT" ? ( // </div> //   </Button> //     Resend Ref 1 //   > //     onClick={() => sendRef(userProfile.candidateId, 0)} //     className="bg-primary text-light no-border" //   <Button // <div>
              <Button
                className="bg-info text-light no-border"
                onClick={() => {
                  toggleR();
                  setRefId(0);
                  setSelectedCandidate(userProfile.candidateId);

                }}
              >
                Sent
              </Button>
            )
              : userProfile.referenceFormStatus0 == "EXPIRED" ? ( // </div> //   </Button> //     Resend Ref 1 //   > //     onClick={() => sendRef(userProfile.candidateId, 0)} //     className="bg-primary text-light no-border" //   <Button // <div>
                // <Button
                //   className="bg-primary text-light no-border"
                //   onClick={() => sendRef(userProfile.candidateId, 0)}
                // >
                <Button
                  className="bg-primary text-light no-border"
                  onClick={() => {
                    toggleR();
                    setRefId(0);
                    setSelectedCandidate(userProfile.candidateId);

                  }}>
                  Resend Ref 1
                </Button>
              ): userProfile.referenceFormStatus0 == "SENT" ? (
                <div>
                  <Button
                    className="bg-info text-light no-border"
                    onClick={() => {
                      toggleR();
                      setRefId(0);
                      setSelectedCandidate(userProfile.candidateId);
                    }}
                  >
                    Sent
                  </Button>
                </div>
              ) : userProfile.referenceFormStatus0 == "FILLED" ? (
                <div>
                  <Button
                    className="bg-success text-light no-border"
                    onClick={() => {
                      setViewModal(!viewModal);
                      setRefId(0);
                    }}
                  >
                    Ref 1
                  </Button>
                </div>
              ) : null}

          <div className="mx-2">
            {userProfile.referenceFormStatus1 == "NOT_SENT" ? (
              <Button
                className="bg-primary text-light no-border"
                onClick={() => {
                  toggleR();
                  setRefId(1);
                  setSelectedCandidate(userProfile.candidateId);

                }}
              >
                Show Ref 2
              </Button>
            ) : userProfile.referenceFormStatus1 == // </div> //   </Button> //     Resend Ref 2 //   > //     onClick={() => sendRef(userProfile.candidateId, 1)} //     className="bg-primary text-light no-border" //   <Button // <div>
              "REGECTED" ? (
              <Button
                className="bg-warning text-light no-border"
                onClick={() => {
                  toggleH();
                  setRefId(1);
                  setSelectedCandidate(userProfile.candidateId);

                }}
              >
                Resent Ref 2
              </Button>
            )
              : userProfile.referenceFormStatus1 == // </div> //   </Button> //     Resend Ref 2 //   > //     onClick={() => sendRef(userProfile.candidateId, 1)} //     className="bg-primary text-light no-border" //   <Button // <div>
                "RESUBMIT" ? (
                <Button
                  className="bg-info text-light no-border"
                  onClick={() => {
                    toggleR();
                    setRefId(1);
                    setSelectedCandidate(userProfile.candidateId);

                  }}
                >
                  Sent
                </Button>
              )
                : userProfile.referenceFormStatus1 == // </div> //   </Button> //     Resend Ref 2 //   > //     onClick={() => sendRef(userProfile.candidateId, 1)} //     className="bg-primary text-light no-border" //   <Button // <div>
                  "EXPIRED" ? (
                  // <Button
                  //   className="bg-primary text-light no-border"
                  //   onClick={() => sendRef(userProfile.candidateId, 1)}
                  // >

                  <Button
                    className="bg-primary text-light no-border"
                    onClick={() => {
                      toggleR();
                      setRefId(1);
                      setSelectedCandidate(userProfile.candidateId);

                    }}>
                    Resend Ref 2
                  </Button>
                ) : userProfile.referenceFormStatus1 == "SUBMITTED" ? (
                  <div>
                    <Button
                      className="bg-secondary text-light no-border"
                      onClick={() => {
                        setViewModal(!viewModal);
                        setRefId(1);
                      }}
                    >
                      Pending
                    </Button>
                  </div>
                ) : userProfile.referenceFormStatus1 == "APPROVED" ? (
                  <div>
                    <Button
                      className="bg-success text-light no-border"
                      onClick={() => {
                        setViewModal(!viewModal);
                        setRefId(1);
                      }}
                    >
                      Ref 2 Approved
                    </Button>
                  </div>
                ) : userProfile.referenceFormStatus1 == "SENT" ? (
                  <div>
                    <Button
                      className="bg-info text-light no-border"
                      onClick={() => {
                        toggleR();
                        setRefId(1);
                        setSelectedCandidate(userProfile.candidateId);
                      }}
                    >
                      Sent
                    </Button>
                  </div>
                ) : userProfile.referenceFormStatus1 == "FILLED" ? (
                  <div>
                    <Button
                      className="bg-success text-light no-border"
                      onClick={() => {
                        setViewModal(!viewModal);
                        setRefId(1);
                      }}
                    >
                      Ref 2
                    </Button>
                  </div>
                ) : null}
          </div>

          <div >
            {userProfile.referenceFormStatus2 == "NOT_SENT" ? (
              <Button
                className="bg-primary text-light no-border"
                onClick={() => {
                  toggleR();
                  setRefId(2);
                  setSelectedCandidate(userProfile.candidateId);

                }}
              >
                Show Ref 3
              </Button>
            ) : userProfile.referenceFormStatus2 ==
              "EXPIRED" ? (

              <Button
                className="bg-primary text-light no-border"
                onClick={() => {
                  toggleR();
                  setRefId(2);
                  setSelectedCandidate(userProfile.candidateId);

                }}>
                Resend Ref 3
              </Button>
            ) : userProfile.referenceFormStatus2 ==
              "REJECTED" ? (
              <Button
                className="bg-warning text-light no-border"

                onClick={() => {
                  toggleH(); setRefId(2);
                  setSelectedCandidate(userProfile.candidateId);


                }}
              >
                Resent Ref 3
              </Button>
            )
              : userProfile.referenceFormStatus2 ==
                "RESUBMIT" ? (
                <Button
                  className="bg-info text-light no-border"
                  onClick={() => {
                    toggleR();
                    setRefId(2);
                    setSelectedCandidate(userProfile.candidateId);

                  }}
                >
                  Sent
                </Button>
              )
                : userProfile.referenceFormStatus2 == "SUBMITTED" ? (
                  <div>
                    <Button
                      className="bg-secondary text-light no-border"
                      onClick={() => {
                        setViewModal(!viewModal);
                        setRefId(2);
                      }}
                    >
                      Pending
                    </Button>
                  </div>
                ) : userProfile.referenceFormStatus2 == "APPROVED" ? (
                  <div>
                    <Button
                      className="bg-success text-light no-border"
                      onClick={() => {
                        setViewModal(!viewModal);
                        setRefId(2);
                      }}
                    >
                      Ref 3 Approved
                    </Button>
                  </div>
                )  : userProfile.referenceFormStatus2 == "SENT" ? (
                  <div>
                    <Button
                      className="bg-info text-light no-border"
                      onClick={() => {
                        toggleR();
                        setRefId(2);
                        setSelectedCandidate(userProfile.candidateId);
                      }}
                    >
                      Sent
                    </Button>
                  </div>
                ) : userProfile.referenceFormStatus2 == "FILLED" ? (
                  <div>
                    <Button
                      className="bg-success text-light no-border"
                      onClick={() => {
                        setViewModal(!viewModal);
                        setRefId(2);
                      }}
                    >
                      Ref 3
                    </Button>
                  </div>
                ) : null}
          </div>
        </div>
      </div>

      <ModalComponent
        show={calendarShow}
        header="Reschedule a call"
        closeModal={() => setCalendarShow(false)}
      >
        <SmallCalendar
          onCreate={(data) => resheduleCall(data)}
          closeModal={() => setCalendarShow(false)}
        />
      </ModalComponent>

      <ModalComponent
        show={showEmailModal}
        header="E mail and sms"
        closeModal={() => toggleM()}
      >
        <EmailNav closeModal={() => toggleM()} user={selectedCandidate} />
      </ModalComponent>


      <ModalComponent
        show={viewModal}
        header="Reference Summary"
        className="modal-dialog modal-lg"
        size={"lg"}
        closeModal={() => setViewModal(false)}
      >
        <ReferenceForm
          mode={"GET"}
          candidateId={userProfile.candidateId}
          closeModal={() => setViewModal(false)}
          identifier={refId}
          getData={getData}
          reset={getData1}
        />

      </ModalComponent>


      <ModalComponent
        show={showReferenceFormTemplate}
        header="Referee details"
        size="lg"
        closeModal={() => toggleR()}
      >

        <ReferenceFormTemplate
          closeModal={() => toggleR()}
          page="referenceWorkspace"
          candidateId={userProfile.candidateId}
          type={userProfile}
          refId={refId}
          rows={row}
          getData={getData}
          user={selectedCandidate}
          identifier={refId}

        />
      </ModalComponent>

      <ModalComponent
        show={showReferenceFormTemplateHistory}
        header="Referee details"
        size="lg"
        closeModal={() => toggleH()}
      >
        <ReferenceFormTemplateHistory
          reset={getData1}
          closeModal={() => toggleH()}
          page="referenceWorkspace"
          candidateId={userProfile.candidateId}
          type={userProfile}
          refId={refId}
          rows={row}
          user={selectedCandidate}
          mode={"GET"}
          identifier={refId}
          getData={getData}

        />
      </ModalComponent>




    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(ReferenceWidget);
