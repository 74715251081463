import React, { useState, useEffect, useRef } from "react";
import {
  HEADHUNTER_WORKSPACE_SUMMARY,
  HOSTMexxar,
  HostMexxarURL,
  REGISTER_CANDIDATE,
} from "../../configs/api-config";
import {
  ConsultantBadge,
  CandidateActiveStatusBadge,
  CandidateStage,
} from "../../util/common";

import axios from "axios";
import { Badge } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Booking from "./booking/booking";
import Profile from "./profile/profile";
import Payroll from "./payroll/payroll";
import { Button, Form } from "reactstrap";
import Calendar from "./calendar/calendar";
import Messages from "./messages/messages";
import Timeline from "./timeline/timeline";
import Settings from "./settings/settings";
import { useHistory } from "react-router-dom";
import { FeedCardWidget } from "../../widgets";
import Compliance from "./compliance/compliance";
import loading from "../../../src/images/puff.svg";
import Invitations from "./invitations/invitations";
import { useToasts } from "react-toast-notifications";
import SMSWIDGET from "../../widgets/dashboard-widgets/sms/sms";
import SupportRequest from "./supportRequest/supportRequest";
import IsPrivileged from "../../util/auth/privilegeHelper.js";
import {
  findIndex,
  forEach,
  uniqWith,
  isEqual,
  isNull,
  filter,
  round,
} from "lodash";
import BookingShifts from "../../pages/booking/workspace/shift";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import {
  faAngleLeft,
  faAngleRight,
  faRainbow,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";
import ComplianceCertificateCheck from "../../services/complianceCertificateCheck";

import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import UpcommingTasksWidget from "../../widgets/dashboard-widgets/upcommingTasks/upcommingTasks";
import ModalComponent from "../../components/Modal/modalComponent";
import ProPicUploadEdit from "../../components/ProPicUploadEdit/ProPicUploadEdit";
import NoData from "../errorPages/NoData";
import moment from "moment";
import { PhoneIcon } from "../../assets/icons/svg";
import TabNavigationMenu from "../../util/common/tabNavigation";
import AvatarMexxar from "../../util/common/avatarMexxar";
import notNull from "../../util/common/notNull";
import AssignAssigmentCodeToCandidate from "./assignAssigmentCode";
import ComplianceCheckNew2023 from "../../services/complianceCheckNew2023";
import EmailWidget from "../../widgets/dashboard-widgets/email/email";

import ComplianceQuestionnaire2 from "./compliance/complianceQuestionnaire.js";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min.js";
import event from "../../components/Modal/ModalBody/event.js";
import CompliancePercentageCalculationwithWhiteList from "../../services/compliancePercentageCalculationwithWhiteList.js";
import EmailNotesWidget from "../../widgets/dashboard-widgets/notes-email/notes-email.js";
import OutlooksMainBodyOfMails from "../email/outlooksMainBodyOfMails.js";
import OutlooksMainBodyOfMailsEmail from "../email/outlooksMainBodyOfMailsEmail.js";

const CandidateProfile = (props) => {
  const { heahunterFiltered, reset, currentResetState, auth, match } = props;

  const MexxarAPI = HOSTMexxar;
  const MexxarURL = HostMexxarURL;
  const Mexxar = REGISTER_CANDIDATE;
  const MexxarApiCandidateData = REGISTER_CANDIDATE;
  const MexxarApiHeadhuntersummery = HEADHUNTER_WORKSPACE_SUMMARY;

  const { addToast } = useToasts();
  const history = useHistory();
  const location = useLocation();

  const [menuItemList, setMenuItemList] = useState([]);
  const [summery, setSummery] = useState({});
  const [reload, setReload] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [candidateId, setCandidateId] = useState("");
  const [percentageId, setPercentageId] = useState(0);
  const [complianceWhiteListSummary, setComplianceWhiteListSummary] =
    useState();
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadModal2, setUploadModal2] = useState(false);
  const [candidateData, setCandidateData] = useState(null);
  const [profilePicture, setProfilePicture] = useState();
  const [currentTab, setCurrentTab] = useState("profile");
  const [modalPreviewPicture, setModalPreviewPicture] = useState(null);
  const [candidateConsultants, setCandidateConsultants] = useState({});

  const tab = window.location.hash.substring(1);
  useEffect(() => {
    if (tab === "") {
      setCurrentTab("profile");
    } else {
      setCurrentTab(tab);
    }

    return () => {};
  }, [tab]);




  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
    //  history.push(`#${tab}`);
  };
 
  const loadModalPreviewPicture = (data) => {
    if (data.avatar == null) {
      if (data.gender === "Male" || data.gender === "MALE") {
        setModalPreviewPicture(MexxarURL + "images/maleCandidate.png");
      } else if (data.gender === "Female" || data.gender === "FEMALE") {
        setModalPreviewPicture(MexxarURL + "images/femaleCandidate.png");
      } else {
        setModalPreviewPicture(MexxarURL + "images/default.png");
      }
    } else {
      let url = String(
        MexxarAPI + String(data.avatar).replace("pro_pic", "pro-pic")
      );
      const config = { responseType: "blob" };
      axios.get(url, config).then((res) => {
        getBase64(new File([res.data], "sample"), (result) => {
          setModalPreviewPicture(result);
        });
      });
    }
  };



  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      //console.log("Error: ", error);
    };
  }

  const getSummeryData = () => {
    axios
      .all([
        // axios.get(MexxarApiHeadhuntersummery),
        axios.get(MexxarApiCandidateData + "/" + props.match.params.id),
      ])
      .then(
        axios.spread((res2) => {
          if (res2.data.body[0] != undefined) {
            setCandidateData(res2?.data?.body[0]);
            // setSummery(res1?.data?.body[0]); //add if summery needed.change the url according to the councultant role ex:heahunter/registration

            let headhuntConsultants = res2.data.body[0].headhunters;
            let registerConsultants = res2.data.body[0].registrars;
            let maintenanceConsultants = res2.data.body[0].maintains;
            let bookingConsultants = res2.data.body[0].bookings;

            let Consultants = headhuntConsultants.concat(
              registerConsultants,
              maintenanceConsultants,
              bookingConsultants
            );

            let uniqDateClientFiltered = uniqWith(Consultants, isEqual);

            setCandidateConsultants(uniqDateClientFiltered);

            loadModalPreviewPicture(res2.data.body[0]);
            // loadProfilePicture(res2.data.body[0]);
          }
        })
      )
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    getCandidateId();
    getSummeryData();
    if (props.match.params.tab != null) {
      setCurrentTab(props.match.params.tab);
    }

    return () => {};
  }, [reload]);

  // const switchTabHandler = (tab) => {

  //   setCurrentTab(tab);
  // };

  const getCandidateId = () => {
    let id = props.match.params.id;
    setCandidateId(id);
  };

  //........... Temp staffing docs percentage calculation...........//

  useDidMountEffect(async () => {
    if (candidateData && candidateData?.candidateServiceType === "TEMPORARY") {
      const roleId = notNull(candidateData?.candidateRole)
        ? candidateData?.candidateRole?.id
        : null;
      const frameworkIds =
        candidateData?.preferredClients?.map((client) => client.framework) ||
        [];
      const specialityIds = candidateData?.candidateSpecialities?.length
        ? candidateData?.candidateSpecialities
        : [];

      const candidateBand = candidateData.candidateBand?.id;

      if (frameworkIds.length > 0 && specialityIds.length > 0) {
        const percentageArray = [];
        const uniqueRequests = new Set();

        await Promise.all(
          specialityIds.map((specialityId) =>
            Promise.all(
              frameworkIds.map(async (frameworkId) => {
                const cacheKey = `${specialityId.id}-${frameworkId.id}`;
                if (uniqueRequests.has(cacheKey)) return;

                uniqueRequests.add(cacheKey);
                const res = await CompliancePercentageCalculationwithWhiteList(
                  Number(props.match.params.id),
                  roleId,
                  specialityId.id,
                  frameworkId.id,
                  candidateBand
                );

                setComplianceWhiteListSummary(res);
                percentageArray.push({
                  specialityId: specialityId.id,
                  specialityName: specialityId.name,
                  frameworkId: frameworkId.id,
                  frameworkName: frameworkId.name,
                  certificateData: res?.certificateData,
                  percentage: isNaN(res?.compliancePercentage)
                    ? 0
                    : res?.compliancePercentage,
                });
              })
            )
          )
        );


      
        setPercentage(percentageArray);
        setPercentageId(0);
      } else {
        setPercentageId(0);
      }
    }

    return () => {};
  }, [candidateData]);

  //........... End of : Temp staffing docs percentage calculation...........//

  //...................... Perm doc percentage calculation.....................//

  //  const renderCount = useRef(0);


  
  useDidMountEffect(() => {
    let percentage;
    if (candidateData && candidateData?.candidateServiceType === "PERMANENT") {
      if (candidateData?.candidatePermDocStatuses) {
        let requiredDocAmmount = candidateData?.candidatePermDocStatuses.length;
        let L1expDocs = filter(
          candidateData?.candidatePermDocStatuses,
          (item) => {
            return moment(item.expireDate).isSameOrBefore(new Date());
          }
        );
        let L1pendingDocs = filter(
          candidateData?.candidatePermDocStatuses,
          (item) => {
            return item.expireDate === null;
          }
        );

        // let L1ApprovedDocs = filter( candidateData?.candidatePermDocStatuses, (item) => {
        //     return item.documentApprovedStatus == "APPROVE";
        //   } );
        let expDocCount = L1expDocs.length;
        let pendingDocCount = L1pendingDocs.length;
        let missingDocCount = pendingDocCount + expDocCount;
        let up = (requiredDocAmmount - missingDocCount) / requiredDocAmmount;
        let result = round(up * 100);

        if (notNull(result)) {
          percentage = result;
        } else {
          percentage = 0;
        }
      }

      setPercentage(percentage);
    }
    return () => {};
  }, [candidateData]);

  //........... End of : Perm doc percentage calculation...........//

  const percentageRight = () => {
    if (percentage.length - 1 > percentageId) {
      setPercentageId(percentageId + 1);
    }
  };

  const percentageLeft = () => {
    if (percentageId != 0) {
      setPercentageId(percentageId - 1);
    }
  };

  // useDidMountEffect(() => {
  //   getSummeryData();
  //   getCandidateId();
  // }, [currentCandidate]);

  useDidMountEffect(() => {
    if (candidateData?.candidateServiceType === "PERMANENT") {
      setMenuItemList([
        { name: "Profile", key: "profile" },
        { name: "Notes", key: "notes" },
        // { name: "Notes-Email", key: "Notes-Email" },
        { name: "Compliance", key: "compliance" },
        //{ name: "Bookings", key: "bookings" },
        { name: "Calendar", key: "calendar" },
        // { name: "Support Request", key: "supportrequest" },
        //{ name: "Payroll", key: "payroll" },
        // { name: "Messages", key: "messages" },
        //{ name: "Trainings", key: "training" },
        //{ name: "SMS", key: "sms" },
        { name: "Email", key: "email" },
        { name: "Timeline", key: "timeline" },
        // { name: "Invitations", key: "invitations" },
        { name: "Settings", key: "settings" },
      ]);
    } else {
      setMenuItemList([
        { name: "Profile", key: "profile" },
        { name: "Notes", key: "notes" },
        // { name: "Notes-Email", key: "Notes-Email" },
        { name: "Compliance", key: "compliance" },

        { name: "Bookings", key: "bookings" },
        { name: "Calendar", key: "calendar" },
        // { name: "Support Request", key: "supportrequest" },
        { name: "Payroll", key: "payroll" },
        // { name: "Messages", key: "messages" },
        { name: "Trainings", key: "training" },
        { name: "SMS", key: "sms" },
        { name: "Email", key: "email" },
        { name: "Timeline", key: "timeline" },
        // { name: "Invitations", key: "invitations" },
        // { name: "Assignment Codes", key: "assignmentCodes" },
        { name: "Settings", key: "settings" },
      ]);
    }

    return () => {};
  }, [candidateData]);

  return (
    <>
      <DashboardLayout
        title="Candidate Profile"
        subTitle="Follow Up On Your Candidates"
        //  targetsShow={auth.item.userType == "EMPLOYEE" ? true : false}
        RegisterNewButtonShow={auth.item.userType == "EMPLOYEE" ? true : false}
        //  summeryData={summery}
      >
        {isNull(candidateData) && (
          <div>
            <div className="d-flex justify-content-center justify-items-center">
              <div>
                <img src={loading} style={{ height: 100 }}></img>
              </div>
            </div>
          </div>
        )}

        {candidateData && (
          <div>
            <div className="card" data-sr-id="36" style={{ opacity: 1 }}>
              <div
                className="card-header  bg-img p-0 no-border"
                data-stellar-background-ratio="0.1"
              >
                {candidateData && (
                  <div>
                    {(() => {
                      const dbcCheckStatus =
                        candidateData?.complianceDbsCheck?.dbsCheckStatus;
                      const nmcSendStatus =
                        candidateData?.complianceNmcCheck?.nmcSendStatus;
                      const hcpcCheckStatus =
                        candidateData?.complianceHcpcCheck?.hcpcCheckStatus;

                      let statuses = [
                        {
                          label: "DBC Check Status",
                          status: dbcCheckStatus,
                          document: "DBS Document",
                        },
                        {
                          label: "NMC Check Status",
                          status: nmcSendStatus,
                          document: "NMC Document",
                        },
                        {
                          label: "HCPC Check Status",
                          status: hcpcCheckStatus,
                          document: "HCPC Document",
                        },
                      ];

                      if (nmcSendStatus === "SUBMITTED") {
                        statuses = statuses.filter(
                          (item) => item.document !== "HCPC Document"
                        );
                      }

                      if (hcpcCheckStatus === "REGISTERED") {
                        statuses = statuses.filter(
                          (item) => item.document !== "NMC Document"
                        );
                      }

                      const notSubmittedStatuses = statuses.filter(
                        (item) =>
                          ![
                            "SUBMITTED",
                            "REGISTERED",
                            "NEW_INFO",
                            "BLANK_NO_NEW_INFO",
                            "NON_BLANK_NO_NEW_INFO",
                          ].includes(item.status)
                      );

                      let borderColor = "solid";
                      let message = "";

                      if (notSubmittedStatuses.length > 0) {
                        borderColor = "red";
                        message = `The following documents are not submitted: ${notSubmittedStatuses
                          .map((item) => item.document)
                          .join(", ")}`;
                      }

                      const nullStatuses = statuses.filter(
                        (item) =>
                          item.status === null || item.status === undefined
                      );

                      if (nullStatuses.length > 0) {
                        borderColor = "yellow";
                        message = `The following documents are not submitted: ${nullStatuses
                          .map((item) => item.document)
                          .join(", ")}.  `;
                      }
                      const invalidStatuses = statuses.filter((item) =>
                        ["INVALID", "FAIL_LAPSED", "NOT_FOUND"].includes(
                          item.status
                        )
                      );
                      if (invalidStatuses.length > 0) {
                        message += `Submitted documents have issues: ${invalidStatuses
                          .map((item) => item.document)
                          .join(", ")}. `;
                      }
                      return (
                        <div
                          className="card"
                          data-sr-id="36"
                          style={{
                            opacity: 1,
                            borderColor: borderColor,
                            borderStyle: "solid",
                            borderWidth: "2px",
                          }}
                        >
                          <div className="bg-dark-overlay r-2x no-r-b">
                            <div
                              className="d-md-flex"
                              style={{ paddingLeft: "1rem" }}
                            >
                              <div className="align-items-center d-flex p-4 "></div>
                              <div className="py-4">
                                <div className="d-flex">
                                  <div className="d-flex align-items-center flex-column ">
                                    <AvatarMexxar
                                      candidateId={candidateData?.id}
                                      primaryPhone={candidateData?.primaryPhone}
                                      avatar={candidateData?.avatar}
                                      gender={candidateData?.gender}
                                      onClick={() => setUploadModal(true)}
                                    />
                                    <span className="badge badge-warning text-uppercase mt-1">
                                      {candidateData?.candidateRole ===
                                      undefined
                                        ? null
                                        : candidateData?.candidateRole?.name}
                                    </span>
                                  </div>

                                  <div className="mx-3">
                                    <h5 className="mt-2">
                                      {candidateData?.firstName}{" "}
                                      {candidateData?.lastName}
                                    </h5>
                                    <div className="text-fade text-sm">
                                      <span className="m-r">
                                        {candidateData?.email}
                                      </span>
                                    </div>
                                    <div>
                                      {candidateConsultants.length > 0 ? (
                                        <div>
                                          {candidateConsultants.map(
                                            (consultant, id) => {
                                              return (
                                                <span
                                                  className="badge badge-success text-uppercase mr-1"
                                                  key={id}
                                                  style={{
                                                    backgroundColor:
                                                      ConsultantBadge(
                                                        consultant.employeeRole
                                                      ).consultantColor,
                                                  }}
                                                >
                                                  {consultant.firstName}{" "}
                                                  {consultant.lastName}
                                                </span>
                                              );
                                            }
                                          )}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div style={{ width: "350px" }}>
                                      <span
                                        className="badge badge-success text-uppercase mr-1"
                                        style={{
                                          backgroundColor: CandidateStage(
                                            candidateData?.workFlowStatus
                                          ).badgeColor,
                                        }}
                                      >
                                        {
                                          CandidateStage(
                                            candidateData?.workFlowStatus
                                          ).badgeLable
                                        }
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <span className="flex"> </span>
                              <div className="align-items-center d-flex p-4 ">
                                {percentage != 0 ? (
                                  <div className="d-flex ">
                                    {candidateData?.candidateServiceType ===
                                      "TEMPORARY" && (
                                      <>
                                        <div
                                          className=""
                                          style={{ width: "10px" }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faAngleLeft}
                                            onClick={percentageLeft}
                                            className="btn-raised btn-wave pointer"
                                            style={{ boxShadow: "none" }}
                                          />
                                        </div>
                                        <div
                                          className="d-flex  flex-column align-items-center"
                                          style={{ width: "80px" }}
                                        >
                                          <div>
                                            {
                                              percentage[percentageId]
                                                ?.percentage
                                            }
                                            %
                                          </div>
                                          <div>
                                            <Badge
                                              color="warning"
                                              className="ml-2"
                                            >
                                              {
                                                percentage[percentageId]
                                                  ?.specialityName
                                              }
                                            </Badge>
                                          </div>
                                          <div>
                                            <Badge
                                              color="warning"
                                              className="ml-2"
                                            >
                                              {
                                                percentage[percentageId]
                                                  ?.frameworkName
                                              }
                                            </Badge>
                                          </div>
                                        </div>
                                        <div style={{ width: "10px" }}>
                                          <FontAwesomeIcon
                                            icon={faAngleRight}
                                            onClick={percentageRight}
                                            className="btn-raised btn-wave pointer"
                                            style={{ boxShadow: "none" }}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <div>0 %</div>
                                )}
                              </div>
                              <span className="flex"></span>
                              <div className="align-items-center d-flex p-4">
                                <div className="toolbar ">
                                  <div className="d-flex flex-column">
                                    <div>
                                      <a
                                        className="  btn btn-sm bg-dark-overlay btn-rounded text-white active"
                                        style={{
                                          backgroundColor:
                                            CandidateActiveStatusBadge(
                                              candidateData?.status
                                            ).badgeColor,
                                          cursor: "default",
                                        }}
                                      >
                                        <span className="d-none">
                                          {
                                            CandidateActiveStatusBadge(
                                              candidateData?.status
                                            ).badgeLable
                                          }
                                        </span>
                                      </a>
                                    </div>
                                    <div>
                                      {candidateData?.candidateServiceType ===
                                      "TEMPORARY" ? (
                                        <Badge className="m-1 p-1 bg-warning align-items-center justify-content-center d-flex">
                                          Temporary
                                        </Badge>
                                      ) : (
                                        <Badge className="m-1 p-1 bg-success align-items-center justify-content-center d-flex">
                                          Permanent
                                        </Badge>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column">
                                    <div>
                                      <IsPrivileged
                                        privilege={["EMPLOYEE", "ADMIN"]}
                                      >
                                        <Link
                                          className="btn btn-sm btn-icon bg-dark-overlay btn-rounded"
                                          to={
                                            "/dashboard/call/" +
                                            candidateData?.id
                                          }
                                        >
                                          <PhoneIcon />
                                        </Link>
                                      </IsPrivileged>
                                    </div>
                                    <div
                                      className="btn btn-md btn-icon bg-dark-overlay btn-rounded mt-1 bg-warning"
                                      onClick={() => {
                                        setUploadModal2(true);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faRobot}
                                        className="icon pointer"
                                        style={{
                                          fontSize: "20px",
                                          color: "green",
                                        }}
                                        title="Try Mexxi For RTW"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {message && (
                            <div style={{ color: "red", padding: "10px" }}>
                              {message}
                            </div>
                          )}
                        </div>
                      );
                    })()}
                  </div>
                )}

                <TabNavigationMenu
                  activated={currentTab}
                  onChangeItem={switchTabHandler}
                  itemList={menuItemList}
                />
              </div>
            </div>

            {currentTab === "compliance" && (
              <Compliance
                candidateId={candidateId}
                candidateData={candidateData}
                candidateServiceType={candidateData?.candidateServiceType}
                resetCandidate={getSummeryData}
                complianceWhiteListSummary={percentage}
                percentageId={percentageId}
                WhiteListExpDocsCount={
                  percentage?.[percentageId]?.certificateData?.[0]
                    ?.expDocsCount ?? 0
                }
                WhiteListUploadedDocsCount={
                  percentage?.[percentageId]?.certificateData?.[0]
                    ?.uploadedDocsCount ?? 0
                }
                WhiteListPendingDocsCount={
                  percentage?.[percentageId]?.certificateData?.[0]
                    ?.pendingDocsCount ?? 0
                }
              ></Compliance>
            )}

            {currentTab === "profile" && (
              <Profile
                candidate={candidateData}
                resetCandidate={getSummeryData}
                onTabChange={switchTabHandler}
              ></Profile>
            )}
            {currentTab === "notes" && (
              <FeedCardWidget
                candidateId={candidateId}
                incommingPage={"PROFILE"}
                candidateData={candidateData}
                resetCandidate={getSummeryData}
              />
            )}
            {/* {currentTab === "Notes-Email" && (
              <EmailNotesWidget candidateId={candidateId}
                incommingPage={"PROFILE"} candidateData={candidateData}
                getSummeryData={getSummeryData} userPhoneNo={candidateData?.primaryMobile}></EmailNotesWidget>
            )} */}
            {currentTab === "bookings" && (
              <BookingShifts
                incomingPage="candidateProfile"
                candidateId={props.match.params.id}
                candidate={candidateData}
              />
            )}
            {currentTab === "calendar" && (
              <Calendar
                candidateId={Number(props.match.params.id)}
                candidate={candidateData}
              ></Calendar>
            )}
            {currentTab === "payroll" && (
              <Payroll
                candidateId={Number(props.match.params.id)}
                candidate={candidateData}
                reload={() => setReload(!reload)}
              ></Payroll>
            )}
            {currentTab === "supportrequest" && (
              <SupportRequest></SupportRequest>
            )}
            {/* {currentTab === "messages" && <Messages></Messages>} */}
            {currentTab === "timeline" && (
              <Timeline
                candidateId={Number(props.match.params.id)}
                candidate={candidateData}
              ></Timeline>
            )}
            {currentTab === "invitations" && <Invitations></Invitations>}
           

            {currentTab === "settings" && (
              <Settings
                candidateId={Number(props.match.params.id)}
                candidateEmail={candidateData?.email}
                candidatePaymentMethod={candidateData?.paymentMethodStatus}
                reload={() => setReload(!reload)}
                emailOptIn={candidateData?.emailOptIn}
                candidateData={candidateData}
              ></Settings>
            )}

            {currentTab === "training" && (
              <UpcommingTasksWidget
                candidateId={Number(props.match.params.id)}
                incommingPage={"candidateProfile"}
              ></UpcommingTasksWidget>
            )}
            {currentTab === "sms" && (
              <SMSWIDGET userPhoneNo={candidateData?.primaryMobile}></SMSWIDGET>
            )}
            {currentTab === "email" && (
              <OutlooksMainBodyOfMailsEmail
                candidateData={candidateData}
                resetCandidate={getSummeryData}
              ></OutlooksMainBodyOfMailsEmail>
            )}
          </div>
        )}
      </DashboardLayout>

      <ModalComponent
        show={uploadModal2}
        header="Mexxi: Where Conversations Flourish and RTW Documents Await"
        //  className="modal-dialog"
        closeModal={() => setUploadModal2(false)}
        style={{ width: "120px" }}
        className="modal-dialog modal-xl"
      >
        <ComplianceQuestionnaire2
          closeModal={() => setUploadModal2(false)}
          candidateId={candidateId}
          candidateData={candidateData}
        />
      </ModalComponent>

      <ModalComponent
        show={uploadModal}
        header="Upload Profile Picture"
        className="modal-dialog"
        closeModal={() => setUploadModal(false)}
      >
        <ProPicUploadEdit
          closeModal={() => setUploadModal(false)}
          image={modalPreviewPicture && modalPreviewPicture}
          setModalPreviewPicture={(data) => setModalPreviewPicture(data)}
          loadProfilePicture={(data) => loadModalPreviewPicture(data)}
          candidateId={candidateId}
          reload={() => setReload(!reload)}
        />
      </ModalComponent>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    heahunterFiltered: state.heahunterFiltered,
  };
}

export default connect(mapStateToProps, {})(CandidateProfile);