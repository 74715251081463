import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser"; // Import error from MSAL
import { loginRequest } from "../../configs/authConfig";
 
// Create a custom hook for token handling
export const useTokenHandler = () => {
  const { instance, accounts } = useMsal();

  const handleToken = async (account) => {
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: account || accounts[0], // Use the selected or default account
      });
      return tokenResponse.accessToken; // Return accessToken directly
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        const response = await instance.acquireTokenPopup(loginRequest);
        return response.accessToken; // Return accessToken directly
      }
      throw error;
    }
  };
  
  const checkTokenExpiration = async () => {
    if (accounts?.length > 0) {
      try {
        const tokenResponse = await handleToken(accounts[0]);
        const expirationDate = new Date(tokenResponse.expiresOn);
        const currentDate = new Date();

        if (currentDate > expirationDate) {
          const refreshedToken = await handleToken(accounts[0]);
          console.log("Token refreshed:", refreshedToken.accessToken);
        } else {
          console.log("Token is still valid:", tokenResponse.accessToken);
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          try {
            const response = await instance.acquireTokenPopup(loginRequest);
            console.log(
              "New token acquired interactively:",
              response.accessToken
            );
          } catch (error) {
            console.error("Failed to acquire token interactively:", error);
          }
        } else {
          console.error("Failed to check token expiration:", error);
        }
      }
    } else {
      console.log("No valid account found");
    }
  };

  return { handleToken, checkTokenExpiration };
};

 